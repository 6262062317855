<template>
  <div>
    <v-card flat>
      <v-card-title>
        <v-icon class="mb-1" left>fas fa-sync</v-icon> Panel de Administración de Bejerman
      </v-card-title>
      <v-card-actions>
        <v-btn outlined color="primary" @click="getProveedoresBejerman()" title="Obtiene los Proveedores de Bejerman y los migra a Jarvis2">Sincronizar Proveedores</v-btn>
        <v-btn outlined color="error" @click="getFCComprasBejerman()" title="Obtiene las Facturas de Compra de Bejerman y las migra a Jarvis2">Sincronizar Facturas de Compra</v-btn>
        <v-btn outlined color="success" @click="getConceptosBejerman()" title="Obtiene los Conceptos de Facturas de Compra de Bejerman y los migra a Jarvis2">Sincronizar Conceptos</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  methods: {
    getProveedoresBejerman(){
      this.$store.state.loading = true
      this.$store.dispatch('facturasCompra/syncProveedoresBejerman', {tipo_importacion: 1})
        .then(response => {
          this.$store.state.loading = false
          if (response.resultado == 1){
            // exito
            this.$store.dispatch('show_snackbar', {
              text: 'Sincronización Exitosa.',
              color: 'success',
            })
            return
          }else if (response.resultado == 2){
            // esperar
            let ultimaHoraExitosa = response.horas.ultima_exitosa
            ultimaHoraExitosa = moment(ultimaHoraExitosa).format('DD/MM/YYYY HH:mm:ss')
            this.$store.dispatch('show_snackbar', {
              text: 'La última importación exitosa se hizo a las ' + ultimaHoraExitosa + '. Debe esperar 30 minutos desde esa hora para reintentar.',
              color: 'info',
            })
            return
          }else{
            // error
            this.$store.dispatch('show_snackbar', {
              text: response.msj,
              color: 'error',
            })
            return
          }
        })
        .catch(error => {
          this.$store.state.loading = false
          this.$store.dispatch('show_snackbar', {
            text: 'No se pudieron sincronizar los proveedores: ' + error.message,
            color: 'error',
          })
          return
        })
    },
    getFCComprasBejerman(){
      this.$store.state.loading = true
      this.$store.dispatch('facturasCompra/syncFCCompras', {tipo_importacion: 2})
        .then(response => {
          this.$store.state.loading = false
          if (response.resultado == 1){
            // exito
            this.$store.dispatch('show_snackbar', {
              text: 'Sincronización Exitosa.',
              color: 'success',
            })
            return
          }else if (response.resultado == 2){
            // esperar
            let ultimaHoraExitosa = response.horas.ultima_exitosa
            ultimaHoraExitosa = moment(ultimaHoraExitosa).format('DD/MM/YYYY HH:mm:ss')
            this.$store.dispatch('show_snackbar', {
              text: 'La última importación exitosa se hizo a las ' + ultimaHoraExitosa + '. Debe esperar 30 minutos desde esa hora para reintentar.',
              color: 'info',
            })
            return
          }else{
            // error
            this.$store.dispatch('show_snackbar', {
              text: response.msj,
              color: 'error',
            })
            return
          }
        })
        .catch(error => {
          this.$store.state.loading = false
          this.$store.dispatch('show_snackbar', {
            text: 'No se pudieron sincronizar las Facturas de Compra: ' + error.message,
            color: 'error',
          })
          return
        })
    },
    getConceptosBejerman(){
      this.$store.state.loading = true
      this.$store.dispatch('facturasCompra/syncConceptosBejerman', {tipo_importacion: 3})
        .then(response => {
          this.$store.state.loading = false
          if (response.resultado == 1){
            // exito
            this.$store.dispatch('show_snackbar', {
              text: 'Sincronización Exitosa.',
              color: 'success',
            })
            return
          }else if (response.resultado == 2){
            // esperar
            let ultimaHoraExitosa = response.horas.ultima_exitosa
            ultimaHoraExitosa = moment(ultimaHoraExitosa).format('DD/MM/YYYY HH:mm:ss')
            this.$store.dispatch('show_snackbar', {
              text: 'La última importación exitosa se hizo a las ' + ultimaHoraExitosa + '. Debe esperar 30 minutos desde esa hora para reintentar.',
              color: 'info',
            })
            return
          }else{
            // error
            this.$store.dispatch('show_snackbar', {
              text: response.msj,
              color: 'error',
            })
            return
          }
        })
        .catch(error => {
          this.$store.state.loading = false
          this.$store.dispatch('show_snackbar', {
            text: 'No se pudieron sincronizar los Conceptos de Bejerman: ' + error.message,
            color: 'error',
          })
          return
        })
    }
  },
}
</script>

<style>

</style>