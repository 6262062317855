<template>
  <div>
    <v-row class="d-flex justify-center mt-0 mx-md-2 mx-n1 mb-0">
      <!-- Titulo del Formulario -->
      <v-col cols="12" class="d-flex justify-end align-center pt-0 px-1">
        <div v-if="esNuevoCobro == 0">Cobro N° {{cobroId}}</div>
        <div v-else>Nuevo Cobro Online</div>
        <v-spacer></v-spacer>
      </v-col>
      <!-- Contenido -->
      <v-col cols="12">
        <v-card
          flat
          width="auto"
        >
          <v-card-text>
            <!-- Empresa, Sucursal, Punto de Venta y Operacion -->
            <v-row>
              <v-col cols="12" sm="3" md="3" class="py-0">
                Empresa
                <v-autocomplete
                  v-model="selectedEmpresa"
                  item-text="nombre_corto"
                  :items="empresas"
                  hide-details
                  outlined
                  dense
                  return-object
                  @change="setLocales()"
                  :disabled="esNuevoCobro == 0"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="py-0">
                Sucursal
                <v-autocomplete
                  v-model="selectedSucursal"
                  item-text="nombre"
                  :items="sucursales"
                  hide-details
                  outlined
                  dense
                  return-object
                  @change="setLocales()"
                  :disabled="esNuevoCobro == 0"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="py-0">
                Punto de Venta
                <v-autocomplete
                  v-model="selectedPtoVta"
                  item-text="nombre"
                  :items="ptosVta"
                  hide-details
                  outlined
                  dense
                  return-object
                  :disabled="esNuevoCobro == 0"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="py-0">
                Operación
                <v-autocomplete
                  v-model="selectedOperacion"
                  item-text="operacion_nombre"
                  :items="operaciones"
                  hide-details
                  outlined
                  dense
                  return-object
                  :disabled="esNuevoCobro == 0"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <!-- Importe, Financiacion, Importe por Cuota e Importe Total -->
            <v-row class="pt-6">
              <v-col cols="12" sm="3" md="3" class="py-0">
                Importe
                <v-text-field
                  v-model="importe"
                  outlined
                  dense
                  type="number"
                  @blur="getFinanciaciones()"
                  :readonly="esNuevoCobro == 0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" class="py-0">
                Financiacion
                <v-row class="mt-0">
                  <v-autocomplete
                    v-model="selectedFinanciacion"
                    item-text="descripcion"
                    :items="financiaciones"
                    hide-details
                    outlined
                    dense
                    return-object
                    :disabled="esNuevoCobro == 0"
                  ></v-autocomplete>
                  <v-btn icon small title="Buscar Financiaciones" @click="buscarFinanciaciones()" class="ml-2" :disabled="esNuevoCobro == 0"><v-icon color="success" small>fas fa-search</v-icon></v-btn>
                </v-row>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="py-0">
                Importe por Cuota
                <v-text-field
                  v-model="importeCuota"
                  outlined
                  dense
                  type="number"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="py-0">
                Importe Total
                <v-text-field
                  v-model="importeTotal"
                  outlined
                  dense
                  type="number"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Solicitud de Adhesion -->
            <v-row v-if="selectedEmpresa.id == 3">
              <v-col cols="12" sm="3" md="3" class="py-0">
                Solicitud de Adhesión
                <v-text-field
                  v-model="solAdhesion"
                  outlined
                  dense
                  type="text"
                  :readonly="esNuevoCobro == 0"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Datos de la Tarjeta -->
            <v-row :no-gutters="$vuetify.breakpoint.xs">
              <v-col cols="12">
                <v-card
                  elevation="2"
                  outlined
                >
                  <v-card-title>
                    Datos de la Tarjeta
                  </v-card-title>
                  <v-card-text>
                    <!-- Tarjeta, Cuotas, Titular y DNI -->
                    <v-row>
                      <v-col cols="12" sm="3" md="3" class="py-0">
                        Tarjeta
                        <v-autocomplete
                          v-model="selectedTarjeta"
                          item-text="tarjeta_nombre"
                          :items="tarjetas"
                          hide-details
                          outlined
                          dense
                          return-object
                          :disabled="lockTarjeta"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" class="py-0">
                        Cuotas
                        <v-text-field
                          v-model="cuotas"
                          outlined
                          dense
                          type="number"
                          :readonly="lockCuotas"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="5" md="5" class="py-0">
                        Titular
                        <v-text-field
                          v-model="titular"
                          outlined
                          dense
                          type="text"
                          :readonly="esNuevoCobro == 0"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" class="py-0">
                        DNI
                        <v-text-field
                          v-model="documentoNro"
                          outlined
                          dense
                          type="number"
                          :readonly="esNuevoCobro == 0"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <!-- Tarjeta Número, Vencimiento y Cuotas -->
                    <v-row>
                      <v-col cols="12" sm="3" md="3" class="py-0">
                        Tarjeta Numero
                        <the-mask
                          v-model="tarjetaNumero"
                          :mask="['####-####-####-####','####-####-####-###', '####-####-#####-####','####-####-####-####-###' ]"
                          :class="$vuetify.theme.dark ? 'white--text tarjetaCss' : 'black--text tarjetaCss'"
                          :readonly="esNuevoCobro == 0"
                        ></the-mask>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" class="py-0">
                        Vencimiento
                        <the-mask
                          v-model="vencimiento"
                          mask="##/##"
                          :class="$vuetify.theme.dark ? 'white--text tarjetaCss' : 'black--text tarjetaCss'"
                        ></the-mask>
                      </v-col>
                      <v-col sm="2" md="2" class="py-0">
                        CVC
                        <v-text-field
                          v-model="cvc"
                          outlined
                          dense
                          type="number"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-row justify="end">
                      <v-col cols="4">
                        <label><h3><strong>Total</strong></h3></label>
                        <label class="text-primary" ><h3><strong>$ {{ Intl.NumberFormat("de-DE").format(importeTotal)}}</strong></h3> </label>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <!-- Botones -->
          <v-card-actions class="d-flex justify-center mx-2">
            <v-btn color="warning" @click="volverIndex()" class="ma-2">Volver</v-btn>
            <BtnConfirmar
              @action="cobrar()"
              color="success"
              icono="fas fa-credit-card"
              :texto="`¿Esta seguro de realizar el Cobro?`"
              nombre="Cobrar"
            />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- Modal Seleccionar Financiacion -->
    <ModalSelectFP
      :datos="modalFP"
      @setFP="setearFP"
      @setearModalFps="setModalFp"
    />
  </div>
</template>

<script>
import BtnConfirmar from '../../components/util/BtnConfirmar.vue'
import {convertDecimals, order_list_by} from '../../util/utils'
import router from '../../router'
import ModalSelectFP from '../../components/financiaciones/ModalSelectFP.vue'
export default {
  data() {
    return {
      cobro: {},
      esNuevoCobro: 0,
      cobroId: 0,
      tipoPedido: 0,
      terminaCarga: 0,
      empresas: [],
      selectedEmpresa: {},
      sucursales: [],
      selectedSucursal: {},
      ptosVta: [],
      ptosVtaAux: [],
      selectedPtoVta: {},
      selectedOperacion: {},
      operaciones: [],
      operacionesAux: [],
      selectedFinanciacion: {},
      financiaciones: [],
      importe: '',
      importeCuota: '',
      importeTotal: '',
      selectedTarjeta: {},
      tarjetas: [],
      cuotas: '',
      titular: '',
      documentoNro: '',
      tarjetaNumero: '',
      vencimiento: '',
      cvc: '',
      solAdhesion: '',
      estados: [],
      modalFP: {
        activo: false,
        financiaciones: []
      },
      lockTarjeta: true,
      lockCuotas: true
    }
  },
  created () {
    if (Object.keys(this.$route.params).length == 0){
      this.esNuevoCobro = 1
    }else{
      this.esNuevoCobro = 0
      this.cobroId = this.$route.params.id
      this.tipoPedido = this.$route.params.tipo_pedido
    }
    this.initForm()
  },
  components: {
    BtnConfirmar,
    ModalSelectFP
  },
  methods: {
    async initForm(){
      this.$store.state.loading = true
      this.empresas = JSON.parse(localStorage.getItem('empresas'))
      this.sucursales = JSON.parse(localStorage.getItem('sucursales'))
      // obtengo las operaciones
      let operacionesPeticion = await this.$store.dispatch('cobros/init_index_cobros', {nuevo: this.esNuevoCobro, id: this.cobroId, tipo_pedido: this.tipoPedido})
      this.$store.state.loading = false
      if (operacionesPeticion.resultado == 1){
        this.operacionesAux = operacionesPeticion.operaciones
        this.estados = operacionesPeticion.estados
        this.ptosVtaAux = operacionesPeticion.locales
        this.tarjetas = operacionesPeticion.tarjetas
        if (this.esNuevoCobro == 0){
          this.initCobro(operacionesPeticion.cobro, operacionesPeticion.fps)
        }else{
          this.terminaCarga = 1
        }
      }else{
        this.$store.dispatch('show_snackbar', {
          text: operacionesPeticion.msj,
          color: 'error',
        })
      }
    },
    async initCobro(cobro, fps){
      // guardo el cobro
      this.cobro = cobro
      // empresa
      this.selectedEmpresa = this.empresas.filter(element => element.id == cobro.empresa_codigo)[0]
      // sucursal
      this.selectedSucursal = this.sucursales.filter(element => element.id == cobro.sucursal_codigo)[0]
      this.setLocales()
      // pto de venta
      this.selectedPtoVta = this.ptosVta.filter(element => element.id == cobro.pto_vta_codigo)[0]
      // operacion
      this.selectedOperacion = this.operaciones.filter(element => element.operacion_id == cobro.operacion_id)[0]
      // importe
      this.importe = convertDecimals(cobro.importe)
      // financiacion
      this.financiaciones = fps
      let unFp = this.financiaciones.filter(element => element.codigo == cobro.fp_cond_id)
      if (unFp.length > 0){
        this.selectedFinanciacion = unFp[0]
      }else{
        this.selectedFinanciacion = {}
      }
      // solicitud de adhesion
      if (cobro.solicitud_adhesion != null && cobro.solicitud_adhesion != undefined && cobro.solicitud_adhesion.toString().length > 0){
        this.solAdhesion = cobro.solicitud_adhesion
      }
      // tarjeta
      let unaTarjeta = this.tarjetas.filter(element => element.tarjeta_codigo == cobro.tarjeta_codigo)
      if (unaTarjeta.length > 0){
        this.selectedTarjeta = unaTarjeta[0]
      }else{
        this.lockTarjeta = false
      }
      // cuotas
      if (cobro.cuotas != null && cobro.cuotas != undefined && cobro.cuotas.toString().length > 0){
        this.cuotas = cobro.cuotas
      }else{
        this.lockCuotas = false
      }
      // titular
      this.titular = cobro.titular
      // DNI
      this.documentoNro = cobro.documento_numero
      // tarjeta
      this.tarjetaNumero = cobro.tarjeta_numero
    },
    buscarFinanciaciones(){
      let validosEnc = this.validoEnc()
      if(validosEnc.valido == false){
        this.$store.dispatch('show_snackbar', {
          text: validosEnc.msj,
          color: 'warning',
        })
        return
      }
      if (this.financiaciones.length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'No se obtuvieron las financiaciones.',
          color: 'info',
        })
        return
      }
      this.modalFP.financiaciones = this.financiaciones
      this.modalFP.activo = true
    },
    async cobrar(){
      let cobroValido = this.validarCobro()
      if (cobroValido.valido == false){
        this.$store.dispatch('show_snackbar', {
          text: cobroValido.msj,
          color: 'warning',
        })
        return
      }
      // obtengo año y mes de vencimiento
      let mes = this.vencimiento.substring(0,2)
      let anio = this.vencimiento.substring(2,4)
      // controlo el mes
      if (parseInt(mes) < 1 || parseInt(mes) > 12){
        this.$store.dispatch('show_snackbar', {
          text: 'Mes de Vencimiento no válido.',
          color: 'warning',
        })
        return
      }
      this.$store.state.loading = true
      // total a pagar
      let totalAPagar = '0'
      if (convertDecimals(this.importeTotal)%1 == 0){
        totalAPagar = parseInt(this.importeTotal).toString() + '00'
      }else{
        totalAPagar = (convertDecimals(this.importeTotal)*100)
        totalAPagar = (parseInt(totalAPagar)).toString()
      }
      // armo mi body para el cobro
      let body = {
        tarjeta_numero: this.tarjetaNumero.toString().replace('-', ''),
        mes_expira: mes.toString(),
        anio_expira: anio.toString(),
        codigo_seguridad: this.cvc.toString(),
        titular: this.titular,
        tipo_doc: 'dni',
        documento: this.documentoNro.toString(),
        empresa_id: this.selectedEmpresa.id,
        tipo_pedido: this.selectedOperacion.operacion_id,
        importe: totalAPagar,
        cuotas: this.cuotas.toString(),
        tarjeta_codigo: this.selectedTarjeta.tarjeta_codigo.toString(),
        pto_vta_codigo: this.selectedPtoVta.id,
        fp_cond_id: this.selectedFinanciacion.codigo,
        sucursal_codigo: this.selectedSucursal.id,
        key_boton: ''
      }
      // agrego la solicitud de adhesion?
      if (this.selectedEmpresa.id == 3){
        body.solicitud_adhesion = this.solAdhesion
      }
      // es para reprocesar?
      if (this.esNuevoCobro == 0){
        body.id = this.cobro.id
      }
      // a cobrar!!
      let cobroPeticion = await this.$store.dispatch('cobros/process_payment', body)
      this.$store.state.loading = false
      // controlo la salida
      if (cobroPeticion.resultado == 1){
        // llamada correcta a la API, se cobro?
        if (cobroPeticion.datosCobro != null && cobroPeticion.datosCobro.hasOwnProperty('body') && cobroPeticion.datosCobro.body.hasOwnProperty('status') && 
        cobroPeticion.datosCobro.body.status.toUpperCase() == 'APPROVED'){
          // cobro exitoso!
          this.$store.dispatch('show_snackbar', {
            text: 'Su Cobro fue procesado con Éxito.',
            color: 'success',
          })
          // aqui deberia redirigir
          setTimeout(() => [this.volverIndex()], 3000)
        }else{
          // no se aprobó el cobro
          let msjError = ''
          if (cobroPeticion.datosCobro.hasOwnProperty('body') && cobroPeticion.datosCobro.body.hasOwnProperty('status_details') && cobroPeticion.datosCobro.body.status_details.hasOwnProperty('error') &&
          cobroPeticion.datosCobro.body.status_details.error.hasOwnProperty('reason') && cobroPeticion.datosCobro.body.status_details.error.reason.hasOwnProperty('description')){
            msjError = cobroPeticion.datosCobro.body.status_details.error.reason.description
          }else if (Object.keys(cobroPeticion.datosCobro).length == 3 && cobroPeticion.datosCobro.hasOwnProperty('error') && cobroPeticion.datosCobro.hasOwnProperty('exito') && cobroPeticion.datosCobro.hasOwnProperty('mensaje') && cobroPeticion.datosCobro.error == 1){
            msjError = cobroPeticion.datosCobro.mensaje
          }else if (cobroPeticion.datosCobro.hasOwnProperty('error') && cobroPeticion.datosCobro.error.hasOwnProperty('mensaje')){
            msjError = cobroPeticion.datosCobro.error.mensaje
          }else{
            msjError = 'Error genérico. No se pudo efectuar su cobro.'
          }
          this.$store.dispatch('show_snackbar', {
            text: msjError,
            color: 'error',
          })
        }
      }else{
        // fallo el cobro
        this.$store.dispatch('show_snackbar', {
          text: cobroPeticion.msj,
          color: 'error',
        })
      }
    },
    volverIndex(){
      router.push({path: '/cobro-online'})
    },
    setLocales(){
      this.ptosVta = []
      this.operaciones = []
      // locales
      if (Object.keys(this.selectedEmpresa).length > 0 && Object.keys(this.selectedSucursal).length > 0){
        this.ptosVta = this.ptosVtaAux.filter(element => element.empresa == this.selectedEmpresa.id && element.sucursal == this.selectedSucursal.id)
      }
      // operaciones
      if (Object.keys(this.selectedEmpresa).length > 0){
        this.operaciones = this.operacionesAux.filter(element => element.empresa_id == this.selectedEmpresa.id)
      }
    },
    validoEnc(){
      if (Object.keys(this.selectedEmpresa).length == 0) return {valido: false, msj: 'Debe seleccionar la Empresa.'}
      if (Object.keys(this.selectedSucursal).length == 0) return {valido: false, msj: 'Debe seleccionar la Sucursal.'}
      if (Object.keys(this.selectedPtoVta).length == 0) return {valido: false, msj: 'Debe seleccionar el Punto de Venta.'}
      if (Object.keys(this.selectedOperacion).length == 0) return {valido: false, msj: 'Debe seleccionar el tipo de Operación a realizar.'}
      if (this.selectedPtoVta.decidir != 1) return {valido: false, msj: 'El Punto de Venta selecciondo no está habilitado para cobrar con DECIDIR.'}
      if (this.importe == null || this.importe == undefined || this.importe.toString().length == 0) return {valido: false, msj: 'Importe inválido.'}
      if (convertDecimals(this.importe) <= 0) return {valido: false, msj: 'Importe inválido.'}
      // comentado por MMACORITTO el 06/07/22
      //if (this.selectedEmpresa.id == 3 && (this.solAdhesion == null || this.solAdhesion == undefined || this.solAdhesion.toString().length == 0)) return {valido: false, msj: 'Debe ingresar la Solicitud de Adhesión.'}
      return {valido: true, msj: 'OK'}
    },
    validarCobro(){
      let encValido = this.validoEnc()
      if (encValido.valido == false) return {valido: false, msj: encValido.msj}
      if (this.importeCuota == null || this.importeCuota == undefined || this.importeCuota.toString().length == 0) return {valido: false, msj: 'Importe de la Cuota sin definir.'}
      if (this.importeTotal == null || this.importeTotal == undefined || this.importeTotal.toString().length == 0) return {valido: false, msj: 'Importe Total sin definir.'}
      if (Object.keys(this.selectedTarjeta).length == 0) return {valido: false, msj: 'Debe seleccionar la tarjeta.'}
      if (this.cuotas == null || this.cuotas == undefined || this.cuotas.toString().length == 0) return {valido: false, msj: 'Cantidad de Cuotas sin definir.'}
      if (this.titular.toString().length == 0) return {valido: false, msj: 'Debe ingresar el Titular de la Tarjeta.'}
      if (this.documentoNro == null || this.documentoNro == undefined || this.documentoNro.toString().length == 0) return {valido: false, msj: 'Debe ingresar el Nro de Documento.'}
      if (this.tarjetaNumero == null || this.tarjetaNumero == undefined || this.tarjetaNumero.toString().length == 0) return {valido: false, msj: 'Debe ingresar el Nro de Tarjeta.'}
      if (this.vencimiento == null || this.vencimiento == undefined || this.vencimiento.toString().length == 0 || this.vencimiento.toString().length != 4) return {valido: false, msj: 'Debe ingresar el Vencimiento de la Tarjeta.'}
      if (this.cvc == null || this.cvc == undefined || this.cvc.toString().length == 0) return {valido: false, msj: 'CVC sin definir.'}
      return {valido: true, msj: 'OK'}
    },
    async getFinanciaciones(){
      if (this.importe != null && this.importe != undefined && this.importe.toString().length > 0 && this.esNuevoCobro == 1){
        this.financiaciones = []
        this.selectedFinanciacion = {}
        // valido los datos del encabezado
        let validosEnc = this.validoEnc()
        if(validosEnc.valido == false){
          this.$store.dispatch('show_snackbar', {
            text: validosEnc.msj,
            color: 'warning',
          })
          return
        }
        this.$store.state.loading = true
        // tipo de venta
        let tipo_venta = 'OTROS'
        if (this.selectedEmpresa.tipo_venta != null && this.selectedEmpresa.tipo_venta != undefined && this.selectedEmpresa.tipo_venta != 'null' && this.selectedEmpresa.tipo_venta.toString().length > 0){
          tipo_venta = this.selectedEmpresa.tipo_venta
        }
        // armo mi body
        let body = {
          importe: this.importe,
          sucursal: this.selectedSucursal.id,
          tipo_venta: tipo_venta,
          manual: 0,
          empresa: this.selectedEmpresa.id,
          canal: this.selectedPtoVta.canal,
          pto_vta: this.selectedPtoVta.id,
          articulos: []
        }
        let fpPeticion = await this.$store.dispatch('financiaciones/get_financiaciones_habilitadas', body)
        this.$store.state.loading = false
        if (fpPeticion.resultado == 1){
          this.financiaciones = fpPeticion.fps
          order_list_by(this.financiaciones, 'descripcion')
        }else{
          this.$store.dispatch('show_snackbar', {
            text: operacionesPeticion.msj,
            color: 'error',
          })
        }
      }
    },
    setModalFp(value){
      this.modalFP.activo = value
    },
    setearFP(item){
      this.selectedFinanciacion = item
    },
    limpiarDatosTarjeta(){
      if (this.terminaCarga == 1){
        this.selectedTarjeta = {}
        this.cuotas = ''
        this.titular = ''
        this.documentoNro = ''
        this.tarjetaNumero = ''
        this.vencimiento = ''
        this.cvc = ''
      }
    },
    setDatosFinanciacion(){
      this.importeCuota = 0
      this.importeTotal = 0
      if (this.esNuevoCobro == 0 && this.terminaCarga == 0){
        this.terminaCarga = 1
      }else{
        this.limpiarDatosTarjeta()
      }
      if (Object.keys(this.selectedFinanciacion).length > 0){
        this.importeTotal = convertDecimals(this.selectedFinanciacion.total_final)
        let unaTarjeta = this.tarjetas.filter(element => element.tarjeta_codigo == this.selectedFinanciacion.tarjeta_codigo)
        if (unaTarjeta.length > 0){
          this.selectedTarjeta = unaTarjeta[0]
          this.lockTarjeta = true
        }else{
          // permito ingresar la tarjeta
          this.lockTarjeta = false
        }
        let cuotas_desde = parseInt(this.selectedFinanciacion.cuotas_desde)
        let cuotas_hasta = parseInt(this.selectedFinanciacion.cuotas_hasta)
        let cuota_interna = parseInt(this.selectedFinanciacion.cuota_interna)
        let dif = cuotas_hasta - cuotas_desde
        if (dif == 0){
          this.importeCuota = convertDecimals(convertDecimals(this.selectedFinanciacion.total_final)/cuotas_hasta)
          this.cuotas = cuota_interna
          this.lockCuotas = true
        }else{
          // permito ingresar la cuota
          this.lockCuotas = false
        }
      }else{
        this.importeCuota = ''
        this.importeTotal = ''
      }
    }
  },
  watch: {
    financiaciones: function(){
      if (this.financiaciones.length > 0){
        for (let id in this.financiaciones){
          if (this.financiaciones[id].cuotas_desde == this.financiaciones[id].cuotas_hasta){
            this.financiaciones[id].importe_cuotas = convertDecimals(convertDecimals(this.financiaciones[id].total_final)/convertDecimals(this.financiaciones[id].cuotas_hasta))
          }
        }
      }
    },
    selectedFinanciacion: function(){
      this.setDatosFinanciacion()
    }
  },
}
</script>

<style scoped>
  .tarjetaCss {
    width: 100%;
    height: 32px;
    border-radius: 3px;
    box-shadow: none;
    border: 1px solid #ced6e0;
    transition: all 0.3s ease-in-out;
    font-size: 14px;
    padding: 5px 10px;
    background: none;
  }
</style>