<template>
  <v-dialog
    v-model="visible"
    max-width="1000px"
  >
    <v-card
      flat
      max-width="1000px"
    >
      <v-card-title>
        Agregar Relacion Proveedor-Concepto
        <v-spacer></v-spacer>
        <v-btn icon><v-icon @click="visible = false" title="Cerrar">fas fa-times</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <br/>
      <v-card-text>
        <!-- Filtros -->
        <v-row :no-gutters="$vuetify.breakpoint.xs">
          <v-col cols="12" sm="6" md="3" class="py-1">
            Empresa
            <v-autocomplete
              v-model="selectedEmpresa"
              item-text="nombre"
              :items="empresasN"
              hide-details
              clearable
              outlined
              dense
              return-object
              @change="getProveedores()"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="py-1">
            Proveedor
            <v-autocomplete
              v-model="selectedProveedor"
              item-text="proveedor_nombre"
              :items="proveedores"
              hide-details
              clearable
              outlined
              dense
              return-object
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="2" class="py-1">
            Cod. Concepto
            <v-text-field
              v-model="codigoConcepto"
              outlined
              dense
              @blur="completarConcepto(1)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="py-1">
            Concepto
            <v-autocomplete
              v-model="selectedConcepto"
              item-text="nombre"
              :items="conceptos"
              hide-details
              clearable
              outlined
              dense
              return-object
              @change="completarConcepto(2)"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="1" class="py-1 pt-6" title="Agregar">
            <v-btn icon color="success" @click="addNewConcept()" :disabled="addBlock == true">
              <v-icon small>fas fa-check</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <!-- Listado -->
        <v-row :no-gutters="$vuetify.breakpoint.xs">
          <v-col cols="12">
            <v-data-table
              class="cebra elevation-2 mt-2"
              :headers="headers"
              :items="listadoConceptos"
              dense
              :search="search"
              :loading="load"
            >
              <!-- Buscador en el datatable -->
              <template v-slot:top>
                <v-row class="d-flex justify-end pa-2" no-gutters>
                  <v-col cols="6" sm="3">
                    <SearchDataTable
                      v-model="search"
                    />
                  </v-col>
                </v-row>
              </template>
              <!-- Acciones y modal para confirmar -->
              <template v-slot:[`item.acciones`]="{ item }">
                 <v-icon small color="error" title="Eliminar" @click="quitarConcepto(item)">fas fa-ban</v-icon>
              </template>
              <!-- Msj que se mostrara si no existen resultados -->
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  max-width="400"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  No hay datos para los filtros seleccionados
                </v-alert>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- Botones -->
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="load"
          @click="visible = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          :loading="load"
          @action="guardarRelacion"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SearchDataTable from '../util/SearchDataTable.vue'
import BtnConfirmar from '../util/BtnConfirmar.vue'
export default {
  props: {
    activo: Boolean,
    empresasN: Array
  },
  data() {
    return {
      visible: false,
      selectedEmpresa: {},
      proveedores: [],
      selectedProveedor: {},
      codigoConcepto: '',
      codigoBloqueado: false,
      selectedConcepto: {},
      conceptos: [], 
      headers: [
        { text: 'Proveedor', align: 'center', value: 'proveedor_nombre' },
        { text: 'Concepto Codigo', align: 'center', value: 'concepto_codigo' },
        { text: 'Concepto Nombre', align: 'center', value: 'concepto_nombre' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      listadoConceptos: [],
      search: '',
      load: false,
      addBlock: false
    }
  },
  created () {

  },
  components: {
    SearchDataTable,
    BtnConfirmar
  },
  methods: {
    async getProveedores(){
      if (typeof this.selectedEmpresa == 'object' && this.selectedEmpresa != null){
        this.$store.state.loading = true
        if (Object.keys(this.selectedEmpresa).length == 0){
          this.$store.state.loading = false
          this.$store.dispatch('show_snackbar', {
            text: 'Debe seleccionar una Empresa.',
            color: 'warning',
          })
          return
        }
        this.selectedProveedor = {}
        this.proveedores = []
        await this.$store.dispatch('bejerman/get_proveedores_bejerman', {empresa_codigo: this.selectedEmpresa.id})
          .then(async response => {
            let respuesta = response
            if (respuesta.resultado == 1){
              // peticion exitosa
              this.proveedores = respuesta.proveedoresBejerman
              await this.getConceptos()
            }else{
              // error
              this.$store.state.loading = false
              this.$store.dispatch('show_snackbar', {
                text: respuesta.msj,
                color: 'error',
              })
              return
            }
          })
          .catch(error => {
            this.$store.state.loading = false
            this.$store.dispatch('show_snackbar', {
              text: 'Ocurrio un problema inesperado al correr el metodo getProveedores: ' + error.message,
              color: 'error',
            })
            return
          })
      }
    },
    async getConceptos(){
      await this.$store.dispatch('bejerman/get_oracle_conceptos', {empresa_codigo: this.selectedEmpresa.id})
        .then(response => {
          this.$store.state.loading = false
          let respuesta = response
          if (respuesta.resultado == 1){
            this.conceptos = respuesta.conceptosOracle
          }else{
            this.$store.dispatch('show_snackbar', {
              text: respuesta.msj,
              color: 'error',
            })
            return
          }
        })
        .catch(error => {
          this.$store.state.loading = false
          this.$store.dispatch('show_snackbar', {
            text: 'Ocurrio un problema inesperado al correr el metodo getConceptos: ' + error.message,
            color: 'error',
          })
          return
        })
    },
    async addNewConcept(){
      // control de campos de consistencia
      if (Object.keys(this.selectedEmpresa).length == 0 || Object.keys(this.selectedProveedor).length == 0 || this.codigoConcepto.length == 0 || Object.keys(this.selectedConcepto).length == 0){
        this.$swal.fire({
          icon: 'warning',
          title: 'Agregar un Nuevo Concepto.',
          text: 'Por favor, complete todos los campos para poder agregar a la tabla.'
        })
        return
      }
      // control de repetidos
      let repetido = this.listadoConceptos.filter(element => element.concepto_codigo == this.codigoConcepto)
      if (repetido.length > 0){
        this.$store.dispatch('show_snackbar', {
          text: 'El concepto ya existe en el listado.',
          color: 'warning',
        })
        this.codigoConcepto = ''
        this.selectedConcepto = {}
        return
      }
      this.load = true
      this.addBlock = true
      // control de existencia en Oracle
      await this.$store.dispatch('bejerman/get_oracle_prov_empresa_concep_relation', {empresa_codigo: this.selectedEmpresa.id, proveedor_codigo: this.selectedProveedor.proveedor_codigo, concepto_codigo: this.selectedConcepto.codigo})
        .then(response => {
          let respuesta = response
          if (respuesta.resultado == 1){
            if (respuesta.relationOracle.length > 0){
              // ya existe, agregar pero advertir que ya existe
              this.$swal.fire({
                icon: 'info',
                title: 'Agregar un Nuevo Concepto.',
                text: 'La relación que está agregando ya existe en la base de datos. Puede verla desde la Pantalla Principal de este menú.'
              })
              this.codigoConcepto = ''
              this.selectedConcepto = {}
              this.load = false
              this.addBlock = false
              return
            }
            let unConcepto = {
              proveedor_nombre: this.selectedProveedor.proveedor_nombre,
              proveedor_codigo: this.selectedProveedor.proveedor_codigo,
              concepto_codigo: this.selectedConcepto.codigo,
              concepto_nombre:this.selectedConcepto.nombre,
              iva_compras: this.selectedConcepto.iva_compras,
              iva_ventas: this.selectedConcepto.iva_ventas,
              acciones: ''
            }
            this.listadoConceptos.push(unConcepto)
            this.selectedConcepto = {}
            this.codigoConcepto = ''
            this.load = false
            this.addBlock = false
          }else{
            this.$swal.fire({
              icon: 'error',
              title: 'Agregar un Nuevo Concepto.',
              text: respuesta.msj
            })
          }
          this.load = false
          this.addBlock = false
          this.codigoConcepto = ''
          this.selectedConcepto = {}
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: 'No se pudo obtener el estado de la fila a agregar: ' + error.message,
            color: 'warning',
          })
          this.load = false
          this.addBlock = false
          this.codigoConcepto = ''
          this.selectedConcepto = {}
        })
    },
    completarConcepto(opcion){
      if (opcion == 1){
        // llena por el codigo del concepto
        if (this.codigoConcepto.length > 0){
          this.selectedConcepto = {}
          // busco el codigo del concepto en el listado de conceptos que ya tengo cargado
          let unConcepto = this.conceptos.filter(element => element.codigo.toUpperCase() == this.codigoConcepto.toUpperCase())
          if (unConcepto.length > 0){
            // lo encontre, lo asigno
            this.selectedConcepto = unConcepto[0]
          }else{
            // no lo encontre
            this.codigoConcepto = ''
            this.$store.dispatch('show_snackbar', {
              text: 'No se reconoce el Código del Concepto ingresado.',
              color: 'warning',
            })
            return
          }
        }
      }else{
        // elige un concepto de la lista
        if (typeof this.selectedConcepto == 'object' && this.selectedConcepto != null && Object.keys(this.selectedConcepto).length > 0){
          this.codigoConcepto = this.selectedConcepto.codigo
        }
      }
    },
    quitarConcepto(item){
      let pos = this.listadoConceptos.indexOf(item)
      this.listadoConceptos.splice(pos, 1)
    },
    async guardarRelacion(){
      // tengo algo que guardar?
      if (this.listadoConceptos.length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Sin cambios que aplicar.',
          color: 'success',
        })
        this.visible = false
        this.selectedEmpresa = {}
        this.selectedProveedor = {}
        this.codigoConcepto = ''
        this.selectedConcepto = {}
        this.proveedores = []
        this.conceptos = []
        return
      }
      // paso a guardar cambios
      this.$store.state.loading = true
      await this.$store.dispatch('bejerman/save_new_relation_CxP', {empresa_codigo: this.selectedEmpresa.id, detalles: this.listadoConceptos})
        .then(response => {
          this.$store.state.loading = false
          let respuesta = response
          if (respuesta.resultado == 1){
            this.$store.dispatch('show_snackbar', {
              text: 'Los cambios fueron guardados con Éxito.',
              color: 'success',
            })
            this.visible = false
          }else{
            this.$store.dispatch('show_snackbar', {
              text: respuesta.msj,
              color: 'error',
            })
          }
        })
        .catch(error => {
          this.$store.state.loading = false
          this.$store.dispatch('show_snackbar', {
            text: 'No se pudo guardar los cambios: ' + error.message,
            color: 'error',
          })
        })
    }
  },
  watch: {
    activo: function(){
      this.visible = this.activo
    },
    visible: function(){
      if (this.visible == false){
        this.selectedEmpresa = {}
        this.selectedProveedor = {}
        this.codigoConcepto = ''
        this.selectedConcepto = {}
        this.proveedores = []
        this.conceptos = []
        this.listadoConceptos = []
        this.$emit('cerrarModalCxP')
      }
    },
    selectedEmpresa: function(){
      if (this.selectedEmpresa == null || this.selectedEmpresa == undefined){
        this.selectedEmpresa = {}
        this.selectedProveedor = {}
        this.proveedores = []
        this.codigoConcepto = ''
        this.selectedConcepto = {}
        this.conceptos = []
        this.listadoConceptos = []
      }
    },
    selectedProveedor: function(){
      if (this.selectedProveedor == null || this.selectedProveedor == undefined){
        this.selectedProveedor = {}
        this.codigoConcepto = ''
        this.selectedConcepto = {}
        this.listadoConceptos = []
      }
    },
    selectedConcepto: function(){
      if (this.selectedConcepto == null || this.selectedConcepto == undefined){
        this.selectedConcepto = {}
        this.codigoConcepto = ''
      }
    }
  },
}
</script>

<style>

</style>