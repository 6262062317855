<template>
  <v-dialog
    v-model="activo"
    max-width="50%"
  >
    <v-card
      flat
    >
      <!-- Titulo -->
      <v-card-title
        class="pt-0"
        :class="$vuetify.breakpoint.smAndDown ? 'd-flex justify-center' : ''"
      >
        Artículos
        <v-spacer></v-spacer>
        <v-btn icon><v-icon @click="activo = false" title="Cerrar">fas fa-times</v-icon></v-btn>
      </v-card-title>
      <!-- Listado -->
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-data-table
              class="cebra elevation-2 mt-2"
              :headers="headers"
              :items="articulos"
              :loading="load"
              dense
              :search="search"
            >
              <!-- Buscador en el datatable -->
              <template v-slot:top>
                <v-row class="d-flex justify-end pa-2" no-gutters>
                  <v-col cols="6" sm="6">
                    <SearchDataTable
                      v-model="search"
                    />
                  </v-col>
                </v-row>
              </template>
              <!-- Msj que se mostrara si no existen resultados -->
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  Sin Artículos para mostrar.
                </v-alert>
              </template>
              <!-- Items -->
              <template v-slot:[`item.cantidad`]="{ item }">
                <v-row class="d-flex justify-center" no-gutters>
                  <v-text-field
                    v-model="item.cantidad"
                    style="width: 50px"
                    type="number"
                    hide-details
                    dense
                    outlined
                  ></v-text-field>
                </v-row>
              </template>
              <!-- Acciones -->
              <template v-slot:[`item.acciones`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn small icon color="success" @click="selectArticulo(item)" v-on="on">
                      <v-icon small>fas fa-check</v-icon>
                    </v-btn>
                  </template>
                  <span>Seleccionar</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import SearchDataTable from '../../util/SearchDataTable.vue'
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  components: {
    SearchDataTable,
  },
  computed: {
    activo: {
      get() {
        return this.datos.activo
      },
      set(value) {
        this.$emit('setearModalArtsDev', value)
      }
    },
    cliente_codigo: {
      get() {
        return this.datos.cliente_codigo
      }
    },
    lista_precios: {
      get() {
        return this.datos.lista_precios
      }
    }
  },
  data() {
    return {
      headers: [
        { text: 'Código', align: 'center', value: 'articulo_codigo' },
        { text: 'Nombre', align: 'center', value: 'articulo_nombre' },
        { text: 'Cantidad', align: 'center', value: 'cantidad' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      articulos: [],
      load: false,
      search: ''
    }
  },
  methods: {
    async initDialogArts(){
      this.load = true
      let artPeticion = await this.$store.dispatch('remitosDevolucion/getArtsDevolucion', {articulo_codigo: 0, cliente: this.cliente_codigo, lista: this.lista_precios})
      this.load = false
      if (artPeticion.resultado == 1){
        this.articulos = artPeticion.articulos
      }else{
        this.$store.dispatch('show_snackbar', {
          text: artPeticion.msj,
          color: 'error',
        })
      }
    },
    limpiarModal(){
      this.articulos = []
      this.load = false
      this.search = ''
    },
    selectArticulo(item){
      if (parseInt(item.cantidad) == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Ingrese la Cantidad.',
          color: 'info',
        })
        return
      }
      this.$emit('setearArtSelect', item)
      this.activo = false
    }
  },
  watch: {
    activo: function(){
      if (this.activo == true){
        this.initDialogArts()
      }else{
        this.limpiarModal()
      }
    }
  },
}
</script>

<style>

</style>