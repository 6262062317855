<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <!-- Titulo y boton agregar -->
            <v-btn
              style="position: absolute; top: -15px; right: 55px; z-index: 1"
              color="success"
              small
              fab
              title="Nuevo Local"
              @click="nuevoLocal()"
            >
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtros
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar()">
                <!-- Empresa, Sucursal, Estado y Tipo de Local -->
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Empresa
                    <v-autocomplete
                      v-model="selectedEmpresa"
                      item-text="nombre_corto"
                      :items="empresas"
                      hide-details
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Sucursal
                    <v-autocomplete
                      v-model="selectedSucursal"
                      item-text="nombre"
                      :items="sucursales"
                      hide-details
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Estado
                    <v-autocomplete
                      v-model="selectedEstado"
                      item-text="estado_nombre"
                      :items="estados"
                      hide-details
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Tipo
                    <v-autocomplete
                      v-model="selectedTipoLocal"
                      item-text="categoria_nombre"
                      :items="tiposLocales"
                      hide-details
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <!-- Nombre de Local y Botones -->
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="6" md="6" class="py-1">
                    Nombre Local
                    <v-text-field
                      v-model="localNombre"
                      outlined
                      dense
                      type="text"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="d-flex justify-end align-end">
                    <BtnFiltro
                      :loading="load"
                      @clear="limpiar()"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- Listado -->
        <v-data-table
          class="cebra elevation-2 mt-2"
          :headers="headers"
          :items="locales"
          :loading="load"
          dense
          :search="search"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <!-- Buscador en el datatable -->
          <template v-slot:top>
            <v-row class="d-flex justify-space-between pa-2" no-gutters>
              <v-col cols="6" sm="9" md="6">
                <DownloadDataTableVue
                  name="ABM_Locales"
                  :data="locales"
                  :headers="headers_excel"
                />
              </v-col>
              <v-col cols="6" sm="3">
                <SearchDataTable
                  v-model="search"
                />
              </v-col>
            </v-row>
          </template>
          <!-- Msj que se mostrara si no existen resultados -->
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No hay datos para los filtros seleccionados.
            </v-alert>
          </template>
          <!-- Acciones -->
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn small icon color="success" @click="verLocal(item)" v-on="on">
                  <v-icon small>fas fa-eye</v-icon>
                </v-btn>
              </template>
              <span>Ver</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn small icon :color="parseInt(item.con_archivo) > 0 ? 'success' : 'error'" @click="verImagenes(item)" v-on="on">
                  <v-icon small>fas fa-file-upload</v-icon>
                </v-btn>
              </template>
              <span>Imagenes</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn small icon color="indigo" @click="verTerminales(item)" v-on="on">
                  <v-icon small>fas fa-mobile-alt</v-icon>
                </v-btn>
              </template>
              <span>Terminales</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Modal Ver Locales -->
    <ModalVerLocales 
      :datos="dialogVerLocales"
      @setearModalVerLocales="setModalVerLocales"
      @executeBuscar="buscar"
    />
    <!-- Modal Subir Archivos -->
    <ModalUploadImgGral 
      :datos="dialogSubirImgs"
      @setearModalUpImg="setModalUpImg"
    />
    <!-- Modal Terminales x Local -->
    <ModalTerminalesLocal
      :datos="dialogTerminales"
      @setearModalTerminales="setModalTerminales"
    />
  </div>
</template>

<script>
import BtnFiltro from '../../../components/util/BtnFiltro.vue'
import SearchDataTable from '../../../components/util/SearchDataTable.vue'
import moment from 'moment'
import ModalVerLocales from '../../../components/generales/locales/ModalVerLocales.vue'
import ModalUploadImgGral from '../../../components/generales/ModalUploadImgGral.vue'
import ModalTerminalesLocal from '../../../components/generales/locales/ModalTerminalesLocal.vue'
import DownloadDataTableVue from '../../../components/util/DownloadDataTable.vue'

export default {
  data() {
    return {
      panel: 0,
      selectedEmpresa: {},
      empresas: [],
      selectedSucursal: {},
      sucursales: [],
      selectedEstado: {estado_codigo: 0, estado_nombre: 'HABILITADO'},
      estados: [{estado_codigo: 0, estado_nombre: 'HABILITADO'}, {estado_codigo: 1, estado_nombre: 'INHABILITADO'}],
      selectedTipoLocal: {},
      tiposLocales: [],
      localNombre: '',
      load: false,
      headers: [
        { text: 'Código', align: 'end', value: 'pto_vta_codigo' },
        { text: 'Sucursal', align: '', value: 'sucursal_nombre' },
        { text: 'Nombre', align: '', value: 'pto_vta_nombre' },
        { text: 'Domicilio', align: '', value: 'domicilio' },
        { text: 'Tipo', align: '', value: 'categoria_nombre' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      headers_excel:{
        'Código' : 'pto_vta_codigo',
        'Sucursal': 'sucursal_nombre',
        'Nombre':'pto_vta_nombre',
        'Domicilio': 'domicilio',
        'Tipo': 'categoria_nombre'
      },
      locales: [],
      search: '',
      dialogVerLocales: {
        activo: false,
        local: {},
        nuevo: 0,
        ver: 0,
        tipos_locales: [],
        empresasDialog: [],
        sucursalesDialog: []
      },
      sortDesc: true,
      sortBy: 'pto_vta_codigo',
      dialogSubirImgs: {
        activo: false,
        icono: 'fas fa-eye',
        titulo_modal: 'Imagenes para el Local ',
        tipos: ['.jpg', '.png', '.webp', '.pdf'], // formatos admitidos
        multiple: true,
        max_size: 1, // tamaño del archivo en megas
        ruta: '', // ruta del archivo
        raiz: 0, // indica si vamos a trabajar con la raiz del 10.40 o la de jarvis 2
        tabla: 4, // indica la tabla donde va el log
        id: 0, // generalmente indica un numero identificador para la tabla del log, por ejemplo para facturas de compra seria el id de la factura de compra en la tabla LOG_FACTURAS_COMPRAS en el campo FACTURA_COMPRA_ID
        permiso_borrar: 0 // permiso para borrar archivos
      },
      dialogTerminales: {
        activo: false,
        local: {}
      }
    }
  },
  components: {
    BtnFiltro,
    SearchDataTable,
    ModalVerLocales,
    ModalUploadImgGral,
    ModalTerminalesLocal,
    DownloadDataTableVue
  },
  created () {
    this.initForm()
  },
  methods: {
    async initForm(){
      this.empresas = JSON.parse(localStorage.getItem('empresas'))
      this.sucursales = JSON.parse(localStorage.getItem('sucursales'))
      this.dialogVerLocales.empresasDialog = this.empresas
      this.dialogVerLocales.sucursalesDialog = this.sucursales
      this.$store.state.loading = true
      let initPeticion = await this.$store.dispatch('localesStore/initFormLocales')
      this.$store.state.loading = false
      if (initPeticion.resultado == 1){
        this.tiposLocales = initPeticion.tipos
        this.dialogVerLocales.tipos_locales = initPeticion.tipos
        this.dialogSubirImgs.permiso_borrar = initPeticion.permiso
      }else{
        this.$store.dispatch('show_snackbar', {
          text: initPeticion.msj,
          color: 'error',
        })
      }
    },
    async buscar(){
      if (Object.keys(this.selectedEmpresa).length == 0 || Object.keys(this.selectedEstado).length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Los campos Empresa y Estado son obligatorios para realizar la búsqueda.',
          color: 'warning',
        })
        return
      }
      this.locales = []
      let sucursal = 0
      if (Object.keys(this.selectedSucursal).length > 0) sucursal = this.selectedSucursal.id
      let tipo = 0
      if (Object.keys(this.selectedTipoLocal).length > 0) tipo = this.selectedTipoLocal.categoria_codigo
      this.load = true
      let detallesPeticion = await this.$store.dispatch('localesStore/getLocales', {empresa_codigo: this.selectedEmpresa.id, sucursal_codigo: sucursal, estado_codigo: this.selectedEstado.estado_codigo, tipo_codigo: tipo, nombre_local: this.localNombre})
      this.load = false
      if (detallesPeticion.resultado == 1){
        this.locales = detallesPeticion.detalles
      }else{
        this.$store.dispatch('show_snackbar', {
          text: detallesPeticion.msj,
          color: 'warning',
        })
      }
    },
    nuevoLocal(){
      this.dialogVerLocales.local = {}
      this.dialogVerLocales.nuevo = 1
      this.dialogVerLocales.ver = 0
      this.dialogVerLocales.activo = true
    },
    limpiar(){
      this.selectedEmpresa = {}
      this.selectedSucursal = {}
      this.selectedEstado = {estado_codigo: 0, estado_nombre: 'HABILITADO'}
      this.selectedTipoLocal = {}
      this.localNombre = ''
    },
    verLocal(item){
      this.dialogVerLocales.local = item
      this.dialogVerLocales.nuevo = 0
      this.dialogVerLocales.ver = 1
      this.dialogVerLocales.activo = true
    },
    setModalVerLocales(value){
      this.dialogVerLocales.activo = value
      if (this.dialogVerLocales.activo == false){
        this.dialogVerLocales.local = {}
        this.dialogVerLocales.nuevo = 0
        this.dialogVerLocales.ver = 0
      }
    },
    verImagenes(item){
      // titulo del modal
      this.dialogSubirImgs.titulo_modal = this.dialogSubirImgs.titulo_modal + item.pto_vta_nombre
      // ruta de la carpeta donde se deberian encontrar los archivos
      this.dialogSubirImgs.ruta = '/fotos_locales/' + item.pto_vta_codigo
      // indico el id identificador de las fotos del local
      this.dialogSubirImgs.id = item.pto_vta_codigo
      // abro el modal
      this.dialogSubirImgs.activo = true
    },
    setModalUpImg(value){
      this.dialogSubirImgs.activo = value
      if (this.dialogSubirImgs.activo == false){
        this.dialogSubirImgs.icono = 'fas fa-eye'
        this.dialogSubirImgs.titulo_modal = 'Imagenes para el Local '
      }
    },
    verTerminales (item) {
      this.dialogTerminales.local = item
      this.dialogTerminales.activo = true
    },
    setModalTerminales (value) {
      this.dialogTerminales.activo = value
      if (!this.dialogTerminales.activo) {
        this.dialogTerminales.local = {}
      }
    }
  },
  watch: {
    locales: function(){
      if (this.locales.length > 0){
        for (let id in this.locales){
          this.locales[id].fecha_grabacion = moment(this.locales[id].fecha_grabacion).format('DD/MM/YYYY')
        }
      }
    }
  },
}
</script>

<style>

</style>