<template>
  <div>
    <v-row class="d-flex justify-center mt-0 mx-md-2 mx-n1 mb-0">
      <!-- Titulo -->
      <v-col cols="12" class="d-flex justify-end align-center pt-0 px-1">
        <!-- <div v-if="nuevaOrden == true">Nueva Orden de Compra</div>
        <div v-else>Orden de Compra N° {{ $route.params.id }}</div> -->
        
        <div v-if="!nuevaOrden">Orden de Compra N° {{ $route.params.id }}</div>
        <v-spacer></v-spacer>
        <v-expand-x-transition>
          <v-switch
            v-model="editar"
            label="Editar"
            class="d-flex align-center py-0 my-0"
            hide-details
            dense
            v-if="nuevaOrden == false && puedeEditar == true && tienePermiso == 1"
          ></v-switch>
        </v-expand-x-transition>
      </v-col>
      <!-- Contenido -->
      <v-col cols="12">
        <v-card
          flat
          width="auto"
        >
          <v-card-text>
            <!-- Fecha, Usuario, Empresa y Proveedor -->
            <v-row :no-gutters="$vuetify.breakpoint.xs">
              <v-col cols="12" sm="2" md="2" class="py-0">
                <FechaPicker
                  v-model="fecha"
                  label="Fecha"
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="2" md="2" class="py-0">
                <v-text-field
                  v-model="usuario"
                  outlined
                  dense
                  type="text"
                  hide-details
                  label="Usuario"
                  readonly
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12" sm="3" md="3" class="py-0">
                <v-autocomplete
                  v-model="selectedEmpresa"
                  item-text="nombre_corto"
                  :items="empresas"
                  hide-details
                  outlined
                  dense
                  return-object
                  label="Empresa"
                  @change="getArtsAndPedidos()"
                  :disabled="articulos.length > 0 || nuevaOrden == false"
                ></v-autocomplete>
              </v-col> -->
              <v-col cols="12" sm="8" md="8" class="py-0">
                <v-autocomplete
                  v-model="selectedProveedor"
                  item-text="proveedor_nombre"
                  :items="proveedores"
                  hide-details
                  outlined
                  dense
                  return-object
                  label="Proveedor"
                  @change="getArtsAndPedidos()"
                  :disabled="articulos.length > 0 || nuevaOrden == false"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <!-- 10-05-2023 por MMURILLO, Moneda y Cotización -->
            <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-0">
              <v-col cols="12" sm="3" md="3" class="py-0">
                <v-autocomplete
                  v-model="selectedMoneda"
                  item-text="moneda_nombre"
                  :items="monedas"
                  hide-details
                  outlined
                  dense
                  return-object
                  label="Moneda"
                  :disabled="cotizaPedido || nuevaOrden == false"
                  @change="verificarCotizacion()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="py-0">
                <v-text-field
                  v-model="cotizacion"
                  :id="cotRef"
                  :ref="cotRef"
                  outlined
                  dense
                  type="number"
                  hide-details
                  label="Cotizacion"
                  :readonly="Object.keys(selectedMoneda).length == 0 || (!editar && !nuevaOrden) || selectedMoneda.cotizacion == 0"
                  @blur="verificarCotizacion()"
                  @keypress.native.enter="$refs[cotRef].blur()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="7" class="d-flex justify-end align-center py-0">
                <input
                  ref="input"
                  type="file"
                  class="d-none"
                  accept="text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.oasis.opendocument.spreadsheet"
                  @change="validar_archivo($event)"
                >
                <v-btn
                  color="green darken-3"
                  :disabled="nuevaOrden == false || articulos.length > 0 || Object.keys(this.selectedProveedor).length == 0"
                  :dark="!(nuevaOrden == false || articulos.length > 0 || Object.keys(this.selectedProveedor).length == 0)"
                  @click="$refs.input.click()"
                >
                  IMPORTAR ARCHIVO
                </v-btn>
                <download-excel
                  v-if="nuevaOrden == true"
                  name="Modelo de archivo para Nueva Orden Compra.xls"
                  :data="[{a:'',b:'',c:'',d:'',e:'',f:'',g:'',h:'',i:'',j:'',k:'',l:'',m:'',n:''}]"
                  :fields="{
                    'Artículo código': 'a',
                    'Artículo proveedor': 'b',
                    'Descripción': 'c',
                    'Salta': 'd',
                    'Tucuman': 'e',
                    'Chaco': 'f',
                    'Santiago': 'g',
                    'Misiones': 'h',
                    'Formosa': 'i',
                    'Jujuy': 'j',
                    'Corrientes': 'k',
                    'La Rioja': 'l',
                    'Cantidad': 'm',
                    'Precio unitario': 'n'
                  }"
                >
                  <v-btn
                    title="Descargar modelo"
                    color="indigo"
                    class="ml-2"
                    :x-small="$vuetify.breakpoint.smAndDown"
                    :small="$vuetify.breakpoint.mdAndUp"
                    :fab="$vuetify.breakpoint.smAndDown"
                    dark
                  >
                    <v-icon :left="$vuetify.breakpoint.mdAndUp" small>fas fa-file-download</v-icon>
                    <span v-text="$vuetify.breakpoint.mdAndUp ? 'Modelo' : ''"></span>
                  </v-btn>
                </download-excel>
              </v-col>
            </v-row>
            <!-- Pedidos -->
            <v-row :no-gutters="$vuetify.breakpoint.xs">
              <v-col cols="12">
                <v-card
                  elevation="2"
                  outlined
                >
                  <v-card-title>
                    Pedidos
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="9" md="9" class="py-2">
                        <v-autocomplete
                          v-model="selectedPedidos"
                          item-text="pedido"
                          :items="pedidos"
                          hide-details
                          outlined
                          dense
                          return-object
                          label="Pedidos"
                          :disabled="pedidos.length == 0 || nuevaOrden == false || articulos.length > 0"
                          multiple
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" class="py-2 d-flex justify-end align-end" style="">
                        <v-btn color="info" :disabled="nuevaOrden == false || selectedPedidos.length == 0 || articulos.length > 0" @click="importarDetalles()">Importar Detalles</v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <!-- Artículos -->
            <v-row :no-gutters="$vuetify.breakpoint.xs">
              <v-col cols="12">
                <v-card
                  elevation="2"
                  outlined
                >
                  <v-card-title>
                    Artículos
                    <v-btn icon title="Buscar Artículos" @click="seleccionarArticulo()" v-if="editar == true || nuevaOrden == true"><v-icon color="success" small>fas fa-search</v-icon></v-btn>
                    <v-spacer></v-spacer>
                    <v-switch
                      v-model="metodoIngresoArts"
                      :label="msjMetodoIngresoArts"
                      class="d-flex align-center py-0 my-0"
                      hide-details
                      dense
                      :disabled="articulos.length > 0"
                    ></v-switch>
                  </v-card-title>
                  <v-card-text class="pt-4">
                    <!-- Agregar un Artículo -->
                    <v-row v-if="nuevaOrden == true || editar == true">
                      <v-col cols="12" sm="1" md="1" class="py-1">
                        <v-text-field
                          v-model="cantidad"
                          outlined
                          dense
                          type="number"
                          hide-details
                          label="Cant."
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" class="py-1">
                        <v-text-field
                          v-model="codArticulo"
                          :id="codArtRef"
                          :ref="codArtRef"
                          outlined
                          dense
                          type="number"
                          hide-details
                          label="Cód. Artículo"
                          @blur="completarArticulo()"
                          @keypress.native.enter="$refs[codArtRef].blur()"
                          :disabled="metodoIngresoArts == false"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" class="py-1">
                        <v-text-field
                          v-model="codBarras"
                          :id="codBarrasRef"
                          :ref="codBarrasRef"
                          outlined
                          dense
                          type="text"
                          hide-details
                          label="Cód. Barra"
                          @blur="completarArticulo()"
                          @keypress.native.enter="$refs[codBarrasRef].blur()"
                          :disabled="metodoIngresoArts == true"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" class="py-1">
                        <v-text-field
                          v-model="artNombre"
                          outlined
                          dense
                          type="text"
                          hide-details
                          label="Descripcion"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" class="py-1">
                        <v-text-field
                          v-model="precio"
                          outlined
                          dense
                          type="number"
                          hide-details
                          label="Precio Unit."
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" class="py-1 d-flex justify-end align-end">
                        <v-btn color="info" @click.stop="completarArticulo()">Agregar</v-btn>
                      </v-col>
                    </v-row>
                    <!-- Listado de Artículos -->
                    <v-row>
                      <v-col cols="12">
                        <v-data-table
                          class="cebra elevation-2 mt-2"
                          :headers="headers"
                          :items="articulos"
                          :loading="load"
                          :search="search"
                          dense
                          item-key="index"
                        >
                          <!-- Buscador en el datatable -->
                          <template v-slot:top>
                            <v-row class="d-flex justify-end pa-2" no-gutters>
                              <v-col cols="6" sm="3">
                                <SearchDataTable
                                  v-model="search"
                                />
                              </v-col>
                            </v-row>
                          </template>
                          <!-- Msj que se mostrara si no existen resultados -->
                          <template v-slot:no-data>
                            <v-alert
                              class="mx-auto mt-4"
                              max-width="400"
                              type="warning"
                              border="left"
                              dense
                              text
                            >
                              Sin Artículos para mostrar.
                            </v-alert>
                          </template>
                          <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
                          <template
                            v-for="header in headersFP.filter((header) => header.hasOwnProperty('formatter'))"
                            v-slot:[`item.${header.value}`]="{ value }"
                          >
                            {{ header.formatter(value) }}
                          </template>
                          <!-- Cantidad -->
                          <template v-slot:[`item.cantidad`]="{ item }">
                            <v-row class="d-flex justify-center" no-gutters>
                              <v-text-field 
                                v-model="item.cantidad" 
                                :id="cantRef"
                                :ref="cantRef"
                                type="number" 
                                outlined dense 
                                style="width: 50px" 
                                hide-details
                                :readonly="editar == false && nuevaOrden == false"
                                @blur="recalcularSubTotales(item)"
                                @keypress.native.enter="$refs[cantRef].blur()"></v-text-field>
                            </v-row>
                          </template>
                          <!-- Precio Unitario -->
                          <template v-slot:[`item.precio`]="{ item }">
                            <v-row class="d-flex justify-center" no-gutters>
                              <v-text-field 
                                v-model="item.precio" 
                                :id="precioRef"
                                :ref="precioRef"
                                type="number" 
                                outlined dense 
                                style="width: 50px" 
                                hide-details
                                @blur="recalcularSubTotales(item)"
                                @keypress.native.enter="$refs[precioRef].blur()"
                                :readonly="editar == false && nuevaOrden == false"
                                prefix="$"
                              ></v-text-field>
                            </v-row>
                          </template>
                          <!-- % Imp. Interno -->
                          <template v-slot:[`item.porc_imp_interno`]="{ item }">
                            <v-row class="d-flex justify-center" no-gutters>
                              <v-text-field 
                                v-model="item.porc_imp_interno" 
                                :id="porc_imp_ref"
                                :ref="porc_imp_ref"
                                type="number" 
                                outlined dense 
                                style="width: 50px" 
                                hide-details
                                @blur="recalcularSubTotales(item)"
                                @keypress.native.enter="$refs[porc_imp_ref].blur()"
                                :readonly="editar == false && nuevaOrden == false"
                                prefix="%"
                              ></v-text-field>
                            </v-row>
                          </template>
                          <template v-slot:[`item.imp_interno`]="{ item }">
                            {{ format_money(item.imp_interno) }}
                          </template>
                          <!-- Acciones -->
                          <template v-slot:[`item.acciones`]="{ item }">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn small icon color="info" @click="verDestinos(1, item)" v-on="on">
                                  <v-icon small>fas fa-list</v-icon>
                                </v-btn>
                              </template>
                              <span>Ver Destinos</span>
                            </v-tooltip>
                            <v-tooltip bottom v-if="nuevaOrden == true || editar == true">
                              <template v-slot:activator="{ on }">
                                <v-btn small icon color="error" @click="quitarDetalle(item)" v-on="on">
                                  <v-icon small>far fa-times-circle</v-icon>
                                </v-btn>
                              </template>
                              <span>Quitar</span>
                            </v-tooltip>
                            <v-tooltip bottom v-if="item.destino_ok == 0">
                              <template v-slot:activator="{ on }">
                                <v-btn small icon color="warning" @click="recalcularSubTotales(item)" v-on="on">
                                  <v-icon small>fas fa-exclamation-circle</v-icon>
                                </v-btn>
                              </template>
                              <span>No se completaron los Destinos</span>
                            </v-tooltip>
                            <v-tooltip bottom v-if="item.articulo_codigo == 0 || item.articulo_codigo == null || item.articulo_codigo == undefined || item.articulo_codigo.toString().length == 0">
                              <template v-slot:activator="{ on }">
                                <v-btn small icon color="warning" @click="asociarArticulo(item)" v-on="on">
                                  <v-icon small>fas fa-exclamation-triangle</v-icon>
                                </v-btn>
                              </template>
                              <span>Asociar Artículo</span>
                            </v-tooltip>
                          </template>
                          <!-- ultima row para el total -->
                          <template slot="body.append">
                            <tr class="font-weight-bold">
                              <th></th>
                              <th></th>
                              <th></th>
                              <th class="text-center">Cant. Total: {{ cantTotalOC }}</th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th class="text-center">Total: ${{ Intl.NumberFormat("de-DE").format(totalOC) }}</th>
                              <th></th>
                              <th></th>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <!-- Fechas de Pago -->
            <v-row :no-gutters="$vuetify.breakpoint.xs">
              <v-col cols="12">
                <v-card
                  elevation="2"
                  outlined
                >
                  <v-card-title>
                    Fechas de Pago
                    <v-spacer></v-spacer>
                    <v-switch
                      v-model="pagoFormaIngreso"
                      class="d-flex align-center py-0 my-0"
                      hide-details
                      dense
                      :label="`Forma de Ingreso: ${pagoNombreForIngreso}`"
                      :disabled="editar == false && nuevaOrden == false"
                    ></v-switch>
                  </v-card-title>
                  <v-card-text class="pt-4">
                    <!-- Agregar Fecha de Pago -->
                    <v-row v-if="nuevaOrden == true || editar == true">
                      <v-col cols="12" sm="2" md="2" class="py-1">
                        <FechaPicker
                          v-model="fechaPago"
                          label="Fecha"
                          @blur="validaFechaPago()"
                        />
                      </v-col>
                      <v-col cols="12" sm="2" md="2" class="py-1">
                        <v-text-field
                          v-model="porcentaje"
                          :id="porcentajeRef"
                          :ref="porcentajeRef"
                          outlined
                          dense
                          type="number"
                          hide-details
                          label="Porcentaje"
                          prefix="%"
                          :readonly="pagoFormaIngreso == true"
                          @blur="validarPorcentajePago()"
                          @keypress.native.enter="$refs[porcentajeRef].blur()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" class="py-1">
                        <v-text-field
                          v-model="importe"
                          :id="importeRef"
                          :ref="importeRef"
                          outlined
                          dense
                          type="number"
                          hide-details
                          label="Importe"
                          prefix="$"
                          :readonly="pagoFormaIngreso == false"
                          @blur="validarImportePago()"
                          @keypress.native.enter="$refs[importeRef].blur()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" class="py-1">
                        <v-autocomplete
                          v-model="selectedFP"
                          item-text="forma_pago_nombre"
                          :items="formasPago"
                          hide-details
                          outlined
                          dense
                          return-object
                          label="Forma de Pago"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" class="py-1">
                        <v-autocomplete
                          v-model="selectedTipoPago"
                          item-text="tipo_pago_nombre"
                          :items="tiposPago"
                          hide-details
                          outlined
                          dense
                          return-object
                          label="Tipo Pago"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="1" md="1" class="py-1 justify-end align-end">
                        <v-btn icon @click="addPago()" title="Agregar Pago"><v-icon color="success">far fa-check-circle</v-icon></v-btn>
                      </v-col>
                    </v-row>
                    <!-- Listado de Fechas de Pago -->
                    <v-row>
                      <v-col cols="12">
                        <v-data-table
                          class="cebra elevation-2 mt-2"
                          :headers="headersFP"
                          :items="formasPagoOC"
                          :loading="loadFP"
                          :search="searchFP"
                          dense
                          item-key="index"
                        >
                          <!-- Buscador en el datatable -->
                          <template v-slot:top>
                            <v-row class="d-flex justify-end pa-2" no-gutters>
                              <v-col cols="6" sm="3">
                                <SearchDataTable
                                  v-model="searchFP"
                                />
                              </v-col>
                            </v-row>
                          </template>
                          <!-- Msj que se mostrara si no existen resultados -->
                          <template v-slot:no-data>
                            <v-alert
                              class="mx-auto mt-4"
                              max-width="400"
                              type="warning"
                              border="left"
                              dense
                              text
                            >
                              Sin Fechas de Pago ingresadas.
                            </v-alert>
                          </template>
                          <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
                          <template
                            v-for="header in headersFP.filter((header) => header.hasOwnProperty('formatter'))"
                            v-slot:[`item.${header.value}`]="{ value }"
                          >
                            {{ header.formatter(value) }}
                          </template>
                          <!-- Items -->
                          <template v-slot:[`item.porcentaje_pago`]="{ item }">
                            {{ item.porcentaje_pago }}%
                          </template>
                          <!-- ultima row para el total -->
                          <template slot="body.append">
                            <tr class="font-weight-bold">
                              <th></th>
                              <th class="text-end">Porc. Pago. Total: {{ totalFinalPorcentaje }}%</th>
                              <th></th>
                              <th class="text-end" colspan="2">Imp. Pago. Total: ${{ Intl.NumberFormat("de-DE").format(totalFinalPagos) }}</th>
                              <th></th>
                            </tr>
                          </template>
                          <!-- Acciones -->
                          <template v-slot:[`item.acciones`]="{ item }">
                            <v-tooltip bottom v-if="nuevaOrden == true || editar == true">
                              <template v-slot:activator="{ on }">
                                <v-btn small icon color="error" @click="quitarPago(item)" v-on="on">
                                  <v-icon small>far fa-times-circle</v-icon>
                                </v-btn>
                              </template>
                              <span>Quitar Pago</span>
                            </v-tooltip>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <!-- Botones -->
          <v-card-actions class="d-flex justify-end mx-2">
            <v-btn color="warning" @click="volverIndex()" class="ma-2">Volver</v-btn>
            <v-btn color="success" class="ma-2" v-if="nuevaOrden == false && pagada == 0" @click="confirmarMarcarPagada()"><v-icon small class="ma-2">far fa-check-circle</v-icon> Pagada</v-btn>
            <v-btn color="error" class="ma-2" v-if="nuevaOrden == false && pagada == 1" @click="confirmarMarcarNoPagada()"><v-icon small class="ma-2">far fa-times-circle</v-icon> No Pagada</v-btn>
            <v-btn color="info" class="ma-2" v-if="nuevaOrden == false" @click="dialogVerFactCompra.activo = true; dialogVerFactCompra.orden_id = ordenId"><v-icon small class="ma-2">fas fa-clipboard-list</v-icon> Facturas Compra</v-btn>
            <v-btn color="info" class="ma-2" v-if="nuevaOrden == false" @click="dialogVerRecepciones.activo = true; dialogVerRecepciones.orden_id = ordenId"><v-icon small class="ma-2">fas fa-list</v-icon> Recepciones</v-btn>
            <BtnConfirmar
              @action="sendOC()"
              color="success"
              :texto="`¿Esta seguro de registrar la nueva Orden de Compra?`"
              nombre="Guardar"
              v-if="nuevaOrden == true"
            />
            <BtnConfirmar
              @action="updateOC()"
              color="success"
              :texto="`¿Esta seguro de modificar Orden de Compra ${ordenId}?`"
              nombre="Modificar"
              clase="ma-2"
              v-if="nuevaOrden == false && editar == true"
            />
            <BtnConfirmar
              @action="cancelOC()"
              color="error"
              :texto="`¿Esta seguro de anular Orden de Compra ${ordenId}?`"
              nombre="Anular"
              clase="ma-2"
              icono="fas fa-trash-alt"
              v-if="nuevaOrden == false && editar == true"
            />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- Modal Seleccionar un Artículo -->
    <ModalSelectArticulo 
      :datos="dialogSelectArticulo"
      @setearModalSelectArt="setModalSelectArt"
      @setearArticulo="setArticulo"
    />
    <!-- Modal Ingresar Destinos -->
    <ModalIngresarDestinos 
      :datos="dialogDestinos"
      @setearModalIngDestinos="setModalIngDestinos"
      @setearDestinos="setDestinos"
      @updateDestinos="upDestinos"
    />
    <!-- Modal Asociar Articulos -->
    <ModalAsociarArticulo 
      :datos="dialogAsociarArts"
      @setearModalAsocArt="setModalAsocArt"
      @setearAsociacion="setAsociacion"
    />
    <!-- Modal Confirmar Marcar como Pagada -->
    <ModalConfirmar 
      :activoDialog="modalMarcarPagada"
      :texto="`¿Está seguro de marcar como Pagada a la Orden de Compra ${ordenId}?`"
      @action="marcarComoPagada()"
      @setearModalConfAccion="setMarcarPagada"
    />
    <!-- Modal Confirmar Marcar como No Pagada -->
    <ModalConfirmar 
      :activoDialog="modalMarcarNoPagada"
      :texto="`¿Está seguro de marcar como NO Pagada a la Orden de Compra ${ordenId}?`"
      @action="marcarComoNoPagada()"
      @setearModalConfAccion="setMarcarNoPagada"
    />
    <!-- Modal Ver Facturas de Compra -->
    <ModalVerFactCompra 
      :datos="dialogVerFactCompra"
      @setearModalVerFactComp="setModalVerFactComp"
    />
    <!-- Modal Ver Recepciones -->
    <ModalVerRecepciones 
      :datos="dialogVerRecepciones"
      @setearModalVerRecep="setModalVerRecep"
    />
  </div>
</template>

<script>
import FechaPicker from '../../components/util/FechaPicker.vue'
import SearchDataTable from '../../components/util/SearchDataTable.vue'
import ModalSelectArticulo from '../../components/util/ModalSelectArticulo.vue'
import ModalIngresarDestinos from '../../components/ordenes-compra/ModalIngresarDestinos.vue'
import BtnConfirmar from '../../components/util/BtnConfirmar.vue'
import ModalAsociarArticulo from '../../components/ordenes-compra/ModalAsociarArticulo.vue'
import ModalConfirmar from '../../components/util/ModalConfirmar.vue'
import ModalVerFactCompra from '../../components/ordenes-compra/ModalVerFactCompra.vue'
import ModalVerRecepciones from '../../components/ordenes-compra/ModalVerRecepciones.vue'
import moment from 'moment'
import { roundNumber, convertDecimalsPlus, format_money, order_list_by, tipos_archivos } from '../../util/utils'
import router from '../../router'
import { read, utils } from 'xlsx'

export default {
  data() {
    return {
      format_money: format_money,
      nuevaOrden: false,
      editar: false,
      puedeEditar: false,
      puedeAnular: false,
      ordenId: 0,
      fecha: '',
      selectedEmpresa: {},
      empresas: [],
      usuario: '',
      proveedores: [],
      selectedProveedor: {},
      pedidos: [],
      selectedPedidos: [],
      headers: [
        { text: 'Cod. Artículo', align: 'center', value: 'articulo_codigo' },
        { text: 'Cod. Barras', align: 'center', value: 'articulo_cod_barras' },
        { text: 'Descripcion', value: 'articulo_nombre' },
        { text: 'Cantidad', align: 'end', value: 'cantidad' },
        { text: 'Precio Unitario', align: 'end', value: 'precio' },
        { text: 'SubTotal', align: 'end', value: 'importe', formatter: format_money },
        { text: '% Imp. Interno', align: 'end', value: 'porc_imp_interno' },
        { text: 'Imp. Interno', align: 'end', value: 'imp_interno' },
        { text: 'Pend. Recep.', align: 'end', value: 'pend_recep' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      articulos: [],
      load: false,
      search: '',
      fechaPago: moment(new Date()).format('DD/MM/YYYY'),
      formasPago: [],
      selectedFP: {},
      importe: '',
      porcentaje: '',
      headersFP: [
        { text: 'Fecha', value: 'fecha_pago' },
        { text: 'Porcentaje', align: 'end', value: 'porcentaje_pago' },
        { text: 'Forma de Pago',value: 'forma_pago' },
        { text: 'Tipo de Pago', value: 'tipo_pago_nombre' },
        { text: 'Monto', align: 'end', value: 'importe', formatter: format_money },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      formasPagoOC: [],
      loadFP: false,
      searchFP: '',
      cantidad: '',
      codArticulo: '',
      codBarras: '',
      artNombre: '',
      precio: '',
      gravamenFormula: '',
      proveedorArticuloId: '',
      dialogSelectArticulo: {
        activo: false,
        articulos: [],
        bloquear_cant: false,
        bloquear_precio: false,
        cerrar_seleccion: false,
        titulo_modal: 'Buscar Artículos',
        color: ''
      },
      codArtRef: 'codArtRef',
      codBarrasRef: 'codBarrasRef',
      cantRef: 'cantRef',
      precioRef: 'precioRef',
      porc_imp_ref: 'porc_imp_ref',
      imp_ref: 'imp_ref',
      dialogDestinos: {
        activo: false,
        sucursales: [],
        cantidad: 0,
        nuevo: 0,
        ver: 0,
        destinos_actuales: []
      },
      posActual: -1,
      totalOC: 0,
      cantTotalOC: 0,
      metodoIngresoArts: false,
      msjMetodoIngresoArts: 'Por Código de Barras',
      pagoFormaIngreso: false,
      pagoNombreForIngreso: 'Porcentaje',
      porcentajeRef: 'porcentajeRef',
      importeRef: 'importeRef',
      totalFinalPorcentaje: 0,
      totalFinalPagos: 0,
      dialogAsociarArts: {
        activo: false,
        articulos: [],
        item: {},
        proveedor_codigo: 0,
        proveedor_nombre: ''
      },
      pagada: 0,
      modalMarcarPagada: false,
      modalMarcarNoPagada: false,
      dialogVerFactCompra: {
        activo: false,
        orden_id: 0
      },
      dialogVerRecepciones: {
        activo: false,
        orden_id: 0,
        factura_id: 0
      },
      selectedMoneda: {},
      monedas: [],
      cotizacion: '',
      cotizaPedido: false,
      cotRef: 'cotRef',
      selectedTipoPago: {},
      tiposPago: [],
      tienePermiso: 0
    }
  },
  components: {
    FechaPicker,
    SearchDataTable,
    ModalSelectArticulo,
    ModalIngresarDestinos,
    BtnConfirmar,
    ModalAsociarArticulo,
    ModalConfirmar,
    ModalVerFactCompra,
    ModalVerRecepciones
  },
  created () {
    if (Object.keys(this.$route.params).length == 0){
      this.ordenId = 0
      this.nuevaOrden = true
    }
    else{
      this.ordenId = this.$route.params.id
      this.nuevaOrden = false
    }
    this.initForm()
  },
  methods: {
    async initForm(){
      this.empresas = JSON.parse(localStorage.getItem('empresas'))
      this.$store.state.loading = true
      let initPeticion = await this.$store.dispatch('ordenesCompra/initFormUnaOC', {nuevo: this.nuevaOrden == true ? 1 : 0, orden_id: this.ordenId})
      this.$store.state.loading = false
      if (initPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: initPeticion.msj,
          color: 'error',
        })
        return
      }
      // proveedores
      this.proveedores = initPeticion.proveedores
      // formas de pago
      this.formasPago = initPeticion.fps
      // 18-04-2023 por MMURILLO, sucursales
      this.dialogDestinos.sucursales = initPeticion.sucursales
      // 10-05-2023 por MMURILLO, monedas
      this.monedas = initPeticion.monedas
      // 12-05-2023 por MMURILLO, tipos pagos
      this.tiposPago = initPeticion.tipos_pago
      // 07-06-2023 por MMURILLO, permiso para editar
      this.tienePermiso = initPeticion.permiso
      if (this.nuevaOrden == true){
        // es nueva orden de compra, asigno la fecha
        this.fecha = moment(new Date()).format('DD/MM/YYYY')
        // usuario
        this.usuario = this.$store.state.username
        // si tiene la empresa RFB en su listado, la asigno por defecto
        let unaEmp = this.empresas.filter(element => element.id == 2)
        if (unaEmp.length > 0) this.selectedEmpresa = unaEmp[0]
      }else{
        // es una orden de compra ya creada, voy llenando los datos, fecha
        this.fecha = moment(initPeticion.orden.encabezado[0].fecha).format('DD/MM/YYYY')
        // usuario
        this.usuario = initPeticion.orden.encabezado[0].usuario
        // empresa
        let unaEmp = this.empresas.filter(element => element.id == initPeticion.orden.encabezado[0].empresa_codigo)
        if (unaEmp.length > 0) this.selectedEmpresa = unaEmp[0]
        // proveedor
        let unProv = this.proveedores.filter(element => element.proveedor_codigo == initPeticion.orden.encabezado[0].proveedor_codigo)
        if (unProv.length > 0) this.selectedProveedor = unProv[0]
        // puede modificar?
        this.puedeEditar = initPeticion.orden.encabezado[0].modifica == 0 ? false : true
        // puede anular?
        this.puedeAnular = initPeticion.orden.encabezado[0].anula == 0 ? false : true
        // pagada?
        this.pagada = initPeticion.orden.encabezado[0].pagado
        // 11-05-2023 por MMURILLO, moneda
        this.selectedMoneda = this.monedas.filter(element => element.moneda_id == initPeticion.orden.encabezado[0].moneda_id)[0]
        // 11-05-2023 por MMURILLO, cotizacion
        if (roundNumber(initPeticion.orden.encabezado[0].cotizacion, 2) > 0) this.cotizacion = roundNumber(initPeticion.orden.encabezado[0].cotizacion, 2)
        // pedidos
        if (initPeticion.orden.pedidos.length > 0){
          let pedidosAux = initPeticion.orden.pedidos
          let listado = []
          for (let id in pedidosAux){
            let unPedido = {
              pedido_id: pedidosAux[id].pedido_id,
              fecha_pedido: pedidosAux[id].fecha_pedido,
              pedido: pedidosAux[id].pedido,
              moneda_id: pedidosAux[id].moneda_id
            }
            listado.push(unPedido)
          }
          // asigno a los pedidos seleccionados
          this.pedidos = listado
          this.selectedPedidos = this.pedidos
        }
        // detalle
        let detalles = initPeticion.orden.detalles
        // los ingresaron con código de barras?
        let conCodBarras = detalles.filter(element => element.articulo_cod_barras != null && element.articulo_cod_barras.toString().length > 0)
        if (conCodBarras.length > 0) this.metodoIngresoArts = false
        else this.metodoIngresoArts = true
        // armo los detalles
        for (let id in detalles){
          let unDet = {
            id: detalles[id].id,
            articulo_codigo: detalles[id].articulo_codigo,
            articulo_cod_barras: detalles[id].articulo_cod_barras,
            articulo_nombre: detalles[id].articulo_nombre,
            cantidad: parseInt(detalles[id].cantidad),
            precio: roundNumber(detalles[id].precio, 2),
            importe: roundNumber(detalles[id].importe, 2),
            gravamen: convertDecimalsPlus(detalles[id].gravamen_formula),
            destinos: [],
            proveedor_articulo_id: detalles[id].proveedor_articulo_id,
            destino_ok: 1,
            porc_imp_interno: roundNumber(roundNumber(detalles[id].porc_imp_interno, 4) * 100, 4),
            imp_interno: roundNumber(detalles[id].imp_interno, 4),
            pend_recep: detalles[id].pend_recep
          }
          // rearmo el array de destinos
          let destinosFinal = []
          for (let jd in this.dialogDestinos.sucursales){
            let unDest = {
              cantidad: 0,
              sucursal_codigo: this.dialogDestinos.sucursales[jd].id,
              sucursal_nombre: this.dialogDestinos.sucursales[jd].nombre
            }
            let unaSuc = detalles[id].destinos.filter(element => element.sucursal_codigo == this.dialogDestinos.sucursales[jd].id)
            if (unaSuc.length > 0) unDest.cantidad = unaSuc[0].cantidad
            destinosFinal.push(unDest)
          }
          // asigno los destinos
          unDet.destinos = destinosFinal
          // lo agrego al detalle
          this.articulos.push(unDet)
        }
        // fechas de pago
        let pagos = initPeticion.orden.fechas_pago
        for (let id in pagos){
          let unaFP = this.formasPago.filter(element => element.forma_pago_id == pagos[id].forma_pago_id)
          if (unaFP.length == 0){
            this.$store.dispatch('show_snackbar', {
              text: 'No se pudo determinar una de las Formas de Pago. No se puede continuar.',
              color: 'error',
            })
            return
          }
          // 12-05-2023 por MMURILLO, tipo de pago
          let unTP = this.tiposPago.filter(element => element.tipo_pago_id == pagos[id].tipo_pago_id && parseInt(pagos[id].tipo_pago_id) > 0)
          // tipo de pago
          let unPago = {
            fecha_pago: moment(pagos[id].fecha).format("DD/MM/YYYY"),
            porcentaje_pago: roundNumber(pagos[id].porcentaje, 2),
            forma_pago: unaFP[0].forma_pago_nombre,
            forma_pago_id: unaFP[0].forma_pago_id,
            importe: roundNumber(pagos[id].importe, 2),
            tipo_pago_id: unTP.length > 0 ? unTP[0].tipo_pago_id : 0,
            tipo_pago_nombre: unTP.length > 0 ? unTP[0].tipo_pago_nombre : '',
            id: pagos[id].id
          }
          this.formasPagoOC.push(unPago)
        }
        // cuando termino, calculo totales
        this.calcularTotales()
      }
    },
    async getArtsAndPedidos(){
      if (Object.keys(this.selectedEmpresa).length > 0 && Object.keys(this.selectedProveedor).length > 0){
        this.dialogSelectArticulo.articulos = []
        this.pedidos = []
        this.selectedPedidos = []
        // paso a obtener los articulos y pedidos asociados
        this.$store.state.loading = true
        let infoPeticion = await this.$store.dispatch('ordenesCompra/getArtsAndPedidos', {
          empresa_codigo: this.selectedEmpresa.id,
          proveedor_codigo: this.selectedProveedor.proveedor_codigo,
          todos: this.metodoIngresoArts == false ? 0 : 1
        })
        this.$store.state.loading = false
        if (infoPeticion.resultado == 1){
          this.dialogSelectArticulo.articulos = infoPeticion.articulos
          this.pedidos = infoPeticion.pedidos
          for (let id in this.dialogSelectArticulo.articulos){
            this.dialogSelectArticulo.articulos[id].precio_s_iva = roundNumber(this.dialogSelectArticulo.articulos[id].precio_s_iva, 2)
            this.dialogSelectArticulo.articulos[id].formula = roundNumber(this.dialogSelectArticulo.articulos[id].formula, 3)
          }
        }else{
          this.$store.dispatch('show_snackbar', {
            text: infoPeticion.msj,
            color: 'error',
          })
        }
      }
    },
    seleccionarArticulo(){
      if (Object.keys(this.selectedEmpresa).length == 0 || Object.keys(this.selectedProveedor).length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe selecccionar la Empresa y el Proveedor para realizar la búsqueda',
          color: 'warning',
        })
        return
      }
      // abro el modal
      this.dialogSelectArticulo.activo = true
    },
    setModalSelectArt(value){
      this.dialogSelectArticulo.activo = value
    },
    limpiarSeccionArticulos(){
      this.cantidad = ''
      this.codArticulo = ''
      this.codBarras = ''
      this.artNombre = ''
      this.precio = ''
      this.gravamenFormula = ''
      this.proveedorArticuloId = ''
    },
    setArticulo(art){
      // completo la info que tengo en la seccion agregar artículos
      this.cantidad = art.cantidad
      this.codArticulo = art.articulo_codigo
      this.codBarras = art.articulo_cod_barras
      this.artNombre = art.articulo_nombre
      this.precio = roundNumber(art.precio_s_iva, 2)
      this.gravamenFormula = art.formula
      this.proveedorArticuloId = art.proveedores_articulos_id
      this.completarArticulo()
    },
    async completarArticulo(){
      // cantidad
      if (this.cantidad == null || this.cantidad == undefined || this.cantidad.toString().length == 0 || this.cantidad == 0) this.cantidad = 1
      // codigo de artículo de BB o de Barras
      if ((this.codArticulo == null || this.codArticulo == undefined || this.codArticulo.toString().length == 0) &&
      (this.codBarras == null || this.codBarras == undefined || this.codBarras.toString().length == 0)){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar el código usando el de Artículo ó bien el código de Barras.',
          color: 'warning',
        })
        return
      }
      // completo el nombre y el precio del artículo
      if (this.artNombre == null || this.artNombre == undefined || this.artNombre.toString().length == 0 ||
      this.precio == null || this.precio == undefined || this.precio.toString().length == 0 || this.gravamenFormula == null || this.gravamenFormula == undefined ||
      this.gravamenFormula.toString().length == 0 || this.proveedorArticuloId == null || this.proveedorArticuloId == undefined || this.proveedorArticuloId.toString().length == 0){
        // primero busco en el array de articulos que ya tengo
        let unArt = []
        if (this.codArticulo.toString().length > 0) unArt = this.dialogSelectArticulo.articulos.filter(element => element.articulo_codigo == this.codArticulo)
        else unArt = this.dialogSelectArticulo.articulos.filter(element => element.articulo_cod_barras != null && element.articulo_cod_barras.toUpperCase() == this.codBarras.toString().toUpperCase())
        if (unArt.length == 0){
          // no lo encontré, lo busco siempre y cuando me hayan ingresado el código de artículo de BB
          if (this.codArticulo.toString().length == 0){
            this.$store.dispatch('show_snackbar', {
              text: 'El artículo a agregar no figura asociado para el proveedor seleccionado. Intente ingresar por código de Artículo.',
              color: 'info',
            })
            this.limpiarSeccionArticulos()
            return
          }
          // busco el artículo
          this.$store.state.loading = true
          let datosPeticion = await this.$store.dispatch('ordenesCompra/getArticuloData', {articulo_codigo: this.codArticulo})
          this.$store.state.loading = false
          if (datosPeticion.resultado == 1){
            if (datosPeticion.articulos.length > 0){
              this.codArticulo = datosPeticion.articulos[0].articulo_codigo
              this.codBarras = datosPeticion.articulos[0].articulo_cod_barras
              this.artNombre = datosPeticion.articulos[0].articulo_nombre
              this.precio = datosPeticion.articulos[0].precio_s_iva
              this.gravamenFormula = datosPeticion.articulos[0].formula
              this.proveedorArticuloId = datosPeticion.articulos[0].proveedores_articulos_id
            }else{
              this.$store.dispatch('show_snackbar', {
                text: 'No se encontraron coincidencias para el artículo ingresado.',
                color: 'info',
              })
              return
            }
          }else{
            this.$store.dispatch('show_snackbar', {
              text: datosPeticion.msj,
              color: 'error',
            })
            return
          }
        }else{
          // lo encontré, completo la info
          this.codArticulo = unArt[0].articulo_codigo
          this.codBarras = unArt[0].articulo_cod_barras
          this.artNombre = unArt[0].articulo_nombre
          this.precio = unArt[0].precio_s_iva
          this.gravamenFormula = unArt[0].formula
          this.proveedorArticuloId = unArt[0].proveedores_articulos_id
        }
      }
      // existe ya en el listado?
      let existeArt = []
      if (this.codBarras != null && this.codBarras != undefined && this.codBarras.toString().length > 0){
        existeArt = this.articulos.filter(element => element.articulo_cod_barras.toString().toUpperCase() == this.codBarras.toString().toUpperCase() && element.articulo_nombre == this.artNombre)
      }else{
        existeArt = this.articulos.filter(element => element.articulo_codigo == this.codArticulo && element.articulo_nombre == this.artNombre)
      }
      if (existeArt.length > 0){
        this.$store.dispatch('show_snackbar', {
          text: 'El artículo ya existe en el Listado.',
          color: 'warning',
        })
        return
      }
      // indico que estoy agregando un nuevo detalle al listado
      this.dialogDestinos.nuevo = 1
      // con los datos de artículos completos, paso a pedir los destinos
      this.ingresarDestinos()
    },
    ingresarDestinos(){
      this.dialogDestinos.cantidad = this.cantidad
      this.dialogDestinos.activo = true
    },
    setDestinos(dest){
      // que posActual sea distinto de -1 significa que estan completando los destinos de un detalle ya existente en el listado de artículos, por lo tanto no se debe agregar
      if (this.posActual == -1){
        // aqui tengo que agregar al listado, por aqui tengo que pasar si o si siempre antes de agregar un detalle al listado
        let unArt = {
          id: 0,
          articulo_codigo: this.codArticulo,
          articulo_cod_barras: this.codBarras,
          articulo_nombre: this.artNombre,
          cantidad: parseInt(this.cantidad),
          precio: roundNumber(this.precio, 2),
          importe: roundNumber(roundNumber(this.precio, 2) * parseInt(this.cantidad), 2),
          gravamen: convertDecimalsPlus(this.gravamenFormula),
          destinos: dest,
          proveedor_articulo_id: this.proveedorArticuloId,
          destino_ok: 1,
          porc_imp_interno: 0,
          imp_interno: 0
        }
        // agrego al listado
        this.articulos.push(unArt)
        // limpio la seccion de artículos
        this.limpiarSeccionArticulos()
        // calculo totales
        this.calcularTotales()
      }else{
        this.upDestinos(dest)
      }
    },
    async setModalIngDestinos(value){
      this.dialogDestinos.activo = value
      if (this.dialogDestinos.activo == false){
        this.dialogDestinos.cantidad = 0
        this.dialogDestinos.nuevo = 0
        this.dialogDestinos.ver = 0
        this.dialogDestinos.destinos_actuales = []
      }
    },
    recalcularSubTotales(item){
      let pos = this.articulos.indexOf(item)
      // normalizo la cantidad
      if (this.articulos[pos].cantidad == null || this.articulos[pos].cantidad == undefined || this.articulos[pos].cantidad.toString().length == 0 || this.articulos[pos].cantidad == 0) this.articulos[pos].cantidad = 1 
      // normalizo el precio
      this.articulos[pos].precio = roundNumber(this.articulos[pos].precio, 2)
      // recalculo el subtotal del detalle
      this.articulos[pos].importe = roundNumber(this.articulos[pos].precio * parseInt(this.articulos[pos].cantidad), 2)
      // 29-052023 por MMURILLO, normalizo el impuesto interno
      if (this.articulos[pos].porc_imp_interno == null || this.articulos[pos].porc_imp_interno == undefined || this.articulos[pos].porc_imp_interno.toString().length == 0) this.articulos[pos].porc_imp_interno = 0
      if (this.articulos[pos].imp_interno == null || this.articulos[pos].imp_interno == undefined || this.articulos[pos].imp_interno.toString().length == 0) this.articulos[pos].imp_interno = 0
      if (roundNumber(this.articulos[pos].porc_imp_interno, 4) > 0){
        // calculo el impuesto interno de acuerdo al porcentaje
        let tasaImpInt = roundNumber(roundNumber(this.articulos[pos].porc_imp_interno, 4)/100, 4)
        let neto = this.articulos[pos].importe
        this.articulos[pos].imp_interno = roundNumber(neto * tasaImpInt, 4)
      }
      // la cantidad que ingresaron coincide con la cantidad asignada en los destinos?
      let conDestinos = this.articulos[pos].destinos.filter(element => parseInt(element.cantidad) > 0)
      if (conDestinos.length > 0){
        let cont = 0
        for (let id in conDestinos){
          cont = cont + parseInt(conDestinos[id].cantidad)
        }
        if (parseInt(cont) != parseInt(this.articulos[pos].cantidad)){
          // abro el modal de destinos para que complete los destinos
          this.verDestinos(2, this.articulos[pos])
          return
        }
      }else{
        if (conDestinos.length == 0){
          // abro el modal de destinos para que complete los destinos
          this.verDestinos(2, this.articulos[pos])
          return
        }
      }
      // paso a calcular el total de la orden
      this.calcularTotales()
    },
    calcularTotales(){
      // reinicio los totales
      this.totalOC = 0
      this.cantTotalOC = 0
      // recorro los artículos y voy acumulando
      for (let id in this.articulos){
        this.totalOC = this.totalOC + roundNumber(this.articulos[id].importe, 2) + roundNumber(this.articulos[id].imp_interno, 2)
        // 29-05-2023 por MMURILLO, le agrego el impuesto interno
        this.cantTotalOC = this.cantTotalOC + parseInt(this.articulos[id].cantidad)
      }
      this.totalOC = roundNumber(this.totalOC, 2)
    },
    quitarDetalle(item){
      let pos = this.articulos.indexOf(item)
      this.articulos.splice(pos, 1)
      this.calcularTotales()
    },
    verDestinos(opcion, item){
      // guardo la posicion del item en el listado
      this.posActual = this.articulos.indexOf(item)
      // indico si es un detalle nuevo
      if (item.id == 0) this.dialogDestinos.nuevo = 1
      else this.dialogDestinos.nuevo = 0
      // estan viendo o modificando?
      if (opcion == 1){
        // estan por ver, pero estan confeccionando una nueva factura?
        if (this.nuevaOrden == true) this.dialogDestinos.ver = 0
        else{
          // estan haciendo una nueva factura, estan editando?
          if (this.editar == true) this.dialogDestinos.ver = 0
          else this.dialogDestinos.ver = 1
        }
      }else this.dialogDestinos.ver = 0
      // cantidad
      this.dialogDestinos.cantidad = item.cantidad
      // destinos actuales
      this.dialogDestinos.destinos_actuales = item.destinos
      // abro el modal
      this.dialogDestinos.activo = true
    },
    upDestinos(dest){
      // reasigno los destinos
      this.articulos[this.posActual].destinos = dest
      // marco el detalle como consistente a los destinos
      this.articulos[this.posActual].destino_ok = 1
      // devuelvo la posicion a donde estaba originalmente
      this.posActual = -1
      // recalculo totales
      this.calcularTotales()
    },
    validaFechaPago(){
      if (this.fechaPago == '' || this.fechaPago == null || this.fechaPago == undefined){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar una fecha válida para poder continuar.',
          color: 'error',
        })
        this.fechaPago = moment(new Date()).format('DD/MM/YYYY')
        return
      }
    },
    validarPorcentajePago(){
      if (this.pagoFormaIngreso == false){
        // es vacio el porcentaje?
        if (this.porcentaje == null || this.porcentaje == undefined || this.porcentaje.toString().length == 0 || this.porcentaje == 0){
          this.$store.dispatch('show_snackbar', {
            text: 'Debe ingresar un porcentaje válido para poder continuar.',
            color: 'error',
          })
          this.importe = ''
          return
        }
        this.porcentaje = roundNumber(this.porcentaje, 2)
        if (this.porcentaje < 0 || this.porcentaje > 100){
          this.$store.dispatch('show_snackbar', {
            text: 'Porcentaje no válido. Debe ser un número entre 1 y 100.',
            color: 'error',
          })
          this.porcentaje = ''
          this.importe = ''
          return
        }
        let porcentajeFinalAcumulado = roundNumber(this.totalFinalPorcentaje, 2)
        let totalPorcentaje = roundNumber(roundNumber(this.porcentaje, 2) + roundNumber(porcentajeFinalAcumulado, 2), 2)
        // la suma del porcentaje que estoy agregando y el porcentaje total acumulado hasta el momento supera el 100%?
        if (totalPorcentaje > 100){
          this.$store.dispatch('show_snackbar', {
            text: 'Porcentaje no válido. Excede el total de la Orden de Compra.',
            color: 'error',
          })
          this.porcentaje = ''
          this.importe = ''
          return
        }
        let porcentaje = roundNumber(roundNumber(this.porcentaje, 2)/100, 2)
        this.importe = roundNumber(porcentaje * roundNumber(this.totalOC, 2), 2)
        // estoy agregando un pago con el que llegamos al 100%?
        if (parseInt(totalPorcentaje) == 100){
          // controlo la suma de los importes
          let sumaTotall = 0
          for (let id in this.formasPagoOC){
            sumaTotall = roundNumber(roundNumber(sumaTotall, 2) + roundNumber(this.formasPagoOC[id].importe, 2), 2)
          }
          let sumaTotal = sumaTotall + roundNumber(this.importe, 2)
          if (sumaTotal > roundNumber(this.totalOC, 2)){
            this.importe = roundNumber(roundNumber(this.totalOC, 2) - roundNumber(sumaTotall, 2), 2)
          }
        }
      }
    },
    validarImportePago(){
      if (this.pagoFormaIngreso == true){
        if (this.importe == null || this.importe == undefined || this.importe.toString().length == 0 || this.importe == 0){
          this.$store.dispatch('show_snackbar', {
            text: 'Importe no válido. Debe ingresar un importe mayor a 0 y que no sobrepase el valor Total de la Orden de Compra.',
            color: 'error',
          })
          this.porcentaje = ''
          this.importe = ''
          return
        }
        let porcentaje = roundNumber((roundNumber(this.importe, 2)*100)/roundNumber(this.totalOC, 2), 2)
        this.porcentaje = porcentaje
      }
    },
    restablecerPagos(){
      this.fechaPago = moment(new Date()).format('DD/MM/YYYY')
      this.porcentaje = ''
      this.importe = ''
      this.selectedFP = {}
    },
    addPago(){
      // control de valores vacios
      if(this.fechaPago.toString().length == 0 || this.porcentaje.length == 0 || this.importe.length == 0 || Object.keys(this.selectedFP).length == 0 || Object.keys(this.selectedTipoPago).length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe completar todos los campos de pago para poder agregar.',
          color: 'warning',
        })
        return
      }
      // tengo artículos cargados?
      if (this.articulos.length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'No se cargaron artículos en la Orden de Compra. No es posible establecer fechas de pago.',
          color: 'warning',
        })
        return
      }
      // control de importe
      if (this.importe.toString().length == 0 || roundNumber(this.importe, 2) < 0 || roundNumber(this.importe, 2) > roundNumber(this.totalOC, 2)){
        this.$store.dispatch('show_snackbar', {
          text: 'Importe no válido. Debe ingresar un importe mayor a 0 y que no sobrepase el valor Total de la Factura.',
          color: 'error',
        })
        this.porcentaje = ''
        this.importe = ''
        return
      }
      // control de la sumatoria de importes del detalle de pagos y del nuevo importe
      let sumaTotal = 0
      for (let id in this.formasPagoOC){
        sumaTotal = roundNumber(sumaTotal, 2) + roundNumber(this.formasPagoOC[id].importe, 2)
      }
      sumaTotal = roundNumber(sumaTotal, 2) + roundNumber(this.importe, 2)
      if (roundNumber(sumaTotal, 2) > roundNumber(this.totalOC, 2)){
        this.$store.dispatch('show_snackbar', {
          text: 'No se puede agregar ya que la suma de los importes de Pago sobrepasan al Total de la Orden de Compra.',
          color: 'error',
        })
        this.porcentaje = ''
        this.importe = ''
        return
      }
      let unPago = {
        fecha_pago: this.fechaPago,
        porcentaje_pago: roundNumber(this.porcentaje, 2),
        forma_pago: this.selectedFP.forma_pago_nombre,
        forma_pago_id: this.selectedFP.forma_pago_id,
        importe: roundNumber(this.importe, 2),
        tipo_pago_id: this.selectedTipoPago.tipo_pago_id,
        tipo_pago_nombre: this.selectedTipoPago.tipo_pago_nombre,
        id: 0
      }
      this.formasPagoOC.push(unPago)
      this.restablecerPagos()
    },
    volverIndex(){
      router.push({path: '/ordenes-compra'})
    },
    validarObligatorios(){
      // empresa
      if (Object.keys(this.selectedEmpresa).length == 0) return {valido: false, msj: 'Se debe ingresar la Empresa para poder continuar.'}
      // proveedor?
      if (Object.keys(this.selectedProveedor).length == 0) return {valido: false, msj: 'Se debe ingresar el Proveedor para poder continuar.'}
      // articulos?
      if (this.articulos.length == 0) return {valido: false, msj: 'Se deben ingresar los artículos para poder continuar.'}
      // tengo artículos sin asociar?
      let artsSinAsoc = this.articulos.filter(element => element.articulo_codigo == null || element.articulo_codigo == undefined || element.articulo_codigo.toString().length == 0 || element.articulo_codigo == 0)
      if (artsSinAsoc.length > 0) return {valido: false, msj: 'Existen artículos que no se encuentran asociados a un código de artículo del sistema.'}
      // tengo detalles con los destinos inconsistentes?
      let detsSinDestino = this.articulos.filter(element => element.destino_ok == 0)
      if (detsSinDestino.length > 0) return {valido: false, msj: 'Existen detalles donde no se terminaron de completar los destinos.'}
      // se llegó al 100% de los pagos?
      if (parseInt(this.totalFinalPorcentaje) != 100) return {valido: false, msj: 'Se debe completar el 100% de los Pagos para poder continuar.'}
      // el total de pagos coincide con el total de la orden de compra?
      if (roundNumber(this.totalOC, 2) != roundNumber(this.totalFinalPagos, 2)) return {valido: false, msj: 'El Importe a Pagar no coincide con el Total de la Orden de Compra.'}
      // 10-05-2023 por MMURILLO, ingresaron moneda?
      if (Object.keys(this.selectedMoneda).length == 0) return {valido: false, msj: 'Se debe ingresar la Moneda para poder continuar.'}
      // cotizacion?
      if ((this.cotizacion == null || this.cotizacion == undefined || this.cotizacion == 0 || this.cotizacion.toString().length == 0) && this.selectedMoneda.cotizacion == 1) return {valido: false, msj: 'Se debe ingresar la Cotización para poder continuar.'}
      // 12-05-2023 por MMURILLO, controlo que el array de pagos tenga tipo de pago definido
      let sinTipoPago = this.formasPagoOC.filter(element => element.tipo_pago_id == null || element.tipo_pago_id == undefined || element.tipo_pago_id == 0)
      if (sinTipoPago.length > 0) return {valido: false, msj: 'Todos los Pagos deben tener definido el Tipo de Pago. Revisar.'}
      return {valido: true, msj: 'OK'}
    },
    quitarPago(item){
      let pos = this.formasPagoOC.indexOf(item)
      this.formasPagoOC.splice(pos, 1)
    },
    async importarDetalles(){
      this.$store.state.loading = true
      let detallesPeticion = await this.$store.dispatch('ordenesCompra/getDetallesPedidos', {pedidos: this.selectedPedidos})
      this.$store.state.loading = false
      if (detallesPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: detallesPeticion.msj,
          color: 'error',
        })
        this.selectedPedidos = []
        return
      }
      // obtuve los detalles de los pedidos seleccionados, paso a armar
      this.armarDetallesPedidos(detallesPeticion.detalles)
    },
    armarDetallesPedidos(detalles){
      // los detalles vienen totalizados por articulo y sucursal, debo primero totalizar la cantidad agrupando por codigo de articulo y codigo de barras. A partir de ahi armar los destinos
      for (let id in detalles){
        // obtengo los articulos del detalle que tengan el mismo codigo de articulo y mismo codigo de barras
        let lisArtsIguales = detalles.filter(element => element.articulo_codigo == detalles[id].articulo_codigo && element.articulo_cod_barras == detalles[id].articulo_cod_barras && element.articulo_nombre == detalles[id].articulo_nombre)
        // como estoy comparando detalles consigo mismo SIEMPRE DEBERIA OBTENER AL MENOS 1 REGISTRO. lisArtsIguales va a tener totalizado por sucursal los articulos, o sea, no tiene detalles con sucursales repetidas
        // controlo si ya existe en el listado de articulos
        let existe = this.articulos.filter(element => element.articulo_codigo == lisArtsIguales[0].articulo_codigo && element.articulo_cod_barras == lisArtsIguales[0].articulo_cod_barras && element.articulo_nombre == lisArtsIguales[0].articulo_nombre)
        if (existe.length == 0){
          // armo mi estructura de destinos
          let dest = []
          for (let jd in this.dialogDestinos.sucursales){
            let unDestino = {
              sucursal_codigo: this.dialogDestinos.sucursales[jd].id,
              sucursal_nombre: this.dialogDestinos.sucursales[jd].nombre,
              cantidad: 0
            }
            dest.push(unDestino)
          }
          let cantidad = 0
          for (let jd in lisArtsIguales){
            // acumulo la cantidad total del detalle
            cantidad = cantidad + parseInt(lisArtsIguales[jd].cantidad)
            // ahora bien, debo asignar en los destinos la cantidad que estan remitiendo
            let unaSuc = dest.filter(element => element.sucursal_codigo == lisArtsIguales[jd].sucursal_codigo)
            let pos = dest.indexOf(unaSuc[0])
            dest[pos].cantidad = parseInt(dest[pos].cantidad) + parseInt(lisArtsIguales[jd].cantidad)
          }
          let unArt = {
            id: 0,
            articulo_codigo: detalles[id].articulo_codigo,
            articulo_cod_barras: detalles[id].articulo_cod_barras,
            articulo_nombre: detalles[id].articulo_nombre,
            cantidad: parseInt(cantidad),
            precio: roundNumber(detalles[id].precio, 2),
            importe: roundNumber(roundNumber(detalles[id].precio, 2) * parseInt(cantidad), 2),
            gravamen: convertDecimalsPlus(detalles[id].formula),
            destinos: dest,
            proveedor_articulo_id: detalles[id].pv_ar_id,
            destino_ok: 1,
            porc_imp_interno: 0,
            imp_interno: 0
          }
          // paso a agregarlo
          this.articulos.push(unArt)
        }
      }
      // calculo totales al completar el ciclo
      this.calcularTotales()
    },
    async sendOC(){
      let valido = this.validarObligatorios()
      if (valido.valido == false){
        this.$store.dispatch('show_snackbar', {
          text: valido.msj,
          color: 'warning',
        })
        return
      }
      // 10-05-2023 por MMURILLO, controlo la cotización
      let cot = 0
      if (this.cotizacion != null && this.cotizacion != undefined && this.cotizacion != 0 && this.cotizacion.toString().length > 0) cot = this.cotizacion
      // si todo es valido, armo mi body
      this.$store.state.loading = true
      let encabezado = {
        orden_fecha: this.fecha,
        orden_usuario: this.usuario,
        orden_proveedor: this.selectedProveedor.proveedor_codigo,
        orden_empresa: this.selectedEmpresa.id,
        orden_total: this.totalOC,
        moneda_id: this.selectedMoneda.moneda_id,
        cotizacion: cot
      }
      let body = {
        encabezado: encabezado,
        detalles: this.articulos,
        pedidos: this.selectedPedidos,
        periodos_pagos: this.formasPagoOC
      }
      let savePeticion = await this.$store.dispatch('ordenesCompra/saveOrdenCompra', body)
      this.$store.state.loading = false
      if (savePeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'Orden de Compra creada con éxito.',
          color: 'success',
        })
        this.volverIndex()
      }else{
        this.$store.dispatch('show_snackbar', {
          text: savePeticion.msj,
          color: 'error',
        })
      }
    },
    async updateOC(){
      let valido = this.validarObligatorios()
      if (valido.valido == false){
        this.$store.dispatch('show_snackbar', {
          text: valido.msj,
          color: 'warning',
        })
        return
      }
      // 10-05-2023 por MMURILLO, controlo la cotización
      let cot = 0
      if (this.cotizacion != null && this.cotizacion != undefined && this.cotizacion != 0 && this.cotizacion.toString().length > 0) cot = this.cotizacion
      // si todo es valido, armo mi body
      this.$store.state.loading = true
      let encabezado = {
        orden_id: this.ordenId,
        orden_fecha: this.fecha,
        orden_usuario: this.usuario,
        orden_proveedor: this.selectedProveedor.proveedor_codigo,
        orden_empresa: this.selectedEmpresa.id,
        orden_total: this.totalOC,
        moneda_id: this.selectedMoneda.moneda_id,
        cotizacion: cot
      }
      let body = {
        encabezado: encabezado,
        detalles: this.articulos,
        pedidos: this.selectedPedidos,
        periodos_pagos: this.formasPagoOC
      }
      let updatePeticion = await this.$store.dispatch('ordenesCompra/updateOrdenCompra', body)
      this.$store.state.loading = false
      if (updatePeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'La Orden de Compra fue actualizada con éxito.',
          color: 'success',
        })
        setTimeout(() => [location.reload()], 3000)
      }else{
        this.$store.dispatch('show_snackbar', {
          text: updatePeticion.msj,
          color: 'error',
        })
      }
    },
    async cancelOC(){
      this.$store.state.loading = true
      let anularPeticion = await this.$store.dispatch('ordenesCompra/anularOrdenCompra', {orden_id: this.ordenId})
      this.$store.state.loading = false
      if (anularPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'La Orden de Compra fue anulada correctamente.',
          color: 'success',
        })
        this.volverIndex()
      }else{
        this.$store.dispatch('show_snackbar', {
          text: anularPeticion.msj,
          color: 'error',
        })
      }
    },
    controlCantidadesDestinos(){
      for (let id in this.articulos){
        let cantDet = parseInt(this.articulos[id].cantidad)
        let dest = this.articulos[id].destinos.filter(element => parseInt(element.cantidad) > 0)
        let cantDest = 0
        if (dest.length > 0){
          for (let jd in dest){
            cantDest = parseInt(cantDest) + parseInt(dest[jd].cantidad)
          }
        }
        if (cantDet != cantDest) this.articulos[id].destino_ok = 0
      }
    },
    async asociarArticulo(item){
      if (this.dialogAsociarArts.articulos.length == 0){
        this.$store.state.loading = true
        let artsPeticion = await this.$store.dispatch('ordenesCompra/getAllArticles')
        this.$store.state.loading = false
        if (artsPeticion.resultado == 0){
          this.$store.dispatch('show_snackbar', {
            text: artsPeticion.msj,
            color: 'error',
          })
          return
        }
        this.dialogAsociarArts.articulos = artsPeticion.articulos
      }
      // abro el modal
      this.dialogAsociarArts.proveedor_codigo = this.selectedProveedor.proveedor_codigo
      this.dialogAsociarArts.proveedor_nombre = this.selectedProveedor.proveedor_nombre
      this.dialogAsociarArts.item = item
      this.dialogAsociarArts.activo = true
    },
    setModalAsocArt(value){
      this.dialogAsociarArts.activo = value
      if (this.dialogAsociarArts.activo == false){
        this.dialogAsociarArts.item = {}
        this.dialogAsociarArts.proveedor_codigo = 0
        this.dialogAsociarArts.proveedor_nombre = ''
      }
    },
    setAsociacion(datos){
      let item = datos.item
      let art = datos.articulo_codigo
      let pos = this.articulos.indexOf(item)
      if (pos == -1){
        this.$store.dispatch('show_snackbar', {
          text: 'No se pudo determinar la posición original del registro.',
          color: 'error',
        })
        return
      }
      this.articulos[pos].articulo_codigo = art
    },
    confirmarMarcarPagada(){
      this.modalMarcarPagada = true
    },
    confirmarMarcarNoPagada(){
      this.modalMarcarNoPagada = true
    },
    setMarcarPagada(value){
      this.modalMarcarPagada = value
    },
    setMarcarNoPagada(value){
      this.modalMarcarNoPagada = value
    },
    async marcarComoPagada(){
      this.$store.state.loading = true
      let marcarPeticion = await this.$store.dispatch('ordenesCompra/marcarPagada', {orden_id: this.ordenId})
      this.$store.state.loading = false
      if (marcarPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'Se marcó como pagada de forma exitosa.',
          color: 'success',
        })
        this.pagada = 1
      }else{
        this.$store.dispatch('show_snackbar', {
          text: marcarPeticion.msj,
          color: 'error',
        })
      }
    },
    async marcarComoNoPagada(){
      this.$store.state.loading = true
      let marcarPeticion = await this.$store.dispatch('ordenesCompra/marcarNoPagada', {orden_id: this.ordenId})
      this.$store.state.loading = false
      if (marcarPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'Se marcó como no pagada de forma exitosa.',
          color: 'success',
        })
        this.pagada = 0
      }else{
        this.$store.dispatch('show_snackbar', {
          text: marcarPeticion.msj,
          color: 'error',
        })
      }
    },
    setModalVerFactComp(value){
      this.dialogVerFactCompra.activo = value
      if (this.dialogVerFactCompra.activo == false){
        this.dialogVerFactCompra.orden_id = 0
      }
    },
    setModalVerRecep(value){
      this.dialogVerRecepciones.activo = value
      if (this.dialogVerRecepciones.activo == false){
        this.dialogVerRecepciones.orden_id = 0
      }
    },
    verificarCotizacion(){
      if (Object.keys(this.selectedMoneda).length > 0){
        if (this.selectedMoneda.cotizacion == 0) this.cotizacion = ''
      }else this.cotizacion = ''
    },
    validar_archivo (event) {
      const files = event.target.files
      if (files.length > 1) {
        this.$refs.input.value = ''
        return this.$store.dispatch('show_snackbar', {
          text: 'Solo puede seleccionar 1 archivo',
          color: 'error'
        })
      }
      if (files.length == 0) {
        this.$refs.input.value = ''
        return
      }

      const file = files[0]
      const tipos_permitidos = ['.csv', '.xls', '.xlsx', '.ods']

      // busco en el array de tipos_archivos el tipo de archivo del archivo que subio
      const tipo_archivo = tipos_archivos.find(a => a.mime === file.type)

      if (tipo_archivo) {
      // si encuentro el tipo de archivo paso a validar que pertenezca a por lo menos un tipo de extension existente en el prop de tipos
        if (tipos_permitidos.find(a => a === tipo_archivo.extension)) {
          // la extension del tipo del archivo existe en los tipos validos
          this.importar_archivo(file)
        }
        else {
          // subio un archivo con una extension no valida
          this.$refs.input.value = ''
          return this.$store.dispatch('show_snackbar', {
            text: 'Tipo de archivo no válido',
            color: 'error'
          })
        }
      }
      else {
        // si no lo encuentra es porque subio un tipo de archivo que no esta registrado en el array tipos_archivos
        this.$refs.input.value = ''
        return this.$store.dispatch('show_snackbar', {
          text: 'Tipo de archivo no válido para el sistema',
          color: 'error'
        })
      }
    },
    importar_archivo (archivo) {
      try {
        // defino la pocision y el codigo de las sucursales en el archivo
        const sucursales = [
          { codigo: 1, posicion: 3 }, // salta
          { codigo: 2, posicion: 4 }, // tucuman
          { codigo: 212, posicion: 5 }, // chaco
          { codigo: 3, posicion: 6 }, // santiago
          { codigo: 547, posicion: 7 }, // misiones
          { codigo: 403, posicion: 8 }, // formosa
          { codigo: 397, posicion: 9 }, // jujuy
          { codigo: 404, posicion: 10 }, // corrientes
          { codigo: 5, posicion: 11 } // la rioja
        ]
        const reader = new FileReader()
        reader.onload = async (e) => {
          const bytes = e.target.result
          const excel = read(bytes, { type: 'binary', cellDates: true })
          // obtengo el nombre de la primer hoja
          const hoja_nombre = excel.SheetNames[0]
          // obtengo la primer hoja
          const hoja = excel.Sheets[hoja_nombre]
          // obtengo la data de la primer hoja
          const data = utils.sheet_to_json(hoja, { header: 1 })
          // ciclo por cada row de la primer hoja (excluyendo los headers)
          let detalles = []
          for (let i = 1; i < data.length; i++) {
            const row = data[i]
            // si la row tiene algun dato la descompongo en sucursales
            if (row.length > 0) {
              let unaFila = {
                articulo_codigo: row[0] ? row[0] : '',
                articulo_cod_barras: row[1] ? row[1] : '',
                articulo_nombre: row[2] ? row[2] : '',
                precio: row[13] ? row[13] : '',
                formula: '',
                pv_ar_id: ''
              }
              // busco la info del articulo en el array
              if (unaFila.articulo_codigo.toString() == '' && unaFila.articulo_cod_barras.toString() != '') {
                let unArt = this.dialogSelectArticulo.articulos.filter(element => element.articulo_cod_barras != null && element.articulo_cod_barras.toUpperCase() == unaFila.articulo_cod_barras.toString().toUpperCase())
                if (unArt.length != 0) {
                  unaFila.articulo_codigo = unArt[0].articulo_codigo
                }
              }
              // ciclo por cada columna de sucursal
              for (let i = 0; i < 9; i++) {
                let filaSucursal = await JSON.parse(JSON.stringify(unaFila))
                filaSucursal.cantidad = row[sucursales[i].posicion]
                filaSucursal.sucursal_codigo = sucursales[i].codigo
                detalles.push(filaSucursal)
              }
            }
          }
          // importo el archivo
          this.armarDetallesPedidos(detalles)
          // limpio el input
          this.$refs.input.value = ''
        }
        reader.readAsBinaryString(archivo)
      } catch (error) {
        this.$refs.input.value = ''
        this.$store.dispatch('show_snackbar', {
          text: 'Ocurrio un error inesperado al importar el archivo: ' + error,
          color: 'error',
          timeout: 4000
        })
      }
    }
  },
  watch: {
    metodoIngresoArts: function(){
      if (this.metodoIngresoArts == false){
        this.msjMetodoIngresoArts = 'Por Código de Barras'
      }else{
        this.msjMetodoIngresoArts = 'Por Código de Artículo'
      }
      this.limpiarSeccionArticulos()
      if (Object.keys(this.selectedEmpresa).length > 0 && Object.keys(this.selectedProveedor).length > 0) this.getArtsAndPedidos()
    },
    articulos: function(){
      if (this.articulos.length > 0){
        for (let id in this.articulos){
          let unArt = this.articulos[id]
          // defino el indice dentro del listado
          Object.defineProperty(unArt, 'index', {
            value: parseInt(id),
            writable: true,
            enumerable: true,
            configurable: true
          })
          // lo asigno
          Object.assign(this.articulos[id], unArt)
        }
        this.controlCantidadesDestinos()
      }
    },
    pagoFormaIngreso: function(){
      if (this.pagoFormaIngreso == false){
        this.pagoNombreForIngreso = 'Porcentaje'
      }else{
        this.pagoNombreForIngreso = 'Importe'
      }
      this.restablecerPagos()
    },
    formasPagoOC: function(){
      if (this.formasPagoOC.length > 0){
        this.totalFinalPorcentaje = 0
        this.totalFinalPagos = 0
        for (let id in this.formasPagoOC){
          this.totalFinalPorcentaje = this.totalFinalPorcentaje + roundNumber(this.formasPagoOC[id].porcentaje_pago, 2)
          this.totalFinalPagos = this.totalFinalPagos + roundNumber(this.formasPagoOC[id].importe, 2)
          // asigno el index
          let unPago = this.formasPagoOC[id]
          Object.defineProperty(unPago, 'index', {
            value: parseInt(id),
            writable: true,
            enumerable: true,
            configurable: true
          })
          // lo asigno
          Object.assign(this.formasPagoOC[id], unPago)
        }
      }else{
        this.totalFinalPorcentaje = 0
        this.totalFinalPagos = 0
      }
    },
    pedidos: function(){
      if (this.pedidos.length > 0){
        for (let id in this.pedidos){
          this.pedidos[id].fecha_pedido = moment(this.pedidos[id].fecha_pedido).format("DD/MM/YYYY")
        }
      }
    },
    editar: function(){
      if (this.editar == true){
        this.getArtsAndPedidos()
      }
    },
    selectedPedidos: function(){
      if (this.selectedPedidos.length > 0){
        this.cotizaPedido = true
        // hay algun pedido sin moneda?
        let sinMoneda = this.selectedPedidos.filter(element => element.moneda_id == 0)
        if (sinMoneda.length > 0){
          this.$store.dispatch('show_snackbar', {
            text: 'Algunos pedidos no se asociaron a un tipo de Moneda. Verificar.',
            color: 'warning',
          })
          this.selectedPedidos = []
          this.cotizaPedido = false
          return
        }
        // me quedo con el primer elemento
        let monedaUsada = this.selectedPedidos[0].moneda_id
        // seleccionaron pedidos que no usan la misma moneda?
        let distintos = this.selectedPedidos.filter(element => element.moneda_id != monedaUsada)
        if (distintos.length > 0){
          this.$store.dispatch('show_snackbar', {
            text: 'No se pueden seleccionar Pedidos que contengan distintas monedas.',
            color: 'warning',
          })
          this.cotizaPedido = false
          this.selectedPedidos = []
          return
        }
        // me quedo con la mayor cotizacion
        let mayor = this.selectedPedidos[0]
        for (let id in this.selectedPedidos){
          if (roundNumber(this.selectedPedidos[id].cotizacion, 2) > roundNumber(mayor.cotizacion, 2)) mayor = this.selectedPedidos[id]
        }
        if (this.nuevaOrden == true) this.cotizacion = mayor.cotizacion
        // seteo la moneda
        this.selectedMoneda = this.monedas.filter(element => element.moneda_id == mayor.moneda_id)[0]
      }else{
        if (this.nuevaOrden == true){
          this.cotizaPedido = false
          this.cotizacion = ''
          this.selectedMoneda = {}
        }
      }
    }
  },
}
</script>

<style>

</style>