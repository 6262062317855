<template>
  <v-dialog
    v-model="dialog"
    width="600"
    :persistent="load"
    scrollable
  >
    <v-card
      :disabled="load"
      :loading="load"
    >
      <v-card-title>
        {{ nueva ? 'Nueva penalización' : 'Editar penalización' }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-8">
        <v-form ref="form">
          <v-row class="d-flex justify-center">
            <v-col cols="6" md="3" class="py-0">
              <PeriodoPicker
                v-model="penalizacion.periodo"
                :disabled="true"
                :label="true"
              />
            </v-col>
            <v-col cols="6" md="3" class="py-0">
              <v-autocomplete
                v-model="penalizacion.empresa"
                label="Empresa"
                item-text="nombre_corto"
                item-value="id"
                :items="empresas"
                autofocus
                outlined
                dense
                @change="get_vendedores(); penalizacion.vendedor_codigo = null"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md="4" class="py-0">
              <v-autocomplete
                v-model="penalizacion.sucursal"
                label="Sucursal"
                item-text="nombre"
                item-value="id"
                :items="sucursales"
                outlined
                dense
                @change="get_vendedores(); penalizacion.vendedor_codigo = null"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="7" class="py-0">
              <v-autocomplete
                v-model="penalizacion.vendedor_codigo"
                label="Vendedor"
                item-text="nombre"
                item-value="codigo"
                :items="vendedores"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md="3" class="py-0">
              <v-text-field
                v-model="penalizacion.importe"
                label="Importe"
                type="number"
                prefix="$"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="10" class="py-0">
              <v-textarea
                v-model="penalizacion.descripcion"
                label="Descripción"
                rows="2"
                :rules="[rules.required]"
                validate-on-blur
                auto-grow
                outlined
                dense
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          :loading="load"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 *  Modal para editar o crear una penalizacion
 * 
 *  Notas: este componente emite acciones (@nueva, @editar) al padre caundo se crea o edita una penalizacion (en caso de estar definidas)
 *         todos los props son obligatorios
 */
import { mapState, mapGetters } from 'vuex'
import { order_list_by, get_nombre_obj_arr } from '../../util/utils'
import moment from 'moment'
import BtnConfirmar from '../util/BtnConfirmar'
import PeriodoPicker from '../util/PeriodoPicker'

export default {
  data () {
    return {
      load: false,
      nueva: false,
      vendedores: [],
      penalizacion: {},
      rules: {
        required: value => !!value || 'Campo requerido',
      }
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('penalizaciones/get_estados')
    this.$store.state.loading = false
  },
  props: {
    value: Boolean,
    p_penalizacion: Object // en caso de ser nueva enviar null
  },
  computed: {
    ...mapGetters(['una_empresa', 'una_sucursal']),
    ...mapState(['empresas', 'sucursales']),
    ...mapState('penalizaciones', ['estados_penal']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar,
    PeriodoPicker
  },
  watch: {
    async dialog (val) {
      if (val) {
        if (this.p_penalizacion) {
          this.nueva = false
          // crea una copia de la penalizacion para editarla
          this.penalizacion = JSON.parse(JSON.stringify(this.p_penalizacion))
        } else {
          this.nueva = true
          this.penalizacion = {
            periodo: moment(new Date()).format('MM/YYYY'),
            empresa: null,
            sucursal: null,
            vendedor_codigo: null,
            importe: null,
            descripcion: null,
            estado: 0
          }
          // si tiene solo 1 empresa la selecciona
          if (this.una_empresa) {
            this.penalizacion.empresa = this.empresas[0].id
          }
          // si tiene solo 1 sucursal la selecciona
          if (this.una_sucursal) {
            this.penalizacion.sucursal = this.sucursales[0].id
          }
        }
        // obtiene los vendedores
        await this.get_vendedores()
      }
      else {
        this.$refs.form.resetValidation()
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        if (this.penalizacion.importe <= 0) {
          this.$store.dispatch('show_snackbar', {
            text: 'El importe no puede ser menor o igual a cero',
            color: 'warning'
          })
          return
        }
        this.load = true
        if (this.nueva) {
          await this.$store.dispatch('penalizaciones/nueva', this.penalizacion)
            .then((res) => {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success'
              })
              this.penalizacion.numero = res.id
              this.penalizacion.vendedor_nombre = get_nombre_obj_arr(this.vendedores, 'codigo', this.penalizacion.vendedor_codigo)
              this.penalizacion.estado_nombre = get_nombre_obj_arr(this.estados_penal, 'codigo', this.penalizacion.estado)
              this.$emit('nueva', this.penalizacion)
              this.dialog = false
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
        } else {
          // solo edita si realmente modifico la penalizacion
          if (JSON.stringify(this.penalizacion) != JSON.stringify(this.p_penalizacion)) {
            await this.$store.dispatch('penalizaciones/editar', this.penalizacion)
              .then((res) => {
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'success'
                })
                this.penalizacion.vendedor_nombre = get_nombre_obj_arr(this.vendedores, 'codigo', this.penalizacion.vendedor_codigo)
                this.$emit('editar', this.penalizacion)
                this.dialog = false
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })
          }
          else {
            this.dialog = false
            this.$store.dispatch('show_snackbar', {
              text: 'No se realizaron cambios en la penalización',
              color: 'warning'
            })
          }
        }
        this.load = false
      }
    },
    async get_vendedores () {
      this.vendedores = []
      const empresa = this.penalizacion.empresa
      const sucursal = this.penalizacion.sucursal
      if (empresa && sucursal) {
        this.$store.state.loading = true
        await this.$store.dispatch('vendedores/get_vendedores', {
          empresa: empresa,
          sucursal: sucursal,
          inhabilitado: 0
        })
          .then((data) => {
            this.vendedores = data.vendedores
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        order_list_by(this.vendedores, 'nombre')
        this.$store.state.loading = false
      }
    }
  }
}
</script>