<template>
  <div>
    <v-row :no-gutters="$vuetify.breakpoint.xs">
      <v-col cols="12">
        <v-card
          elevation="2"
          outlined
        >
        <v-card-title class="mb-1">
          <v-icon v-if="p_venta" class="mb-1" left>fas fa-user-tie</v-icon>
          Cliente
          <v-btn v-if="!lock_cliente && nuevo" icon @click="buscarCliente()">
            <v-icon color="info" small title="Buscar Cliente">fas fa-search</v-icon>
          </v-btn>
          <v-btn v-if="tienePermiso == 1 || modifica == 1" icon @click="nuevoClienteMayorista()">
            <v-icon color="success" small title="Crear Cliente">fas fa-plus-circle</v-icon>
          </v-btn>
          <v-btn v-if="p_refresh" icon @click="getCliente()">
            <v-icon color="teal" title="Refrescar datos" small>fas fa-sync</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <!-- Código, Apellido y Nombre, Número de Documento y Tipo de Documento -->
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="6" sm="2" md="2" class="py-0">
              <v-text-field
                v-if="nuevo"
                v-model="vendedorCodigo"
                outlined
                dense
                type="number"
                @blur="getCliente()"
                :readonly="lock_cliente == true || modifica == 1"
                :filled="lock_cliente == true || modifica == 1"
                label="Código"
              ></v-text-field>
              <v-text-field
                v-if="!nuevo"
                v-model="vendedorCodigo"
                outlined
                dense
                type="number"
                readonly
                filled
                label="Código"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="py-0">
              <v-text-field
                v-model="vendedorNombre"
                outlined
                dense
                type="text"
                readonly
                filled
                label="Apellido y Nombre"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="2" md="2" class="py-0">
              <v-text-field
                v-model="vendedorDNI"
                class="mr-2 mr-sm-0"
                outlined
                dense
                type="number"
                readonly
                filled
                label="Numero Documento"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="2" md="2" class="py-0">
              <v-text-field
                v-model="vendedorTipoDNI"
                class="ml-2 ml-sm-0"
                outlined
                dense
                type="text"
                readonly
                filled
                label="Tipo Documento"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Dirección, Email y Teléfono -->
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12" sm="6" md="6" class="py-0">
              <v-text-field
                v-model="vendedorDireccion"
                outlined
                dense
                type="text"
                readonly
                filled
                label="Dirección"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3" class="py-0">
              <v-text-field
                v-model="vendedorEmail"
                outlined
                dense
                type="text"
                readonly
                filled
                label="Email"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3" class="py-0">
              <v-text-field
                v-model="vendedorTelefono"
                outlined
                dense
                type="text"
                readonly
                filled
                label="Teléfono"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-expand-transition>
          <v-alert
            v-if="p_venta && vendedorInhabilitado == 1"
            class="mx-4"
            type="error"
            border="left"
          >
            El cliente <strong>{{ vendedorNombre }}</strong> se encuentra Inhabilitado. Verificar
          </v-alert>
        </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
    <!-- Modal Buscar Clientes Mayoristas -->
    <BuscarClienteMayorista 
      :datos="dialogClientes"
      @setearCliente="setCliente"
      @setearBuscarClienteM="setBuscarClienteM"
    />
    <!-- Modal Crear Cliente Mayorista -->
    <CrearClienteMayorista 
      :datos="dialogNuevoClienteM"
      @setearNewClienteM="setNewClienteM"
      @nuevoCliente="nuevoCliente"
    />
  </div>
</template>

<script>
import BuscarClienteMayorista from './BuscarClienteMayorista.vue'
import CrearClienteMayorista from './CrearClienteMayorista.vue'
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
    p_venta: {
      type: Boolean,
      default: false
    },
    p_refresh: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      vendedorCodigo: null,
      vendedorNombre: null,
      vendedorDNI: null,
      vendedorTipoDNI: null,
      vendedorTipoDNICodigo: null,
      vendedorDireccion: null,
      vendedorEmail: null,
      vendedorTelefono: null,
      vendedorInhabilitado: null,
      dialogClientes: {
        activo: false,
        ejecutivo_codigo: '',
        ruta: '',
      },
      dialogNuevoClienteM: {
        activo: false,
        ejecutivo_codigo: ''
      },
      iniciaCliente: 0
    }
  },
  created(){
  },
  components: {
    BuscarClienteMayorista,
    CrearClienteMayorista
  },
  computed: {
    tienePermiso: {
      get(){
        return this.datos.tienePermiso
      }
    },
    ejecutivo_codigo: {
      get(){
        return this.datos.ejecutivo_codigo
      }
    },
    pto_vta: {
      get(){
        return this.datos.pto_vta
      }
    },
    lock_cliente: {
      get(){
        return this.datos.lockCliente
      }
    },
    modifica: {
      get(){
        return this.datos.modifica
      }
    },
    cliente: {
      get(){
        return this.datos.cliente
      }
    },
    limpiar_seccion: {
      get(){
        return this.datos.limpiar_seccion
      },
      set(value) {
        this.$emit('setearClearCliente', value)
      }
    },
    datosCliente:{
      get(){
        return this.datos.datosCli
      }
    },
    nuevo:{
      get(){
        return this.datos.nuevo
      }
    },
    ruta:{
      get(){
        return this.datos.miRuta;
      }
    }
  },
  methods: {
    nuevoCliente (cod_vend) {
      this.vendedorCodigo = cod_vend
      this.getCliente()
    },
    init(){
      if(!this.nuevo){
        this.vendedorCodigo = this.datosCliente.vendedor_codigo
        this.vendedorNombre = this.datosCliente.vendedor_nombre
        this.vendedorDNI  = this.datosCliente.numero_documento
        this.vendedorTipoDNI  = this.datosCliente.tipo_documento
        this.vendedorTipoDNICodigo = this.datosCliente.tipo_doc_codigo
        this.vendedorDireccion  = this.datosCliente.domicilio
        this.vendedorEmail  = this.datosCliente.mail
        this.vendedorTelefono  = this.datosCliente.telefono
        this.vendedorInhabilitado = this.datosCliente.inhabilitado
      }
    },
    buscarCliente(){
      if (parseInt(this.pto_vta) > 0){
        // tengo el ejecutivo?
        if (this.ejecutivo_codigo == 0){
          this.$store.dispatch('show_snackbar', {
            text: 'El Vendedor Mayorista no tiene asociado su código de Ejecutivo.',
            color: 'warning',
          })
          return
        }
        this.dialogClientes.ejecutivo_codigo = this.ejecutivo_codigo
        this.dialogClientes.ruta = this.ruta;
        this.dialogClientes.activo = true
      }else{
        this.$store.dispatch('show_snackbar', {
          text: 'Seleccione un punto de venta para empezar la búsqueda',
          color: 'info',
        })
      }
    },
    setCliente(item){
      // pongo los valores del vendedor
      this.vendedorCodigo = item.vendedor_codigo
      this.vendedorNombre = item.vendedor_nombre
      this.vendedorInhabilitado = item.inhabilitado
      this.vendedorDNI = this.ruta == 'mayoristaCobranzas/obtenerClientesMayoristas' ? item.numero_documento : item.documento_numero;
      this.vendedorTipoDNI = this.ruta == 'mayoristaCobranzas/obtenerClientesMayoristas' ? item.tipo_documento : item.tipo_doc_nombre;
      this.vendedorTipoDNICodigo = this.ruta == 'mayoristaCobranzas/obtenerClientesMayoristas' ? item.tipo_doc_codigo : item.tipo_doc_id;
      this.vendedorDireccion = this.ruta == 'mayoristaCobranzas/obtenerClientesMayoristas' ? item.domicilio : item.vendedor_domicilio;
      this.vendedorEmail =  this.ruta == 'mayoristaCobranzas/obtenerClientesMayoristas' ? item.mail : (item.vendedor_email == null ? '' : item.vendedor_email);
      this.vendedorTelefono = this.ruta == 'mayoristaCobranzas/obtenerClientesMayoristas' ? item.telefono : item.vendedor_telefono
      // emito el cliente seleccionado
      this.$emit('setSelectCliente', item)
    },
    async getCliente(){
      if ((parseInt(this.pto_vta) > 0 && this.lock_cliente == false) || (this.modifica == 1)){
        // tengo el ejecutivo?
        if (this.ejecutivo_codigo == 0 && this.modifica == 0){
          this.$store.dispatch('show_snackbar', {
            text: 'El Vendedor Mayorista no tiene asociado su código de Ejecutivo.',
            color: 'warning',
          })
          return
        }
        if (this.vendedorCodigo == null || this.vendedorCodigo == undefined || this.vendedorCodigo.toString().length == 0){
          this.$store.dispatch('show_snackbar', {
            text: 'Ingrese un código de Cliente válido para realizar la búsqueda.',
            color: 'info',
          })
          this.limpiarCliente()
          return
        }
        this.$store.state.loading = true
        let clientePeticion = await this.$store.dispatch(this.ruta, {ejecutivo_codigo: this.ejecutivo_codigo, codigo: this.vendedorCodigo, nombre: '', nroDocumento: '0'})
        this.$store.state.loading = false
        if (clientePeticion.resultado == 1){
          if (clientePeticion.clientes.length > 0){
            this.setCliente(clientePeticion.clientes[0])
          }else{
            this.limpiarCliente();
            this.$store.dispatch('show_snackbar', {
              text: 'No se encontró el Cliente ingresado o bien no está asociado al ejecutivo correcto.',
              color: 'info',
            })
          }
        }else{
          this.$store.dispatch('show_snackbar', {
            text: clientePeticion.msj,
            color: 'error',
          })
        }
      }else{
        if (this.lock_cliente == false){
          this.$store.dispatch('show_snackbar', {
            text: 'Seleccione un punto de venta para empezar la búsqueda',
            color: 'info',
          })
        }
      }
    },
    limpiarCliente(){
      this.vendedorCodigo = ''
      this.vendedorNombre = ''
      this.vendedorDNI = ''
      this.vendedorTipoDNI = ''
      this.vendedorTipoDNICodigo = ''
      this.vendedorDireccion = ''
      this.vendedorEmail = ''
      this.vendedorTelefono = ''
      this.vendedorInhabilitado = ''
      if (this.p_venta) this.$emit('setSelectCliente', {})
    },
    setBuscarClienteM(value){
      this.dialogClientes.activo = value
      if (this.dialogClientes.activo == false) this.dialogClientes.ejecutivo_codigo = ''
    },
    nuevoClienteMayorista(){
      // abro el modal
      if (parseInt(this.pto_vta) > 0){
        // tengo el ejecutivo?
        if (this.ejecutivo_codigo == 0){
          this.$store.dispatch('show_snackbar', {
            text: 'El Vendedor Mayorista no tiene asociado su código de Ejecutivo.',
            color: 'warning',
          })
          return
        }
        this.dialogNuevoClienteM.ejecutivo_codigo = this.ejecutivo_codigo
        this.dialogNuevoClienteM.activo = true
      }else{
        this.$store.dispatch('show_snackbar', {
          text: 'Seleccione un punto de venta para empezar la búsqueda',
          color: 'info',
        })
      }
    },
    setNewClienteM(value){
      this.dialogNuevoClienteM.activo = value
      if (this.dialogNuevoClienteM.activo == false) this.dialogNuevoClienteM.ejecutivo_codigo = ''
    }
  },
  watch: {
    cliente: function(){
      if (parseInt(this.cliente) > 0 && parseInt(this.pto_vta) > 0 && parseInt(this.ejecutivo_codigo) > 0 && (this.iniciaCliente == 0 || this.p_venta)){
        this.iniciaCliente = 1
        this.vendedorCodigo = this.cliente
        this.getCliente()
      }
    },
    pto_vta: function(){
      if (this.modifica == 1 && parseInt(this.cliente) > 0 && parseInt(this.pto_vta) > 0 && parseInt(this.ejecutivo_codigo) > 0 && this.iniciaCliente == 0){
        this.iniciaCliente = 1
        this.vendedorCodigo = this.cliente
        this.getCliente()
      }
    },
    ejecutivo_codigo: function(){
      if (this.modifica == 1 && parseInt(this.cliente) > 0 && parseInt(this.pto_vta) > 0 && parseInt(this.ejecutivo_codigo) > 0 && this.iniciaCliente == 0){
        this.iniciaCliente = 1
        this.vendedorCodigo = this.cliente
        this.getCliente()
      }
    },
    limpiar_seccion: function(){
      if (this.limpiar_seccion == true){
        this.limpiarCliente()
        this.limpiar_seccion = false
      }
    },
    'datos.datosCli':{
      handler: function () {
        if (this.datos.datosCli && Object.keys(this.datos.datosCli).length != 0) {
          this.init();
        }
      },
      deep: true
    }
  },
}
</script>