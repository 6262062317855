<template>
  <v-dialog
    v-model="activo"
    max-width="60%"
  >
    <v-card
      flat
      :loading="load"
    >
      <v-card-title>
        <div v-if="params.nuevo == 1"><v-icon small class="ma-2">fas fa-plus-circle</v-icon> Nuevo Depósito</div>
        <div v-if="params.ver == 1"><v-icon small class="ma-2">fas fa-eye</v-icon> Ver Depósito {{ params.deposito.deposito_acc_nombre }}</div>
        <v-spacer></v-spacer>
        <v-btn icon><v-icon @click="activo = false" title="Cerrar">fas fa-times</v-icon></v-btn>
      </v-card-title>
      <v-card-text class="pt-2">
        <!-- Empresa, Sucursal y Tipo de Deposito -->
        <v-row :no-gutters="$vuetify.breakpoint.xs">
          <v-col cols="12" sm="4" md="4" class="py-1">
            <v-autocomplete
              v-model="selectedEmpresa"
              item-text="nombre_corto"
              :items="params.empresas"
              hide-details
              outlined
              dense
              return-object
              label="Empresa"
              @change="getLocales(1,0)"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4" class="py-1">
            <v-autocomplete
              v-model="selectedSucursal"
              item-text="nombre"
              :items="params.sucursales"
              hide-details
              outlined
              dense
              return-object
              label="Sucursal"
              @change="getLocales(1,0)"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4" class="py-1">
            <v-autocomplete
              v-model="selectedTipoDep"
              item-text="tipo_dep_nombre"
              :items="params.tipos_depositos"
              hide-details
              outlined
              dense
              return-object
              label="Tipo"
              @change="getLocales(1,0)"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <!-- Nombre y Local asociado -->
        <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-2">
          <v-col cols="12" sm="6" md="6" class="py-1">
            <v-text-field
              v-model="nombreDeposito"
              outlined
              dense
              type="text"
              hide-details
              label="Nombre"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="py-1">
            <v-autocomplete
              v-model="selectedLocal"
              item-text="local_nombre"
              :items="locales"
              hide-details
              outlined
              dense
              return-object
              label="Local Asociado"
              clearable
              :disabled="conLocal == false"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <!-- Estado -->
        <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-2">
          <v-col cols="12" sm="3" md="3" class="py-1">
            <v-checkbox
              v-model="estado"
              :label="`${estadoNom}`"
              dense
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- Botones -->
      <v-card-actions class="d-flex justify-end mx-2">
        <v-btn color="warning" class="mr-2" @click="activo = false">Volver</v-btn>
        <BtnConfirmar
          texto="¿Está seguro de dar de Alta al nuevo Depósito?"
          @action="nuevoDeposito()"
          v-if="params.nuevo == 1"
        />
        <BtnConfirmar
          texto="¿Está seguro de guardar los cambios realizados al Depósito?"
          @action="modificarDeposito()"
          v-if="params.ver == 1"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BtnConfirmar from '../util/BtnConfirmar.vue'
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  computed: {
    activo: {
      get() {
        return this.datos.activo
      },
      set(value) {
        this.$emit('setearModalVerDep', value)
      }
    },
    params: {
      get() {
        return this.datos
      },
    }
  },
  components: {
    BtnConfirmar,
  },
  data() {
    return {
      selectedEmpresa: {},
      selectedSucursal: {},
      selectedTipoDep: {},
      nombreDeposito: '',
      selectedLocal: {},
      locales: [],
      conLocal: false,
      load: false,
      estado: false,
      estadoNom: 'Inhabilitado'
    }
  },
  methods: {
    initForm(){
      if (this.params.ver == 1){
        // completo los datos que me estan pasando, empresa
        let unaEmpresa = this.params.empresas.filter(element => element.id == this.params.deposito.empresa_codigo)
        if (unaEmpresa.length > 0) this.selectedEmpresa = unaEmpresa[0]
        // sucursal
        let unaSuc = this.params.sucursales.filter(element => element.id == this.params.deposito.sucursal_codigo)
        if (unaSuc.length > 0) this.selectedSucursal = unaSuc[0]
        // tipo de deposito
        let unTipo = this.params.tipos_depositos.filter(element => element.tipo_dep_codigo == this.params.deposito.tipo_dep_codigo)
        if (unTipo.length > 0) this.selectedTipoDep = unTipo[0]
        // nombre
        this.nombreDeposito = this.params.deposito.deposito_acc_nombre
        // local
        this.getLocales(2, this.params.deposito.local_codigo)
        // estado
        this.estado = this.params.deposito.en_desuso == 0 ? true : false
      }else{
        this.conLocal = true
      }
    },
    async getLocales(opcion, local){
      if (Object.keys(this.selectedEmpresa).length > 0 && Object.keys(this.selectedSucursal).length > 0 && Object.keys(this.selectedTipoDep).length > 0){
        if (this.selectedTipoDep.local != 1){
          // no lleva local, no tiene sentido buscar locales
          this.selectedLocal = {}
          this.locales = []
          this.conLocal = false
          return
        }
        // es con local, lo marco
        this.conLocal = true
        // paso a buscar locales
        this.selectedLocal = {}
        this.locales = []
        this.load = true
        let localesPeticion = await this.$store.dispatch('stock/getLocalesDepositos', {
          empresa_codigo: this.selectedEmpresa.id,
          sucursal_codigo: this.selectedSucursal.id,
          local_codigo: local
        })
        this.load = false
        if (localesPeticion.resultado == 1){
          this.locales = localesPeticion.locales
          if (opcion == 2){
            // completo el local
            let unLocal = this.locales.filter(element => element.local_codigo == local)
            if (unLocal.length > 0) this.selectedLocal = unLocal[0]
            else{
              if (local != 0){
                this.$store.dispatch('show_snackbar', {
                  text: 'No se pudo determinar el local del depósito seleccionado.',
                  color: 'info',
                })
              }
            }
          }
        }else{
          this.$store.dispatch('show_snackbar', {
            text: localesPeticion.msj,
            color: 'error',
          })
        }
      }
    },
    limpiarForm(){
      this.selectedEmpresa = {}
      this.selectedSucursal = {}
      this.selectedTipoDep = {}
      this.nombreDeposito = ''
      this.selectedLocal = {}
      this.conLocal = false
      this.load = false
      this.estado = false
    },
    validarForm(){
      if (Object.keys(this.selectedEmpresa).length == 0) return {valido: false, msj: 'Debe seleccionar la Empresa.'}
      if (Object.keys(this.selectedSucursal).length == 0) return {valido: false, msj: 'Debe seleccionar la Sucursal.'}
      if (Object.keys(this.selectedTipoDep).length == 0) return {valido: false, msj: 'Debe seleccionar la Sucursal.'}
      if (this.nombreDeposito == null || this.nombreDeposito == undefined || this.nombreDeposito.toString().length == 0) return {valido: false, msj: 'Debe ingresar el Nombre del Depósito.'}
      //if (this.selectedTipoDep.local == 1 && Object.keys(this.selectedLocal).length == 0) return {valido: false, msj: 'Debe seleccionar un Local.'}
      return {valido: true, msj: 'OK'}
    },
    async nuevoDeposito(){
      let valido = this.validarForm()
      if (valido.valido == false){
        this.$store.dispatch('show_snackbar', {
          text: valido.msj,
          color: 'warning',
        })
        return
      }
      // local
      let local = 0
      if (Object.keys(this.selectedLocal).length > 0) local = this.selectedLocal.local_codigo
      // armo mi body
      let body = {
        empresa_codigo: this.selectedEmpresa.id,
        sucursal_codigo: this.selectedSucursal.id,
        tipo_dep_codigo: this.selectedTipoDep.tipo_dep_codigo,
        nombre: this.nombreDeposito,
        local_codigo: local,
        estado: this.estado == false ? 1 : 0
      }
      this.$store.state.loading = true
      let nuevoPeticion = await this.$store.dispatch('stock/newDeposito', body)
      this.$store.state.loading = false
      if (nuevoPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'El depósito fue creado correctamente.',
          color: 'success',
        })
        this.$emit('refrescarListado')
        this.activo = false
      }else{
        this.$store.dispatch('show_snackbar', {
          text: nuevoPeticion.msj,
          color: 'error',
        })
      }
    },
    async modificarDeposito(){
      let valido = this.validarForm()
      if (valido.valido == false){
        this.$store.dispatch('show_snackbar', {
          text: valido.msj,
          color: 'warning',
        })
        return
      }
      // local
      let local = 0
      if (Object.keys(this.selectedLocal).length > 0) local = this.selectedLocal.local_codigo
      // armo mi body
      let body = {
        empresa_codigo: this.selectedEmpresa.id,
        sucursal_codigo: this.selectedSucursal.id,
        tipo_dep_codigo: this.selectedTipoDep.tipo_dep_codigo,
        nombre: this.nombreDeposito,
        local_codigo: local,
        estado: this.estado == false ? 1 : 0,
        deposito_codigo: this.params.deposito.deposito_acc_codigo
      }
      this.$store.state.loading = true
      let updatePeticion = await this.$store.dispatch('stock/updateDeposito', body)
      this.$store.state.loading = false
      if (updatePeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'El depósito fue actualizado correctamente.',
          color: 'success',
        })
        this.$emit('refrescarListado')
        this.activo = false
      }else{
        this.$store.dispatch('show_snackbar', {
          text: updatePeticion.msj,
          color: 'error',
        })
      }
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false) this.limpiarForm()
      else this.initForm()
    },
    estado: function(){
      if (this.estado == false) this.estadoNom = 'Inhabilitado'
      else this.estadoNom = 'Habilitado'
    },
    selectedLocal: function(){
      if (this.selectedLocal == null || this.selectedLocal == undefined) this.selectedLocal = {}
    }
  },
}
</script>

<style>

</style>