<template>
  <v-dialog
    v-model="visible"
    max-width="1000px"
  >
    <v-card
      flat
      max-width="1000px"
    >
      <v-card-title>
        Seleccionar {{ seleccionNombre }}
        <v-spacer></v-spacer>
        <v-btn icon><v-icon @click="visible = false" title="Cerrar">fas fa-times</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <!-- Tabla de Artículos, comentado por el momento -->
          <v-col cols="12" v-if="opcion == 1">
            <v-data-table
              class="cebra elevation-2 mt-2"
              :headers="headers"
              :items="articulos"
              :loading="load"
              dense
              :search="search"
            >
              <!-- Buscador en el datatable -->
              <template v-slot:top>
                <v-row class="d-flex justify-end pa-2" no-gutters>
                  <v-col cols="6" sm="3">
                    <SearchDataTable
                      v-model="search"
                    />
                  </v-col>
                </v-row>
              </template>
              <!-- Items -->
              <template v-slot:[`item.precio_s_iva`]="{ item }">
                <v-row class="d-flex justify-center" no-gutters>
                  <v-text-field v-model="item.precio_s_iva" prefix="$" type="number" @keypress.enter="addArticulo(item)" outlined dense hide-details></v-text-field>
                </v-row>
              </template>
              <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
              <template
                v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                v-slot:[`item.${header.value}`]="{ value }"
              >
                {{ header.formatter(value) }}
              </template>
              <template v-slot:[`item.acciones`]="{ item }">
                <v-btn icon small @click="addArticulo(item)"><v-icon small color="success" title="Agregar">fas fa-check</v-icon></v-btn>
              </template>
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  max-width="400"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  No hay datos para los filtros seleccionados
                </v-alert>
              </template>
            </v-data-table>
          </v-col>
          <!-- Tabla de Conceptos -->
          <v-col cols="12" v-if="opcion == 2">
            <v-data-table
              class="cebra elevation-2 mt-2"
              :headers="headerss"
              :items="conceptos"
              :loading="loadd"
              dense
              :search="searchh"
            >
              <!-- Buscador en el datatable -->
              <template v-slot:top>
                <v-row class="d-flex justify-end pa-2" no-gutters>
                  <v-col cols="6" sm="3">
                    <SearchDataTable
                      v-model="searchh"
                    />
                  </v-col>
                </v-row>
              </template>
              <!-- Items -->
              <template v-slot:[`item.precio_s_iva`]="{ item }">
                <v-row class="d-flex justify-center" no-gutters>
                  <v-text-field v-model="item.precio_s_iva" prefix="$" type="number" @keypress.enter="addConcepto(item)" outlined dense hide-details></v-text-field>
                </v-row>
              </template>
              <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
              <template
                v-for="header in headerss.filter((header) => header.hasOwnProperty('formatter'))"
                v-slot:[`item.${header.value}`]="{ value }"
              >
                {{ header.formatter(value) }}
              </template>
              <!-- Acciones -->
              <template v-slot:[`item.acciones`]="{ item }">
                <v-btn icon small @click="addConcepto(item)"><v-icon small color="success" title="Agregar Concepto">fas fa-check</v-icon></v-btn>
              </template>
              <!-- Msj que se mostrara si no existen resultados -->
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  max-width="400"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  No hay datos para los filtros seleccionados
                </v-alert>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn color="secondary" @click="visible = false">Volver</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SearchDataTable from '../util/SearchDataTable.vue'
import { format_money } from '../../util/utils'
export default {
  props: {
    activo: {
      type: Boolean,
      default: false
    },
    opcion: {
      type: Number,
      default: 0
    },
    proveedorCodigo: {
      type: Number,
      default: 0
    },
    empresaId: {
      type: Number,
      default: 0
    },
    articulos: {
      type: Array,
      default: []
    },
    conceptos: {
      type: Array,
      default: []
    }
  },
  components: {
    SearchDataTable,
  },
  data() {
    return {
      visible: false,
      seleccionNombre: '',
      headers: [
        { text: 'Código', align: 'center', value: 'articulo_codigo' },
        { text: 'Descripcion', align: 'center', value: 'articulo_nombre' },
        { text: 'Cantidad', align: 'center', value: 'cantidad' },
        { text: 'Precio S/Iva', align: 'center', value: 'precio_s_iva' },
        { text: 'Seleccionar', align: 'center', value: 'acciones' }
      ],
      load: false,
      search: '',
      headerss: [
        { text: 'Código', align: 'center', value: 'concepto_codigo' },
        { text: 'Descripcion', align: 'center', value: 'concepto_nombre' },
        { text: 'Cantidad', align: 'center', value: 'cantidad' },
        { text: 'Precio S/Iva', align: 'center', value: 'precio_s_iva' },
        { text: 'Seleccionar', align: 'center', value: 'acciones' }
      ],
      loadd: false,
      searchh: ''
    }
  },
  created () {

  },
  methods: {
    addArticulo(item){
      item.opcion = 1
      this.$emit('agregarArticulo', item)
    },
    addConcepto(item){
      item.opcion = 1
      this.$emit('agregarConcepto', item)
    }
  },
  watch: {
    opcion: function(){
      if (this.opcion == 1){
        this.seleccionNombre = 'Artículos'
      }else{
        this.seleccionNombre = 'Conceptos'
      }
    },
    activo: function(){
      this.visible = this.activo
    },
    visible: function(){
      if (this.visible == false){
        this.searchh = ''
        this.search = ''
        this.$emit('cerrarModalArtConcep')
      }
    }
  },
}
</script>

<style>

</style>