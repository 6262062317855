<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <!-- Filtros -->
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <!-- Boton Agregar nueva Orden de Compra -->
            <v-btn
              style="position: absolute; top: -15px; right: 55px; z-index: 1"
              color="success"
              small
              fab
              title="Nueva Orden de Compra"
              @click="nuevaOC()"
              v-if="tienePermiso == 1"
            >
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
            <!-- Titulo Filtros -->
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtros
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar()">
                <!-- Proveedores, Estados y Fechas -->
                <v-row>
                  <v-col cols="12" sm="4" md="3" class="py-1">
                    Proveedores
                    <v-autocomplete
                      v-model="selectedProveedor"
                      item-text="proveedor_nombre"
                      :items="proveedores"
                      hide-details
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="8" md="4" class="py-1">
                    Estado
                    <v-autocomplete
                      v-model="estadosSeleccionados"
                      item-text="estado_nombre"
                      :items="estados"
                      tabindex="1"
                      hide-details
                      multiple
                      return-object
                      chips
                      deletable-chips
                      outlined
                      dense
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col :cols="big ? 12 : 6" :sm="big ? 8 : 3" :md="big ? 5 : 2" class="py-1">
                    Fecha
                    <FechaPickerRango
                      v-model="fechaRango"
                      :limpiar.sync="limpiarFecha"
                      @changeSize="changeSize"
                    />
                  </v-col>
                  <v-col cols="6" :sm="big ? 4 : 5" md="3" class="py-1">
                    Grabados por
                    <v-autocomplete
                      v-model="selectedUsuario"
                      item-text="nombre"
                      :items="usuarios"
                      hide-details
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" sm="3" md="2" class="py-1">
                    Id
                    <v-text-field
                      v-model.trim="id"
                      type="number"
                      hide-details
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="4" md="2" :class="`pt-${($vuetify.breakpoint.xs && !big) || ($vuetify.breakpoint.sm && big) || $vuetify.breakpoint.mdAndUp ? 6 : 4}`">
                    <v-checkbox
                      v-model="recepcionado"
                      label="Recepcionado"
                      dense
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="6" sm="4" md="2" :class="`pt-${($vuetify.breakpoint.sm && big) || $vuetify.breakpoint.mdAndUp ? 6 : 4}`">
                    <v-checkbox
                      v-model="facturado"
                      label="Facturado"
                      dense
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col :cols="big ? 12 : 6" :sm="big ? 12 : 4" :md="big ? 3 : 6" class="pt-0 mt-0 d-flex justify-end">
                    <BtnFiltro class="mt-0 pt-0"
                      :loading="load"
                      @clear="limpiar()"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- Listado -->
        <v-data-table
          class="cebra elevation-2 mt-2"
          v-model="selected_ordenes"
          :show-select="permisoFinalizar == 1"
          @toggle-select-all="selectAllToggle"
          :item-class="itemRowBackground"
          :headers="headers"
          :items="ordenes"
          :loading="load"
          dense
          :search="search"
          @contextmenu:row="clickDerecho"
        >
          <!-- Buscador en el datatable -->
          <template v-slot:top>
            <v-row class="d-flex justify-end pa-2" no-gutters>
              <v-col cols="6" sm="3">
                <SearchDataTable
                  v-model="search"
                />
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
            <v-simple-checkbox
              v-if="permisoFinalizar == 1"
              :value="isSelected"
              :readonly="item.disabled"
              :disabled="item.estado_codigo != 7"
              @input="select($event)"
            ></v-simple-checkbox>
          </template>
          <!-- Msj que se mostrara si no existen resultados -->
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No hay datos para los filtros seleccionados
            </v-alert>
          </template>
          <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
          <template
            v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
            v-slot:[`item.${header.value}`]="{ value }"
          >
            {{ header.formatter(value) }}
          </template>
          <template v-slot:[`item.fecha`]="{item}">
            {{moment(item.fecha).format("DD/MM/YYYY")}}
          </template>
          <template v-slot:[`item.arts_pendientes`]="{item}">
            {{ item.estado_codigo == 7 ? item.arts_pendientes : ''}}
          </template>
          <!-- Acciones -->
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn small icon color="info" @click="verOrdenCompra(item)" v-on="on">
                  <v-icon small>fas fa-eye</v-icon>
                </v-btn>
              </template>
              <span>Ver</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn small icon color="green darken-3" @click="generarExcel(item)" v-on="on">
                  <v-icon small>fas fa-file-download</v-icon>
                </v-btn>
              </template>
              <span>Descargar Excel</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.anula == 1">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="error" @click="confirmarAnularOC(item)" v-on="on">
                  <v-icon small>far fa-times-circle</v-icon>
                </v-btn>
              </template>
              <span>Anular</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.volver_estado == 1 && tienePermiso == 1">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="warning" @click="confirmarVolverEstado(item)" v-on="on">
                  <v-icon small>fas fa-fast-backward</v-icon>
                </v-btn>
              </template>
              <span>Volver Estado</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.pagado == 1">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="error" @click="confirmarMarcarNoPagada(item)" v-on="on">
                  <v-icon small>far fa-times-circle</v-icon>
                </v-btn>
              </template>
              <span>Marcar como No Pagada</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.estado_codigo == 1">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="success" @click="confirmarMarcarComoEmitida(item)" v-on="on">
                  <v-icon small>far fa-arrow-alt-circle-right</v-icon>
                </v-btn>
              </template>
              <span>Emitir</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.estado_codigo == 7 && permisoFinalizar == 1">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="success" @click="confirmarMarcarFinalizada(item)" v-on="on">
                  <v-icon small>fas fa-check-circle</v-icon>
                </v-btn>
              </template>
              <span>Marcar como Finalizada</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn small icon color="orange" @click="setComentario(item)" v-on="on">
                  <v-icon small>fas fa-comment-dots</v-icon>
                </v-btn>
              </template>
              <span>Comentario</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" class="d-flex justify-end px-4">
        <SelectedCounter
          :selected.sync="selected_ordenes"
          :items.sync="ordenes"
          :custom="true"
          @criterio="select_all()"
        />
        <v-spacer></v-spacer>
        
        <BtnConfirmar
          v-if="selected_ordenes.length > 0 && permisoFinalizar == 1"
          nombre="Marcar como finalizados"
          icono="fas fa-check"
          :texto="`¿Desea finalizar las ordenes con recep. parciales?`"
          @action="marcarFinalizadas()"
        />
      </v-col>
    </v-row>
    <!-- Modal Confirmar Anulación -->
    <ModalConfirmar 
      :activoDialog="modalConfAnular"
      :texto="`¿Está seguro de anular la Orden de Compra ${selectedOrdenCompra.id}?`"
      @action="anularOC()"
      @setearModalConfAccion="setConfAnula"
    />
    <!-- Modal Confirmar Volver Estado -->
    <ModalConfirmar 
      :activoDialog="modalConfVolverEstado"
      :texto="`¿Está seguro de volver el estado de la Orden de Compra ${selectedOrdenCompra.id}?`"
      @action="volverEstadoOC()"
      @setearModalConfAccion="setVolverEstado"
    />
    <!-- Modal Confirmar Marcar como Pagada -->
    <ModalConfirmar 
      :activoDialog="modalMarcarPagada"
      :texto="`¿Está seguro de marcar como Pagada a la Orden de Compra ${selectedOrdenCompra.id}?`"
      @action="marcarComoPagada()"
      @setearModalConfAccion="setMarcarPagada"
    />
    <!-- Modal Confirmar Marcar como No Pagada -->
    <ModalConfirmar 
      :activoDialog="modalMarcarNoPagada"
      :texto="`¿Está seguro de marcar como NO Pagada a la Orden de Compra ${selectedOrdenCompra.id}?`"
      @action="marcarComoNoPagada()"
      @setearModalConfAccion="setMarcarNoPagada"
    />
    <!-- Modal Confirmar Marcar como No Pagada -->
    <ModalConfirmar 
      :activoDialog="modalMarcarEmitida"
      :texto="`¿Está seguro de marcar como Emitida a la Orden de Compra ${selectedOrdenCompra.id}?`"
      @action="marcarComoEmitida()"
      @setearModalConfAccion="setMarcarEmitida"
    />
    <!-- Modal Confirmar Marcar Finalizada -->
    <ModalConfirmar 
      :activoDialog="modalMarcarFin"
      :texto="`¿Está seguro de marcar como Recepcionada Parcial Finalizada a la Orden de Compra ${selectedOrdenCompra.id}?`"
      @action="marcarFinalizada()"
      @setearModalConfAccion="setMarcarFinalizada"
    />
    <!-- Menu del Click Derecho -->
    <v-menu
      v-model="menu.activo"
      :position-x="menu.x"
      :position-y="menu.y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item
          @click="dialogVerFactCompra.activo = true; dialogVerFactCompra.orden_id = actualOrdenCompra.id"
        >
          <v-list-item-title>
            <v-icon color="success" small left>fas fa-clipboard-list</v-icon>
            Facturas de Compra
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="dialogVerRecepciones.activo = true; dialogVerRecepciones.orden_id = actualOrdenCompra.id"
        >
          <v-list-item-title>
            <v-icon color="info" small left>fas fa-list</v-icon>
            Recepciones
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="tienePermiso == 1 || permisoFecha == 1"
          @click="openModFechaOC(actualOrdenCompra)"
        >
          <v-list-item-title>
            <v-icon color="purple" small left>fas fa-calendar-week</v-icon>
            Modificar Fecha
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-show="false"
          v-if="permisoFecha == 1"
          @click="dialog_edit_prov = true"
        >
          <v-list-item-title>
            <v-icon color="indigo" small left>fas fa-user-tie</v-icon>
            Modificar Proveedor
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- Modal Ver Facturas de Compra -->
    <ModalVerFactCompra 
      :datos="dialogVerFactCompra"
      @setearModalVerFactComp="setModalVerFactComp"
    />
    <!-- Modal Ver Recepciones -->
    <ModalVerRecepciones 
      :datos="dialogVerRecepciones"
      @setearModalVerRecep="setModalVerRecep"
    />
    <!-- Modal Agregar Comentario -->
    <ModalAddComentario 
      :datos="dialogComentario"
      @setearModalComment="setModalComment"
      @action="setearComentario"
    />
    <!-- Modal Modificar Fecha de la Orden de Compra -->
    <ModalModFechaOC 
      :datos="dialogModFechaOC"
      @setearModalModFechaOC="setModalModFechaOC"
      @actionSuccess="buscar"
    />
    <!-- Modal Modificar Proveedor de la Orden de Compra -->
    <ModalModProveedor
      v-model="dialog_edit_prov"
      :proveedores="proveedores"
      :orden="actualOrdenCompra"
      @exito="cambioDeProveedor"
    />
  </div>
</template>

<script>
import BtnFiltro from '../../components/util/BtnFiltro.vue'
import BtnConfirmar from '../../components/util/BtnConfirmar.vue'
import SearchDataTable from '../../components/util/SearchDataTable.vue'
import FechaPickerRango from '../../components/util/FechaPickerRango.vue'
import moment from 'moment'
import ModalConfirmar from '../../components/util/ModalConfirmar.vue'
import { format_money } from '../../util/utils'
import download from 'downloadjs'
import ModalVerFactCompra from '../../components/ordenes-compra/ModalVerFactCompra.vue'
import ModalVerRecepciones from '../../components/ordenes-compra/ModalVerRecepciones.vue'
import ModalAddComentario from '../../components/generales/ModalAddComentario.vue'
import ModalModFechaOC from '../../components/ordenes-compra/ModalModFechaOC.vue'
import SelectedCounter from '../../components/util/SelectedCounter.vue'
import ModalModProveedor from '../../components/ordenes-compra/ModalModProveedor.vue'

export default {
  data() {
    return {
      moment: moment,
      panel: 0,
      empresas: [],
      selectedEmpresa: {},
      usuarios: [{id: 1, nombre: 'POR MI USUARIO'}, {id: 2, nombre: 'TODOS LOS USUARIOS'}],
      selectedUsuario: {},
      dialog_edit_prov: false,
      big: false,
      load: false,
      headers: [
        { text: 'Id', align: 'center', value: 'id' },
        { text: 'Proveedor', align: 'left', value: 'proveedor_nombre' },
        { text: 'Estado', align: 'left', value: 'estado_nombre' },
        { text: 'Usuario', align: 'left', value: 'usuario' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Arts.', align: 'end', value: 'arts' },
        { text: 'Cant. Arts.', align: 'end', value: 'cant_arts' },
        { text: 'Arts. Pend.', align: 'end', value: 'arts_pendientes'},
        { text: 'Total', align: 'end', value: 'total', formatter: format_money },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      ordenes: [],
      search: '',
      proveedores: [],
      selectedProveedor: {},
      estados: [],
      selectedEstado: {},
      estadosSeleccionados: [],
      fechaRango: [null, null],
      limpiarFecha: false,
      selectedOrdenCompra: {},
      modalConfAnular: false,
      modalConfVolverEstado: false,
      modalMarcarPagada: false,
      modalMarcarNoPagada: false,
      modalMarcarEmitida: false,
      modalMarcarFin: false,
      sortDesc: true,
      sortBy: 'id',
      recepcionado: false,
      facturado: false,
      pendiente: false,
      pagado: false,
      menu: {
        activo: false,
        x: 0,
        y: 0
      },
      dialogVerFactCompra: {
        activo: false,
        orden_id: 0,
        factura_id: 0
      },
      dialogVerRecepciones: {
        activo: false,
        orden_id: 0,
        factura_id: 0
      },
      dialogModFechaOC: {
        activo: false,
        orden_id: 0,
        fecha: ''
      },
      actualOrdenCompra: {},
      tienePermiso: 0,
      permisoFecha: 0,
      permisoFinalizar: 0,
      dialogComentario: {
        activo: false,
        comentario: '',
        id: 0,
        titulo: '',
        permiso: 0
      },
      selected_ordenes: [],
      id: null
    }
  },
  components: {
    BtnFiltro,
    SearchDataTable,
    FechaPickerRango,
    ModalConfirmar,
    ModalVerFactCompra,
    ModalVerRecepciones,
    ModalAddComentario,
    ModalModFechaOC,
    SelectedCounter,
    BtnConfirmar,
    ModalModProveedor
  },
  created () {
    this.initForm()
  },
  methods: {
    async initForm(){
      this.empresas = JSON.parse(localStorage.getItem('empresas'))
      // 13-03-2023 por MMURILLO,  asignamos directamente RFB como empresa
      this.selectedEmpresa = this.empresas.filter(element => element.id == 2)[0]
      this.$store.state.loading = true
      let initPeticion = await this.$store.dispatch('ordenesCompra/initIndexOC')
      this.$store.state.loading = false
      if (initPeticion.resultado == 1){
        this.proveedores = initPeticion.proveedores
        this.estados = initPeticion.estados
        this.tienePermiso = initPeticion.permiso
        this.permisoFecha = initPeticion.permisoFecha
        this.permisoFinalizar = initPeticion.permisoFin
      }else{
        this.$store.dispatch('show_snackbar', {
          text: initPeticion.msj,
          color: 'error',
        })
      }
    },
    async buscar(){
      // comentado el 13-01-2023 por MMURILLO ya que buscamos por RFB siempre
      // if (Object.keys(this.selectedEmpresa).length == 0){
      //   this.$store.dispatch('show_snackbar', {
      //     text: 'Debe seleccionar una empresa para empezar la búsqueda.',
      //     color: 'warning',
      //   })
      //   return
      // }
      if(this.fechaRango[0]){ //si this.fechaRango[0] != "" entra
        this.selected_ordenes = [];
        this.ordenes = []
        // usuario
        let usuario = ''
        if (Object.keys(this.selectedUsuario).length > 0 && this.selectedUsuario.id == 1) usuario = this.$store.state.username
        // proveedor
        let proveedor = 0
        if (Object.keys(this.selectedProveedor).length > 0) proveedor = this.selectedProveedor.proveedor_codigo
        // estado
        /*let estado = []
        if (this.estadosSeleccionados.length > 0) estado = this.selectedEstado.estado_id*/
        // fecha desde
        let fechaDesde = moment(this.fechaRango[0]).format("DD/MM/YYYY")
        // fecha hasta
        let fechaHasta = ''
        if (this.fechaRango[1] != null && this.fechaRango[1] != undefined && this.fechaRango[1].toString().length > 0) fechaHasta = moment(this.fechaRango[1]).format("DD/MM/YYYY")
        // recepcionado
        let recep = 0
        if (this.recepcionado == true) recep = 1
        // facturado
        let fact = 0
        if (this.facturado == true) fact = 1
        // pagado
        let pago = 0
        if (this.pagado == true) pago = 1
        let filtros = {
          empresa_codigo: this.selectedEmpresa.id,
          fecha_desde: fechaDesde,
          fecha_hasta: fechaHasta,
          usuario: usuario,
          proveedor_codigo: proveedor,
          estados: this.estadosSeleccionados,
          recepcionado: recep,
          facturado: fact,
          pagado: pago,
          id: this.id
        }
        this.$store.state.loading = true
        let ordenesPeticion = await this.$store.dispatch('ordenesCompra/getOrdenesCompra2', filtros)
        this.$store.state.loading = false
        if (ordenesPeticion.resultado == 1){
          this.ordenes = ordenesPeticion.ordenes
        }else{
          this.$store.dispatch('show_snackbar', {
            text: ordenesPeticion.msj,
            color: 'error',
          })
        }
      }else{
        this.$store.dispatch('show_snackbar', {
          text: 'Es necesario ingresar al menos una fecha desde',
          color: 'info',
        })
      }
    },
    limpiar(){
      //this.selectedEmpresa = {}
      this.selectedUsuario = {}
      this.selectedProveedor = {}
      this.selectedEstado = {}
      this.estadosSeleccionados = [];
      this.limpiarFecha = true
      this.id = null
    },
    verOrdenCompra(item){
      let path = '/orden-compra/' + item.id
      let routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    },
    nuevaOC(){
      let path = '/nueva-orden-compra'
      let routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    },
    confirmarAnularOC(item){
      this.selectedOrdenCompra = item
      this.modalConfAnular = true
    },
    confirmarVolverEstado(item){
      this.selectedOrdenCompra = item
      this.modalConfVolverEstado = true
    },
    confirmarMarcarPagada(item){
      this.selectedOrdenCompra = item
      this.modalMarcarPagada = true
    },
    confirmarMarcarNoPagada(item){
      this.selectedOrdenCompra = item
      this.modalMarcarNoPagada = true
    },
    confirmarMarcarComoEmitida(item){
      this.selectedOrdenCompra = item
      this.modalMarcarEmitida = true
    },
    confirmarMarcarFinalizada(item){
      this.selectedOrdenCompra = item
      this.modalMarcarFin = true
    },
    setComentario(item){
      this.dialogComentario.comentario = item.observacion ? item.observacion : '';
      this.dialogComentario.id = item.id
      this.dialogComentario.titulo = 'Comentario para la Orden de Compra ' + item.id
      this.dialogComentario.permiso = this.permisoFinalizar
      this.dialogComentario.activo = true
    },
    openModFechaOC(item){
      this.dialogModFechaOC.orden_id = item.id
      this.dialogModFechaOC.fecha = item.fecha
      this.dialogModFechaOC.activo = true
    },
    async marcarComoPagada(){
      this.$store.state.loading = true
      let marcarPeticion = await this.$store.dispatch('ordenesCompra/marcarPagada', {orden_id: this.selectedOrdenCompra.id})
      this.$store.state.loading = false
      if (marcarPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'Se marcó como pagada de forma exitosa.',
          color: 'success',
        })
        this.buscar()
      }else{
        this.$store.dispatch('show_snackbar', {
          text: marcarPeticion.msj,
          color: 'error',
        })
      }
    },
    async marcarComoNoPagada(){
      this.$store.state.loading = true
      let marcarPeticion = await this.$store.dispatch('ordenesCompra/marcarNoPagada', {orden_id: this.selectedOrdenCompra.id})
      this.$store.state.loading = false
      if (marcarPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'Se marcó como no pagada de forma exitosa.',
          color: 'success',
        })
        this.buscar()
      }else{
        this.$store.dispatch('show_snackbar', {
          text: marcarPeticion.msj,
          color: 'error',
        })
      }
    },
    async marcarComoEmitida(){
      this.$store.state.loading = true
      let marcarPeticion = await this.$store.dispatch('ordenesCompra/marcarEmitida', {orden_id: this.selectedOrdenCompra.id})
      this.$store.state.loading = false
      if (marcarPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'Se marcó como Emitida de forma exitosa.',
          color: 'success',
        })
        this.buscar()
      }else{
        this.$store.dispatch('show_snackbar', {
          text: marcarPeticion.msj,
          color: 'error',
        })
      }
    },
    async marcarFinalizada(){
      this.$store.state.loading = true
      let marcarPeticion = await this.$store.dispatch('ordenesCompra/marcarFinalizada', {orden_id: this.selectedOrdenCompra.id})
      this.$store.state.loading = false
      if (marcarPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'Se marcó como Finalizada de forma exitosa.',
          color: 'success',
        })
        this.buscar()
      }else{
        this.$store.dispatch('show_snackbar', {
          text: marcarPeticion.msj,
          color: 'error',
        })
      }
    },
    async generarExcel(item){
      this.$store.state.loading = true
      let excelPeticion = await this.$store.dispatch('ordenesCompra/getExcelOC', {orden_id: item.id})
      this.$store.state.loading = false
      if (excelPeticion.resultado == 1){
        download(excelPeticion.base64, `Orden Compra ${item.id}.xlsx`, 'application/vnd.ms-excel')
      }else{
        this.$store.dispatch('show_snackbar', {
          text: excelPeticion.msj,
          color: 'error',
        })
      }
    },
    async anularOC(){
      this.$store.state.loading = true
      let anulaPeticion = await this.$store.dispatch('ordenesCompra/anularOrdenCompra', {orden_id: this.selectedOrdenCompra.id})
      this.$store.state.loading = false
      if (anulaPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'La Orden de Compra seleccionada fue anulada correctamente.',
          color: 'success',
        })
        this.buscar()
      }else{
        this.$store.dispatch('show_snackbar', {
          text: anulaPeticion.msj,
          color: 'error',
        })
      }
    },
    setConfAnula(value){
      this.modalConfAnular = value
      if (this.modalConfAnular == false) this.selectedOrdenCompra = {}
    },
    async volverEstadoOC(){
      this.$store.state.loading = true
      let volverPeticion = await this.$store.dispatch('ordenesCompra/volverEstadoOrdCompra', {orden_id: this.selectedOrdenCompra.id})
      this.$store.state.loading = false
      if (volverPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'Se volvió el estado de forma exitosa.',
          color: 'success',
        })
        this.buscar()
      }else{
        this.$store.dispatch('show_snackbar', {
          text: volverPeticion.msj,
          color: 'error',
        })
      }
    },
    setVolverEstado(value){
      this.modalConfVolverEstado = value
      if (this.modalConfVolverEstado == false) this.selectedOrdenCompra = {}
    },
    setMarcarPagada(value){
      this.modalMarcarPagada = value
      if (this.modalMarcarPagada == false) this.selectedOrdenCompra = {}
    },
    setMarcarNoPagada(value){
      this.modalMarcarNoPagada = value
      if (this.modalMarcarNoPagada == false) this.selectedOrdenCompra = {}
    },
    setMarcarEmitida(value){
      this.modalMarcarEmitida = value
      if (this.modalMarcarEmitida == false) this.selectedOrdenCompra = {}
    },
    setMarcarFinalizada(value){
      this.modalMarcarFin = value
      if (this.modalMarcarFin == false) this.selectedOrdenCompra = {}
    },
    setModalModFechaOC(value){
      this.dialogModFechaOC.activo = value
      if (this.dialogModFechaOC.activo == false){
        this.dialogModFechaOC.fecha = ''
        this.dialogModFechaOC.orden_id = 0
      }
    },
    clickDerecho(event, item){
      event.preventDefault()
      this.actualOrdenCompra = {}
      this.menu.activo = false
      this.menu.x = event.clientX
      this.menu.y = event.clientY
      this.$nextTick(() => {
        this.menu.activo = true
        this.actualOrdenCompra = item.item
      })
    },
    setModalVerFactComp(value){
      this.dialogVerFactCompra.activo = value
      if (this.dialogVerFactCompra.activo == false){
        this.actualOrdenCompra = {}
        this.dialogVerFactCompra.orden_id = 0
      }
    },
    setModalVerRecep(value){
      this.dialogVerRecepciones.activo = value
      if (this.dialogVerRecepciones.activo == false){
        this.actualOrdenCompra = {}
        this.dialogVerRecepciones.orden_id = 0
      }
    },
    setModalComment(value){
      this.dialogComentario.activo = value
      if (this.dialogComentario.activo == false){
        this.dialogComentario.comentario = ''
        this.dialogComentario.titulo = ''
        this.dialogComentario.id = 0
        this.dialogComentario.permiso = 0
      }
    },
    async setearComentario(obj){
      // paso a guardar el comentario
      this.$store.state.loading = true
      let setPeticion = await this.$store.dispatch('ordenesCompra/setearComentario', obj)
      this.$store.state.loading = false
      if (setPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'Se marcó como Finalizada de forma exitosa.',
          color: 'success',
        })
        this.buscar()
      }else{
        this.$store.dispatch('show_snackbar', {
          text: setPeticion.msj,
          color: 'error',
        })
      }
    },
    changeSize (custom) {
      this.big = custom
    },
    selectAllToggle(){
      if(this.selected_ordenes.length < this.ordenes.filter(e => e.estado_codigo==7).length){
        this.select_all();
      }else this.selected_ordenes = [];
    },
    select_all(){
      this.selected_ordenes = []
      this.ordenes.forEach(elem => {
        if(elem.estado_codigo == 7){
          this.selected_ordenes.push(elem);
        }
      })
    },
    async marcarFinalizadas(){
      this.$store.state.loading = true;
      const res = await this.$store.dispatch('ordenesCompra/marcarFinalizadaMuchas', { ordenes: this.selected_ordenes});
      this.$store.state.loading = false;

      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', {text: res.message, color: 'error'});
      }
      let idMsjExitosas = res.idFinalizadas.join(", ");
      let idMsjNoExitosas = res.idNoFinalizadas.join(", ");

      if(idMsjExitosas.length != 0 && idMsjNoExitosas.length == 0){
        this.$store.dispatch('show_snackbar', {text: 'Ordenes finalizadas con éxito: '+idMsjExitosas, color: 'success'});
      }
      if(idMsjNoExitosas.length !=0 && idMsjExitosas.length == 0){
        this.$store.dispatch('show_snackbar', {text: 'Problemas al finalizar las ordenes: '+idMsjNoExitosas, color: 'error'});
      }
      if(idMsjNoExitosas.length !=0 && idMsjExitosas.length != 0){
        this.$swal.fire({
          icon: 'info',
          title: 'Información de las ordenes marcas',
          text: `Ordenes finalizadas con éxito: ${idMsjExitosas}.\nProblemas al finalizar las ordenes: ${idMsjNoExitosas}. Pruebe reprocesar las que fallaron.`, 
        });
      }
      this.buscar();
    },
    itemRowBackground (item) {
      return item.id == this.actualOrdenCompra.id && this.menu.activo ? ['font-weight-bold', 'background-color: rowsel2'] : ''
    },
    cambioDeProveedor (data) {
      this.actualOrdenCompra.proveedor_codigo = data.codigo
      this.actualOrdenCompra.proveedor_nombre = data.nombre
    }
  },
  watch: {
  },
}
</script>