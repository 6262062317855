<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    :persistent="load"
  >
    <v-card :disabled="load">
      <v-card-title>
        Modificar Proveedor de la Orden de Compra {{ orden.id }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form">
          <v-row class="mt-6">
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field
                v-model="orden.proveedor_nombre"
                label="Proveedor actual"
                outlined
                readonly
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-autocomplete
                v-model="nuevo_proveedor"
                label="Nuevo proveedor"
                item-text="proveedor_nombre"
                item-value="proveedor_codigo"
                :items="proveedores.filter(pro => pro.proveedor_codigo != orden.proveedor_codigo)"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" class="py-0 body-1">
              ¿Desea mantener las relaciones del Proveedor actual con los artículos de la Orden de Compra?
              <v-radio-group
                v-model="mantener_relacion"
                :rules="[rules.required_zero]"
                validate-on-blur
                dense
                row
                @change="$refs.form.resetValidation()"
              >
                <v-radio
                  label="Si"
                  :value="1"
                ></v-radio>
                <v-radio
                  label="No"
                  :value="0"
                ></v-radio>
              </v-radio-group>
              <v-expand-transition>
                <v-alert
                  v-if="mantener_relacion != null"
                  border="left"
                  type="info"
                >
                  {{ mantener_relacion == 1 ?
                    'Al mantener la relación con el Proveedor actual, se vincularán los artículos de la Orden con el nuevo Proveedor, manteniendo el vínculo de los artículos con el Proveedor actual' :
                    'Al no mantener la relación con el Proveedor actual, se desvincularán los artículos de la Orden del Proveedor actual y se vincularán al nuevo Proveedor' }}
                </v-alert>
              </v-expand-transition>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="load"
          @click="dialog = false"
        >
          Cacnelar
        </v-btn>
        <BtnConfirmar
          :loading="load"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { get_nombre_obj_arr } from '../../util/utils'
import BtnConfirmar from '../util/BtnConfirmar.vue'

export default {
  data () {
    return {
      load: false,
      nuevo_proveedor: null,
      mantener_relacion: null,
      rules: {
        required: value => !!value || 'Campo requerido',
        required_zero: value => {
          if (value == null || value == undefined) {
            return 'Campo requerido'
          } else {
            return true
          }
        }
      }
    }
  },
  props: {
    value: Boolean,
    orden: Object,
    proveedores: Array
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar
  },
  watch: {
    dialog (val) {
      val || this.limpiar()
    }
  },
  methods: {
    async guardar () {
      if (!this.$refs.form.validate()) return

      this.load = true
      let result = await this.$store.dispatch('ordenesCompra/editar_proveedor', {
        orden_id: this.orden.id,
        proveedor_id: this.nuevo_proveedor,
        mantiene_relacion: this.mantener_relacion
      })
      this.load = false

      if (result.exito == 1) {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'success'
        })
        let prov_nombre = get_nombre_obj_arr(this.proveedores, 'proveedor_codigo', this.nuevo_proveedor, 'proveedor_nombre')
        this.$emit('exito', {
          codigo: this.nuevo_proveedor,
          nombre: prov_nombre
        })
        this.dialog = false
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    limpiar () {
      this.nuevo_proveedor = null
      this.mantener_relacion = null
      this.$refs.form.resetValidation()
    }
  }
}
</script>