<template>
  <v-dialog
    v-model="activo"
    max-width="50%"
  >
    <v-card
      flat
    >
      <!-- Titulo -->
      <v-card-title
        class="pt-0"
        :class="$vuetify.breakpoint.smAndDown ? 'd-flex justify-center' : ''"
      >
        Auditar Devolución N° {{ devolucion_codigo }}
        <v-spacer></v-spacer>
        <v-btn icon><v-icon @click="activo = false" title="Cerrar">fas fa-times</v-icon></v-btn>
      </v-card-title>
      <!-- Contenido -->
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="observacion"
              outlined
              dense
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end mx-2">
        <v-btn color="warning" @click="activo = false" class="ma-2">Volver</v-btn>
        <BtnConfirmar
          @action="auditar()"
          color="success"
          icono="fas fa-check-double"
          :texto="`¿Esta seguro de auditar la devolución seleccionada?`"
          nombre="Auditar"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BtnConfirmar from '../../util/BtnConfirmar.vue'
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  components: {
    BtnConfirmar
  },
  computed: {
    activo: {
      get() {
        return this.datos.activo
      },
      set(value) {
        this.$emit('setearModalAuditar', value)
      }
    },
    devolucion_codigo: {
      get() {
        return this.datos.devolucion_codigo
      }
    }
  },
  data() {
    return {
      observacion: ''
    }
  },
  methods: {
    async auditar(){
      this.$store.state.loading = true
      let auditarPeticion = await this.$store.dispatch('remitosDevolucion/auditarDevolucion', {observacion: this.observacion, codigo: this.devolucion_codigo})
      this.$store.state.loading = false
      if (auditarPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'Devolución auditada con Éxito.',
          color: 'success',
        })
        this.activo = false
      }else{
        this.$store.dispatch('show_snackbar', {
          text: auditarPeticion.msj,
          color: 'error',
        })
      }
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false) this.observacion = ''
    }
  },
}
</script>

<style>

</style>