<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="60%"
    >
      <v-card
        flat
      >
        <v-card-title>
          {{ titulo }}
          <v-spacer></v-spacer>
          <v-btn icon @click="activo = false"><v-icon title="Cerrar">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-textarea
                outlined
                dense
                label="Comentario"
                v-model="comentario"
                :readonly="permiso == 0"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn
            color="error"
            class="ma-2"
            @click="activo = false"
          >
            Volver
          </v-btn>
          <v-btn
            color="success"
            class="ma-2"
            @click="dialogConf = true"
            v-if="permiso == 1"
          >
            <v-icon left>fas fa-check</v-icon>
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Modal Confirmar Accion -->
    <ModalConfirmar 
      :activoDialog="dialogConf"
      :texto="`¿Está seguro de asociar el comentario?`"
      @action="confirmar()"
      @setearModalConfAccion="setModalAsociar"
    />
  </div>
</template>

<script>
import ModalConfirmar from '../util/ModalConfirmar.vue'
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  components: {
    ModalConfirmar,
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('setearModalComment', value)
      }
    },
    titulo: {
      get(){
        return this.datos.titulo
      }
    },
    msj: {
      get(){
        return this.datos.comentario
      }
    },
    codigo: {
      get(){
        return this.datos.id
      }
    },
    permiso: {
      get(){
        return this.datos.permiso
      }
    }
  },
  data() {
    return {
      comentario: '',
      dialogConf: false
    }
  },
  methods: {
    initForm(){
      this.comentario = this.msj
    },
    limpiarForm(){
      this.comentario = ''
    },
    confirmar(){
      this.$emit('action', {
        comentario: this.comentario,
        codigo: this.codigo
      })
      this.activo = false
    },
    setModalAsociar(value){
      this.dialogConf = value
    },
  },
  watch: {
    activo: function(){
      if (this.activo == false) this.limpiarForm()
      else this.initForm()
    }
  },
}
</script>

<style>

</style>