<template>
  <v-dialog
    v-model="activo"
    max-width="60%"
  >
    <v-card
      flat
    >
      <v-card-title>
        <v-icon small class="ma-2">fas fa-plus-circle</v-icon> Nuevo Parámetro
        <v-spacer></v-spacer>
        <v-btn icon><v-icon @click="activo = false" title="Cerrar">fas fa-times</v-icon></v-btn>
      </v-card-title>
      <v-card-text class="mt-4">
        <!-- Operacion, Nombre de Parametro y Descripcion -->
        <v-row :no-gutters="$vuetify.breakpoint.xs">
          <v-col cols="12" sm="4" md="4" class="py-1">
            <v-autocomplete
              v-model="selectedOperacion"
              item-text="operacion_nombre"
              :items="operaciones"
              hide-details
              outlined
              dense
              return-object
              label="Operación"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4" class="py-1">
            <v-text-field
              v-model="paramNombre"
              outlined
              dense
              type="text"
              hide-details
              label="Parámetro Nombre"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" class="py-1">
            <v-text-field
              v-model="paramDescripcion"
              outlined
              dense
              type="text"
              hide-details
              label="Parámetro Descrip."
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- Parametro valor y Actualiza Usuario -->
        <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-2">
          <v-col cols="12" sm="4" md="4" class="py-1">
            <v-text-field
              v-model="paramValor"
              outlined
              dense
              type="text"
              hide-details
              label="Valor"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1">
            <v-checkbox
              v-model="modUsuario"
              :label="`${modUsuarioNom}`"
              dense
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end mx-2">
        <v-btn color="warning" @click="activo = false" class="ma-2">Volver</v-btn>
        <BtnConfirmar 
          texto="¿Está seguro de dar de Alta el nuevo Parámetro?"
          @action="nuevoParametro()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BtnConfirmar from '../../util/BtnConfirmar.vue'
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  components: {
    BtnConfirmar,
  },
  computed: {
    activo: {
      get() {
        return this.datos.activo
      },
      set(value) {
        this.$emit('setearModalNewParam', value)
      }
    },
    operaciones: {
      get() {
        return this.datos.operaciones
      },
    }
  },
  data() {
    return {
      selectedOperacion: {},
      paramNombre: '',
      paramDescripcion: '',
      paramValor: '',
      modUsuario: false,
      modUsuarioNom: 'No Modifica Usuario'
    }
  },
  methods: {
    verificarObligatorios(){
      if (Object.keys(this.selectedOperacion).length == 0) return {valido: false, msj: 'Debe seleccionar la operacion a la que pertenece el parámetro.'}
      if (this.paramNombre == null || this.paramNombre == undefined || this.paramNombre.toString().length == 0) return {valido: false, msj: 'Debe ingresar el nombre del nuevo Parámetro.'}
      if (this.paramDescripcion == null || this.paramDescripcion == undefined || this.paramDescripcion.toString().length == 0) return {valido: false, msj: 'Debe ingresar la descripción del nuevo Parámetro.'}
      if (this.paramValor == null || this.paramValor == undefined || this.paramValor.toString().length == 0) return {valido: false, msj: 'Debe ingresar el valor del nuevo Parámetro.'}
      return {valido: true, msj: 'OK'}
    },
    async nuevoParametro(){
      let valido = this.verificarObligatorios()
      if (valido.valido == false){
        this.$store.dispatch('show_snackbar', {
          text: valido.msj,
          color: 'warning',
        })
        return
      }
      this.$store.state.loading = true
      let nuevoPeticion = await this.$store.dispatch('parametros/newParametro', {
        operacion_codigo: this.selectedOperacion.operacion_codigo,
        nombre: this.paramNombre,
        descripcion: this.paramDescripcion,
        valor: this.paramValor,
        modifica_usuario: this.modUsuario == false ? 0 : 1
      })
      this.$store.state.loading = false
      if (nuevoPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'El parámetro fue creado con éxito.',
          color: 'success',
        })
        this.activo = false
      }else{
        this.$store.dispatch('show_snackbar', {
          text: nuevoPeticion.msj,
          color: 'error',
        })
      }
    },
    limpiarForm(){
      this.selectedOperacion = {}
      this.paramNombre = ''
      this.paramDescripcion = ''
      this.paramValor = ''
      this.modUsuario = false
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false) this.limpiarForm()
    },
    modUsuario: function(){
      if (this.modUsuario == false) this.modUsuarioNom = 'No Modifica Usuario'
      else this.modUsuarioNom = 'Modifica Usuario'
    }
  },
}
</script>

<style>

</style>