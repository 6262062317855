<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <!-- Titulo y boton agregar -->
            <v-btn
              style="position: absolute; top: -15px; right: 55px; z-index: 1"
              color="success"
              small
              fab
              title="Nuevo Depósito"
              @click="abrirModalDeposito(null, 1)"
            >
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtros
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Filtros y Buscar -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar()">
                <!-- Empresa, Sucursal, Tipo y Nombre de Depósito -->
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Empresa
                    <v-autocomplete
                      v-model="selectedEmpresa"
                      item-text="nombre_corto"
                      :items="empresas"
                      hide-details
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Sucursal
                    <v-autocomplete
                      v-model="selectedSucursal"
                      item-text="nombre"
                      :items="sucursales"
                      hide-details
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Tipo
                    <v-autocomplete
                      v-model="selectedTipoDep"
                      item-text="tipo_dep_nombre"
                      :items="tiposDepositos"
                      hide-details
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Nombre
                    <v-text-field
                      v-model="nombreDeposito"
                      outlined
                      dense
                      type="text"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- Botones -->
                <v-row :no-gutters="$vuetify.breakpoint.xs" justify="end">
                  <BtnFiltro
                    :loading="load"
                    @clear="limpiar()"
                    clase="mt-0"
                  />
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- Listado -->
        <v-data-table
          class="cebra elevation-2 mt-2"
          :headers="headers"
          :items="depositos"
          :loading="load"
          dense
          :search="search"
        >
          <!-- Buscador en el datatable -->
          <template v-slot:top>
            <v-row class="d-flex justify-end pa-2" no-gutters>
              <v-col cols="6" sm="3">
                <SearchDataTable
                  v-model="search"
                />
              </v-col>
            </v-row>
          </template>
          <!-- Msj que se mostrara si no existen resultados -->
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No hay datos para los filtros seleccionados.
            </v-alert>
          </template>
          <!-- Acciones -->
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn small icon color="info" @click="abrirModalDeposito(item, 2)" v-on="on">
                  <v-icon small>fas fa-edit</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.en_desuso == 0">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="error" @click="confirmarInhabilitarDep(item)" v-on="on">
                  <v-icon small>far fa-times-circle</v-icon>
                </v-btn>
              </template>
              <span>Inhabilitar</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.en_desuso == 1">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="success" @click="confirmarHabilitarDep(item)" v-on="on">
                  <v-icon small>far fa-check-circle</v-icon>
                </v-btn>
              </template>
              <span>Habilitar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Modal Confirmar Inhabilitar -->
    <ModalConfirmar 
      :activoDialog="modalInhabilitar"
      :texto="`¿Está seguro de inhabilitar el depósito ${selectedItem.deposito_acc_nombre}?`"
      @action="inhabilitarDep()"
      @setearModalConfAccion="setModalConfAccion"
    />
    <!-- Modal Confirmar Habilitar -->
    <ModalConfirmar 
      :activoDialog="modalHabilitar"
      :texto="`¿Está seguro de habilitar el depósito ${selectedItem.deposito_acc_nombre}?`"
      @action="habilitarDep()"
      @setearModalConfAccion="setModalConfAccion"
    />
    <!-- Modal Ver Depósito -->
    <ModalVerDeposito 
      :datos="dialogVerDeposito"
      @setearModalVerDep="setModalVerDep"
      @refrescarListado="buscar"
    />
  </div>
</template>

<script>
import BtnFiltro from '../../components/util/BtnFiltro.vue'
import SearchDataTable from '../../components/util/SearchDataTable.vue'
import ModalConfirmar from '../../components/util/ModalConfirmar.vue'
import ModalVerDeposito from '../../components/generales/ModalVerDeposito.vue'
export default {
  data() {
    return {
      panel: 0,
      selectedEmpresa: {},
      empresas: [],
      selectedSucursal: {},
      sucursales: [],
      selectedTipoDep: {},
      tiposDepositos: [],
      nombreDeposito: '',
      load: false,
      headers: [
        { text: 'Deposito', align: 'left', value: 'deposito_acc_nombre' },
        { text: 'Empresa', align: 'left', value: 'empresa_nombre' },
        { text: 'Sucursal', align: 'left', value: 'sucursal_nombre' },
        { text: 'Local', align: 'left', value: 'local_nombre' },
        { text: 'Tipo', align: 'left', value: 'tipo_dep_nombre' },
        { text: 'Estado', align: 'left', value: 'en_desuso_nombre' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      depositos: [],
      search: '',
      selectedItem: {},
      modalInhabilitar: false,
      modalHabilitar: false,
      dialogVerDeposito: {
        activo: false,
        deposito: {},
        empresas: [],
        sucursales: [],
        tipos_depositos: [],
        nuevo: 0,
        ver: 0
      }
    }
  },
  components: {
    BtnFiltro,
    SearchDataTable,
    ModalConfirmar,
    ModalVerDeposito
  },
  created () {
    this.initForm()
  },
  methods: {
    async initForm(){
      this.empresas = JSON.parse(localStorage.getItem('empresas'))
      this.sucursales = JSON.parse(localStorage.getItem('sucursales'))
      this.dialogVerDeposito.empresas = JSON.parse(localStorage.getItem('empresas'))
      this.dialogVerDeposito.sucursales = JSON.parse(localStorage.getItem('sucursales'))
      this.$store.state.loading = true
      let initPeticion = await this.$store.dispatch('stock/initFormDepositos')
      this.$store.state.loading = false
      if (initPeticion.resultado == 1){
        this.tiposDepositos = initPeticion.tipos
        this.dialogVerDeposito.tipos_depositos = initPeticion.tipos
      }else{
        this.$store.dispatch('show_snackbar', {
          text: initPeticion.msj,
          color: 'error',
        })
      }
    },
    async buscar(){
      // empresa
      let empresa = 0
      if (Object.keys(this.selectedEmpresa).length > 0) empresa = this.selectedEmpresa.id
      // sucursal
      let sucursal = 0
      if (Object.keys(this.selectedSucursal).length > 0) sucursal = this.selectedSucursal.id
      // tipo de deposito
      let tipoDep = 0
      if (Object.keys(this.selectedTipoDep).length > 0) tipoDep = this.selectedTipoDep.tipo_dep_codigo
      // nombre
      let nomDep = ''
      if (this.nombreDeposito != null && this.nombreDeposito != undefined && this.nombreDeposito.toString().length > 0) nomDep = this.nombreDeposito
      this.depositos = []
      this.$store.state.loading = true
      let depositosPeticion = await this.$store.dispatch('stock/getDepositos', {
        empresa_codigo: empresa,
        sucursal_codigo: sucursal,
        tipo_dep_codigo: tipoDep,
        nombre_deposito: nomDep
      })
      this.$store.state.loading = false
      if (depositosPeticion.resultado == 1){
        this.depositos = depositosPeticion.depositos
      }else{
        this.$store.dispatch('show_snackbar', {
          text: depositosPeticion.msj,
          color: 'error',
        })
      }
    },
    limpiar(){
      this.selectedEmpresa = {}
      this.selectedSucursal = {}
      this.selectedTipoDep = {}
      this.nombreDeposito = ''
    },
    abrirModalDeposito(item, opcion){
      // indico que estoy por ver un deposito
      if (opcion == 1) this.dialogVerDeposito.nuevo = 1
      if (opcion == 2){
        this.dialogVerDeposito.ver = 1
        this.dialogVerDeposito.deposito = item
      }
      this.dialogVerDeposito.activo = true
    },
    confirmarInhabilitarDep(item){
      this.selectedItem = item
      this.modalInhabilitar = true
    },
    async inhabilitarDep(){
      this.$store.state.loading = true
      let bajaPeticion = await this.$store.dispatch('stock/setEstadoDeposito', {
        deposito_codigo: this.selectedItem.deposito_acc_codigo,
        bodega_codigo: this.selectedItem.deposito_bb_codigo,
        opcion: 1
      })
      this.$store.state.loading = false
      if (bajaPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'El depósito seleccionado fue inhabilitado con éxito.',
          color: 'success',
        })
        this.buscar()
        this.selectedItem = {}
      }else{
        this.$store.dispatch('show_snackbar', {
          text: bajaPeticion.msj,
          color: 'error',
        })
        this.selectedItem = {}
      }
    },
    confirmarHabilitarDep(item){
      this.selectedItem = item
      this.modalHabilitar = true
    },
    async habilitarDep(){
      this.$store.state.loading = true
      let altaPeticion = await this.$store.dispatch('stock/setEstadoDeposito', {
        deposito_codigo: this.selectedItem.deposito_acc_codigo,
        bodega_codigo: this.selectedItem.deposito_bb_codigo,
        opcion: 2
      })
      this.$store.state.loading = false
      if (altaPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'El depósito seleccionado fue habilitado con éxito.',
          color: 'success',
        })
        this.buscar()
        this.selectedItem = {}
      }else{
        this.$store.dispatch('show_snackbar', {
          text: altaPeticion.msj,
          color: 'error',
        })
        this.selectedItem = {}
      }
    },
    setModalConfAccion(value){
      this.modalInhabilitar = value
      this.modalHabilitar = value
    },
    setModalVerDep(value){
      this.dialogVerDeposito.activo = value
      if (this.dialogVerDeposito.activo == false){
        this.dialogVerDeposito.deposito = {}
        this.dialogVerDeposito.nuevo = 0
        this.dialogVerDeposito.ver = 0
      }
    }
  },
}
</script>

<style>

</style>