<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- Cabecera -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <!-- Boton Agregar nueva Factura de Compra -->
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="crearNuevaFC()"
            title="Crear Nueva Factura de Compra"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <!-- Titulo Filtros -->
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtros
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <!-- Filtros -->
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar">
              <!-- Empresa, Proveedor, Fecha Desde y Fecha Hasta -->
              <v-row>
                <v-col cols="12" sm="3" md="3" class="py-1">
                  Empresa
                  <v-autocomplete
                    v-model="selectedEmpresa"
                    item-text="nombre_corto"
                    :items="empresas"
                    hide-details
                    clearable
                    outlined
                    dense
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" :sm="big? 4 : 5" md="3" class="py-1">
                  Proveedor
                  <v-autocomplete
                    v-model="selectedProveedor"
                    item-text="proveedor_nombre"
                    :items="proveedores"
                    hide-details
                    clearable
                    outlined
                    dense
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" :sm="big? 5 : 4" :md="big ? 6 : 3" class="py-1">
                  Fecha
                  <FechaPickerRango
                    v-model="fechaRango"
                    :limpiar.sync="limpiarFecha"
                    @changeSize="changeSize"
                  />
                </v-col>
                <v-col cols="12" sm="4" md="3" class="py-1">
                  Id Fact Compra
                  <v-text-field
                    v-model="idFactura"
                    outlined
                    dense
                    type="number"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="2" class="py-1">
                  Estado
                  <v-autocomplete
                    v-model="selectedEstado"
                    item-text="estado_nombre"
                    :items="estados"
                    hide-details
                    clearable
                    outlined
                    dense
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4" md="2" class="py-1">
                  Tipo Fac
                  <v-text-field
                    v-model="tipoFactura"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="2" class="py-1">
                  Emision
                  <v-text-field
                    v-model="emision"
                    outlined
                    dense
                    type="number"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="3" class="py-1">
                  Numero
                  <v-text-field
                    v-model="numero"
                    outlined
                    dense
                    type="number"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="3" class="py-1">
                  Referencia
                  <v-text-field
                    v-model="referencia"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="py-1">
                  Usuario
                  <v-text-field
                    v-model="usuario"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="py-1">
                  Sucursales
                  <v-autocomplete
                    v-model="selectedSucursal"
                    item-text="nombre"
                    :items="sucursales"
                    hide-details
                    clearable
                    outlined
                    dense
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="pt-1">
                  Estado Control
                  <v-autocomplete
                    v-model="selectedEstadoControl"
                    item-text="estado_control_nombre"
                    :items="estadosControl"
                    hide-details
                    clearable
                    outlined
                    dense
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="py-1">
                  Orden de Pago
                  <v-text-field
                    v-model="ordenPago"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="3" class="py-1">
                  Pago Desde
                  <FechaPicker
                    v-model="fechaDesde"
                    hideDetails
                  />
                </v-col>
                <v-col cols="6" sm="3" md="3" class="py-1">
                  Pago Hasta
                  <FechaPicker
                    v-model="fechaHasta"
                    hideDetails
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-1">
                  <BtnFiltro
                    :loading="load"
                    @clear="limpiar()"
                    class="pt-0"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- Listado -->
      <v-data-table
        v-model="selected"
        class="cebra elevation-2 mt-2"
        :headers="headers"
        :items="facturasCompra"
        :loading="load"
        dense
        :search="search"
        @contextmenu:row="clickDerecho"
        :single-select="false"
        item-key="factura_id"
        show-select
        @toggle-select-all="selectAllToggle"
      >
        <!-- Buscador en el datatable -->
        <template v-slot:top>
          <v-row class="pb-2 pt-2" no-gutters>
            <v-btn color="success" small @click="pagar()" class="ma-2" title="Pagar Facturas" v-if="selected.length > 0"><v-icon class="ma-2" small>fas fa-money-check-alt</v-icon> Pagar</v-btn>
            <v-btn color="info" small @click="seleccionarTodos()" class="ma-2" v-if="selected.length != facturasCompra.length && selected.length > 0"><v-icon class="ma-2" small>far fa-check-circle</v-icon> Seleccionar Todos</v-btn>
            <v-btn color="warning" small @click="quitarTodos()" class="ma-2" v-if="selected.length == facturasCompra.length && facturasCompra.length > 0"><v-icon class="ma-2" small>fas fa-ban</v-icon> Quitar Todos</v-btn>
            <v-btn color="error" small @click="quitarTodos()" class="ma-2" v-if="selected.length > 0 && selected.length != facturasCompra.length"><v-icon class="ma-2" small>fas fa-ban</v-icon> Limpiar Seleccion</v-btn>
            <v-btn color="success" small @click="dialogConfAsociar = true" class="ma-2" v-if="selected.length > 0 && tienePermiso == 1"><v-icon class="ma-2" small>far fa-check-circle</v-icon> Marcar Pagadas</v-btn>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3" md="3">
              <SearchDataTable
                v-model="search"
                class="pt-2"
              />
            </v-col>
          </v-row>
          <v-row no-gutters v-if="selected.length > 0">
            <v-col cols="12" class="pt-0 pb-0 mt-0 mb-0 pl-2">
              <p>{{ selected.length }} elementos seleccionados de {{ facturasCompra.length }}. Total: ${{ Intl.NumberFormat("de-DE").format(totalSeleccionado) }}</p>
            </v-col>
          </v-row>
        </template>
        <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
        <!-- <template
          v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
          v-slot:[`item.${header.value}`]="{ value }"
        >
          {{ header.formatter(value) }}
        </template> -->
        <!-- Acomodo la emision y numero -->
        <template v-slot:[`item.comprobante`]="{ item }">
          <v-chip :color="item.comprobante.toString().indexOf('NC') != -1 ? 'error' : ''" small>
            {{ item.comprobante }}
          </v-chip>
        </template>
        <!-- Acomodo los totales -->
        <template v-slot:[`item.total_recalculado`]="{ item }">
          <div v-if="roundNumber(item.total_recalculado, 2) > 0">{{ item.abreviatura + ' ' + Intl.NumberFormat("de-DE").format(item.total_recalculado) }}</div>
        </template>
        <template v-slot:[`item.total_real`]="{ item }">
          {{ '$ ' + Intl.NumberFormat("de-DE").format(item.total_real) }}
        </template>
        <!-- Deshabilito los checks de los detalles que ya se encuentren pagados -->
        <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
          <v-simple-checkbox
            :value="isSelected"
            :readonly="item.estado_control_id == 4"
            :disabled="item.estado_control_id == 4"
            @input="select($event)"
          ></v-simple-checkbox>
        </template>
        <!-- Acciones y modal para confirmar -->
        <template v-slot:[`item.acciones`]="{ item }">
          <v-btn icon small @click="openWindow(item)"><v-icon small color="primary" title="Ver">fas fa-eye</v-icon></v-btn>
          <BtnConfirmar
            :icon_button="true"
            :small="true"
            icono="fas fa-ban"
            @action="anularFacturaCompra(item)"
            color="error"
            title="Anular"
            v-if="item.if_anula == 1"
            :texto="item.if_anula == 1 ? `¿Esta seguro de Anular la Factura ${item.factura_id}?` : `No se puede Anular la Factura seleccionada.`"
          />
          <v-btn icon small @click="subirArchivo(item)"><v-icon small :color="parseInt(item.con_archivo) > 0 ? 'success' : 'error'" title="Subir Archivos">fas fa-file-upload</v-icon></v-btn>
          <v-btn icon small @click="ordenPagoFiles(item)"><v-icon small :color="parseInt(item.orden_pago_id) > 0 ? 'info' : 'error'" title="Orden de Pago">fas fa-file-pdf</v-icon></v-btn>
        </template>
        <!-- Msj que se mostrara si no existen resultados -->
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
      <!-- Modal Subir Archivos -->
      <ModalSubirArchivos
        :ruta="ruta_carpeta"
        :activo="dialogSubirArchivo"
        :facturaId="facturaSeleccionada"
        @cerrarModalSA="closeModalSubirArchivo()"
        @refrescarTabla="buscar()"
      />
      <!-- Modal Subir Archivos -->
      <ModalUploadImgGral 
        :datos="dialogSubirImgs"
        @setearModalUpImg="setModalUpImg"
      />
    </v-col>
    <!-- Menu del Click Derecho -->
    <v-menu
      v-model="menu.activo"
      :position-x="menu.x"
      :position-y="menu.y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item
          @click="dialogVerOrdCompra.activo = true; dialogVerOrdCompra.factura_id = actualFacturaCompra.factura_id"
        >
          <v-list-item-title>
            <v-icon color="success" small left>fas fa-clipboard-list</v-icon>
            Ordenes de Compra
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="dialogVerRecepciones.factura_id = actualFacturaCompra.factura_id; dialogVerRecepciones.activo = true;"
        >
          <v-list-item-title>
            <v-icon color="info" small left>fas fa-list</v-icon>
            Recepciones
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="pagar()"
        >
          <v-list-item-title>
            <v-icon color="rowsel" small left>fas fa-money-check-alt</v-icon>
            Pagar
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- Modal Ver Recepciones -->
    <ModalVerRecepciones 
      :datos="dialogVerRecepciones"
      @setearModalVerRecep="setModalVerRecep"
    />
    <!-- Modal Ver Ordenes de Compra -->
    <ModalVerOrdCompra 
      :datos="dialogVerOrdCompra"
      @setearModalVerOrdComp="setModalVerOrdComp"
    />
    <!-- Modal generar Pago de Facturas -->
    <ModalPagarFacturasCompra 
      :datos="dialogPagar"
      @setearIngPagoFCComp="setIngPagoFCComp"
      @setExitoPagos="buscar"
    />
    <!-- Modal Confirmar Accion -->
    <ModalConfirmar 
      :activoDialog="dialogConfAsociar"
      :texto="`¿Está seguro de marcar como PAGADAS todas las facturas seleccionadas?`"
      @action="marcarPagadas()"
      @setearModalConfAccion="setModalAsociar"
    />
  </v-row>
</template>

<script>
import FechaPicker from '../../components/util/FechaPicker.vue'
import FechaPickerRango from '../../components/util/FechaPickerRango.vue'
import BtnFiltro from '../../components/util/BtnFiltro.vue'
import moment from 'moment'
import SearchDataTable from '../../components/util/SearchDataTable.vue'
import BtnConfirmar from '../../components/util/BtnConfirmar.vue'
import { format_money, roundNumber } from '../../util/utils'
import ModalSubirArchivos from '../../components/generales/facturasCompra/ModalSubirArchivos.vue'
import ModalVerRecepciones from '../../components/ordenes-compra/ModalVerRecepciones.vue'
import ModalVerOrdCompra from '../../components/ordenes-compra/ModalVerOrdCompra.vue'
import ModalPagarFacturasCompra from '../../components/administracion/ModalPagarFacturasCompra.vue'
import ModalUploadImgGral from '../../components/generales/ModalUploadImgGral.vue'
import ModalConfirmar from '../../components/util/ModalConfirmar.vue'
export default {
  data() {
    return {
      format_money: format_money,
      roundNumber: roundNumber,
      panel: 0,
      idFactura: '',
      selectedEstado: {},
      estados: [],
      fechaDesde: '',
      fechaHasta: '',
      fechaRango: [ null, null ],
      limpiarFecha: false,
      tipoFactura: '',
      emision: '',
      numero: '',
      referencia: '',
      usuario: '',
      selectedSucursal: {},
      sucursales: [],
      selectedProveedor: {},
      proveedores: [],
      selectedEmpresa: {},
      empresas: [],
      big: false,
      load: false,
      facturasCompra: [],
      headers: [
        { text: 'Id', align: 'center', value: 'factura_id' },
        { text: 'Fecha', align: 'center', value: 'fecha' },
        { text: 'Proveedor', align: 'left', value: 'proveedor_nombre' },
        //{ text: 'Sucursal', align: 'left', value: 'sucursal_nombre' },
        { text: 'Estado', align: 'center', value: 'estado_nombre' },
        { text: 'Usuario', align: 'center', value: 'usuario' },
        { text: 'Emis-Nro', align: 'left', value: 'comprobante' },
        { text: 'Moneda', align: 'left', value: 'moneda_nombre' },
        //{ text: 'Factura', align: 'center', value: 'tipo_factura' },
        { text: 'Total Cot.', align: 'right', value: 'total_recalculado' },
        { text: 'Total Ars', align: 'right', value: 'total_real' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      search: '',
      ruta_carpeta: '/facturas_compras/',
      dialogSubirArchivo: false,
      facturaSeleccionada: '',
      actualFacturaCompra: {},
      menu: {
        activo: false,
        x: 0,
        y: 0
      },
      dialogVerOrdCompra: {
        activo: false,
        orden_id: 0,
        factura_id: 0,
        auditoria: 0, //se marca en 1 cuando es auditoria, 0 si es administración
        proveedor_codigo: 0, //se usa para buscar el proveedor
      },
      dialogVerRecepciones: {
        activo: false,
        orden_id: 0,
        factura_id: 0,
        auditoria: 0, //se marca en 1 cuando es auditoria, 0 si es administración
        proveedor_codigo: 0, //se usa para buscar el proveedor
      },
      dialogPagar: {
        activo: false,
        proveedor_codigo: '',
        proveedor_nombre: '',
        facturas: []
      },
      selected: [],
      selectedEstadoControl: {},
      estadosControl: [],
      disabledCount: 0,
      dialogSubirImgs: {
        activo: false,
        icono: 'fas fa-eye',
        titulo_modal: 'Archivos para La Orden de Pago ',
        tipos: ['.jpg', '.png', '.webp', '.pdf'], // formatos admitidos
        multiple: true,
        max_size: 1, // tamaño del archivo en megas
        ruta: '', // ruta del archivo
        raiz: 0, // indica si vamos a trabajar con la raiz del 10.40 o la de jarvis 2
        tabla: 6, // indica la tabla donde va el log
        id: 0, // generalmente indica un numero identificador para la tabla del log, por ejemplo para facturas de compra seria el id de la factura de compra en la tabla LOG_FACTURAS_COMPRAS en el campo FACTURA_COMPRA_ID
        permiso_borrar: 0 // permiso para borrar archivos
      },
      ordenPago: '',
      dialogConfAsociar: false,
      tienePermiso: 0,
      totalSeleccionado: 0
    }
  },
  components: {
    FechaPicker,
    BtnFiltro,
    SearchDataTable,
    BtnConfirmar,
    ModalSubirArchivos,
    FechaPickerRango,
    ModalVerRecepciones,
    ModalVerOrdCompra,
    ModalPagarFacturasCompra,
    ModalUploadImgGral,
    ModalConfirmar
  },
  created(){
    this.iniciarFormulario()
  },
  methods: {
    async iniciarFormulario(){
      this.$store.state.loading = true
      this.empresas = JSON.parse(localStorage.getItem('empresas'))
      this.sucursales = JSON.parse(localStorage.getItem('sucursales'))
      // obtener los estados
      this.$store.dispatch('bejerman/get_fc_data')
        .then(response => {
          this.$store.state.loading = false
          if (response.resultado == 1){
            this.estados = response.estados
            this.proveedores = response.proveedores
            this.estadosControl = response.estados_control
            this.dialogSubirImgs.permiso_borrar = response.permiso
            this.tienePermiso = response.permiso_pagar
          }else{
            this.$store.dispatch('show_snackbar', {
              text: response.msj,
              color: 'error',
            })
            return
          }
        })
        .catch(error => {
          this.$store.state.loading = false
          this.$store.dispatch('show_snackbar', {
            text: 'No se pudo obtener los estados de facturas: ' + error.message,
            color: 'error',
          })
          return
        })
    },
    limpiar(){
      this.selectedEstado = {}
      this.selectedEmpresa = {}
      this.selectedSucursal = {}
      this.selectedProveedor = {}
      this.idFactura = ''
      this.tipoFactura = ''
      this.emision = ''
      this.numero = ''
      this.referencia = ''
      this.usuario = ''
      this.limpiarFecha = true
      this.fechaDesde = ''
      this.fechaHasta = ''
      this.ordenPago = ''
    },
    camposVacios(){
      if (this.idFactura.length == 0 && Object.keys(this.selectedEstado).length == 0 && this.tipoFactura.length == 0 && this.emision.length == 0 && this.numero.length == 0 && this.referencia.length == 0 && this.usuario.length == 0 && Object.keys(this.selectedSucursal).length == 0 && Object.keys(this.selectedProveedor).length == 0 && Object.keys(this.selectedEmpresa).length == 0){
        return true
      }else{
        return false
      }
    },
    buscar(){
      if (this.camposVacios()){
        this.$store.dispatch('show_snackbar', {
          text: 'Por favor, ingrese algún filtro para empezar la búsqueda.',
          color: 'warning',
        })
        return
      }
      this.load = true
      this.selected = []
      this.facturasCompra = []
      // obtengo los datos de estado, estado de control, empresa, proveedor y sucursal
      let estadoF = 0
      let estadoC = 0
      let empresaF = 0
      let proveedorF = 0
      let sucurusalF = 0
      if (Object.keys(this.selectedEstado).length > 0){
        estadoF = this.selectedEstado.estado_id
      }
      if (Object.keys(this.selectedEstadoControl).length > 0){
        estadoC = this.selectedEstadoControl.estado_control_id
      }
      if (Object.keys(this.selectedEmpresa).length > 0){
        empresaF = this.selectedEmpresa.id
      }
      if (Object.keys(this.selectedProveedor).length > 0){
        proveedorF = this.selectedProveedor.proveedor_codigo
      }
      if (Object.keys(this.selectedSucursal).length > 0){
        sucurusalF = this.selectedSucursal.id
      }
      // fecha hasta
      let fechaHastaBus = ''
      if (this.fechaRango[1] && null || this.fechaRango[1] != undefined && this.fechaRango[1].length > 0){
        fechaHastaBus = moment(this.fechaRango[1]).format('DD/MM/YYYY')
      }
      // 29-05-2023 por MMURILLO, fecha de pago por ordenes de compra
      let fDesde = ''
      if (this.fechaDesde != null && this.fechaDesde != undefined && this.fechaDesde.toString().length > 0) fDesde = this.fechaDesde //moment(this.fechaDesde, 'YYYY-MM-DD').format('DD/MM/YYYY')
      let fHasta = ''
      if (this.fechaHasta != null && this.fechaHasta != undefined && this.fechaHasta.toString().length > 0) fHasta = this.fechaHasta //moment(this.fechaHasta, 'YYYY-MM-DD').format('DD/MM/YYYY')
      // orden de pago
      let oPago = 0
      if (this.ordenPago != null && this.ordenPago != undefined && this.ordenPago != 0 && this.ordenPago.toString().length > 0) oPago = this.ordenPago
      this.$store.dispatch('bejerman/get_facturas_compra_admin', {
        idFactura: this.idFactura,
        estado: estadoF,
        estadoControl: estadoC,
        fechaDesde: moment(this.fechaRango[0]).format('DD/MM/YYYY'),
        fechaHasta: fechaHastaBus,
        tipoFactura: this.tipoFactura.toUpperCase(),
        emision: this.emision,
        numero: this.numero,
        referencia: this.referencia,
        usuario: this.usuario,
        empresa: empresaF,
        proveedor: proveedorF,
        sucursal: sucurusalF,
        fDesde: fDesde,
        fHasta: fHasta,
        ordenPago: oPago
      })
        .then(response => {
          this.load = false
          if (response.resultado == 1){
            this.facturasCompra = response.facturasComp
            for (let id in this.facturasCompra){
              this.facturasCompra[id].fecha = moment(this.facturasCompra[id].fecha).format('DD/MM/YYYY')
            }
          }else{
            this.$store.dispatch('show_snackbar', {
              text: response.msj,
              color: 'error',
            })
            return
          }
        })
        .catch(error => {
          this.load = false
          this.$store.dispatch('show_snackbar', {
            text: 'No se pudo obtener las Facturas de Compra: ' + error.message,
            color: 'error',
          })
          return
        })
    },
    anularFacturaCompra(item){
      this.$store.state.loading = true
      this.$store.dispatch('facturasCompra/anular_factura_compra', {ifFactura: item.factura_id})
        .then(response => {
          this.$store.state.loading = false
          if (response.resultado == 1){
            this.$store.dispatch('show_snackbar', {
              text: 'Se anuló con éxito la factura ' + item.factura_id + '.',
              color: 'success',
            })
            let pos = this.facturasCompra.indexOf(item)
            this.facturasCompra[pos].estado_codigo = 3
            this.facturasCompra[pos].estado_nombre = 'ANULADO'
            this.facturasCompra[pos].if_anula = 0
            this.facturasCompra[pos].if_modifica = 0
          }else{
            this.$store.dispatch('show_snackbar', {
              text: response.msj,
              color: 'error',
            })
            return
          }
        })
        .catch(error => {
          this.$store.state.loading = false
          this.$store.dispatch('show_snackbar', {
            text: 'No se pudo anular la Factura: ' + error.message,
            color: 'error',
          })
          return
        })
    },
    subirArchivo(item){
      this.facturaSeleccionada = ''
      // indico la ruta donde se van a guardar los archivos
      this.ruta_carpeta = '/facturas_compras/' + item.factura_id
      this.facturaSeleccionada = item.factura_id.toString()
      // abro el modal
      this.dialogSubirArchivo = true
    },
    ordenPagoFiles(item){
      // titulo del modal
      this.dialogSubirImgs.titulo_modal = this.dialogSubirImgs.titulo_modal + item.nro_orden_pago
      // ruta de la carpeta donde se deberian encontrar los archivos
      this.dialogSubirImgs.ruta = '/ordenes_pago/' + item.orden_pago_id
      // indico el id identificador de las fotos para la recepción
      this.dialogSubirImgs.id = item.orden_pago_id
      // abro el modal
      this.dialogSubirImgs.activo = true
    },
    closeModalSubirArchivo(){
      this.dialogSubirArchivo = false
    },
    openWindow(item){
      let path = '/factura-compra/' + item.factura_id.toString()
      const routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    },
    crearNuevaFC(){
      this.$router.push({path: '/new-factura-compra'})
    },
    clickDerecho(event, item){
      event.preventDefault()
      this.actualFacturaCompra = {}
      this.menu.activo = false
      this.menu.x = event.clientX
      this.menu.y = event.clientY
      this.$nextTick(() => {
        this.menu.activo = true
        if (item.item.estado_control_id != 4) this.actualFacturaCompra = item.item
      })
    },
    setModalVerRecep(value){
      this.dialogVerRecepciones.activo = value
      if (this.dialogVerRecepciones.activo == false){
        this.actualFacturaCompra = {}
        this.dialogVerRecepciones.factura_id = 0
      }
    },
    setModalVerOrdComp(value){
      this.dialogVerOrdCompra.activo = value
      if (this.dialogVerOrdCompra.activo == false){
        this.actualFacturaCompra = {}
        this.dialogVerOrdCompra.factura_id = 0
      }
    },
    async pagar(){
      if (this.selected.length == 0 && Object.keys(this.actualFacturaCompra).length > 0) this.selected.push(this.actualFacturaCompra)
      // tengo algo seleccionado?
      if (this.selected.length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Sin Facturas seleccionadas. No se puede iniciar el pago.',
          color: 'info',
        })
        return
      }
      // 17-07-2023 por MMURILLO, controlo que sean de un mismo proveedor
      // tomo el primer código de proveedor
      let unProv = this.selected[0].proveedor_codigo
      // busco a ver si tengo elementos que contengan un código de proveedor distinto
      let distintos = this.selected.filter(element => element.proveedor_codigo != unProv)
      if (distintos.length > 0){
        // aviso al usuario que debe quedarse con un solo proveedor
        this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar únicamente facturas de un solo proveedor.',
          color: 'warning',
        })
        return
      }
      // controlo el estado al momento de abrir el modal
      this.$store.state.loading = true
      let controlPeticion = await this.$store.dispatch('facturasCompra/getEstadoControlActual', {facturas: this.selected})
      this.$store.state.loading = false
      if (controlPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: controlPeticion.msj,
          color: 'error',
        })
        return
      }
      // tengo algo en las pagadas?
      if (controlPeticion.pagadas.length > 0){
        // tengo facturas que ya fueron pagadas, las destildo
        for (let id in controlPeticion.pagadas){
          // paso a quitar
          let unaFactura = this.selected.filter(element => element.factura_id == controlPeticion.pagadas[id].factura_id)
          let pos = this.selected.indexOf(unaFactura[0])
          this.selected.splice(pos, 1)
          // actualizo el estado en los detalles
          let posDet = this.facturasCompra.indexOf(unaFactura[0])
          let det = this.facturasCompra[posDet]
          det.estado_control_id = 4
          det.estado_control_nombre = 'PAGADO'
          Object.assign(this.facturasCompra[posDet], det)
        }
        // doy aviso que se destildaron algunas facturas
        this.$store.dispatch('show_snackbar', {
          text: 'Se seleccionaron Facturas que ya poseían Pagos y las mismas fueron quitadas de su selección. Verifique las facturas seleccionadas y vuelva a iniciar la operación.',
          color: 'info',
        })
        return
      }
      this.dialogPagar.proveedor_codigo = this.selected[0].proveedor_codigo
      this.dialogPagar.proveedor_nombre = this.selected[0].proveedor_nombre
      this.dialogPagar.facturas = this.selected
      this.dialogPagar.activo = true
    },
    setIngPagoFCComp(value){
      this.dialogPagar.activo = value
      if (this.dialogPagar.activo == false){
        this.dialogPagar.facturas = []
        this.dialogPagar.proveedor_nombre = ''
      }
    },
    selectAllToggle(props) {
      if(this.selected.length != this.facturasCompra.length - this.disabledCount) {
          this.selected = [];
          const self = this;
          props.items.forEach(item => {
            if(item.estado_control_id != 4) {
              self.selected.push(item);
            } 
          })
      }else this.selected = []
    },
    seleccionarTodos(){
      if (this.facturasCompra.length > 0){
        // quiere agregar todos los elementos de la tabla, controlo que no se agreguen las que ya estan pagadas ni que sean de proveedores distintos
        let unProv = 0
        if (this.selected.length > 0) unProv = this.selected[0].proveedor_codigo
        // me quedo unicamente con los detalles que no se encuentren pagados
        let noPagados = this.facturasCompra.filter(element => element.estado_control_id != 4)
        // tengo algo para agregar?
        if (noPagados.length == 0) return
        // tengo algo para agregar, controlo unProv
        if (unProv == 0){
          // que unProv sea igual a 0 quiere decir que no tengo nada seleccionado, por lo tanto tengo que pasar a llenar la varible global selected, obtengo el proveedor de noPagados
          unProv = noPagados[0].proveedor_codigo
        }
        // comentado el 17-07-2023 por MMURILLO, dejamos de controlar que sea de un solo proveedor
        // hasta este punto si o si tengo algo en unProv, verifico si todos los detalles de noPagados tienen el mismo proveedor
        // let distintos = noPagados.filter(element => element.proveedor_codigo != unProv)
        // if (distintos.length > 0){
        //   this.$store.dispatch('show_snackbar', {
        //     text: 'Existen distintos proveedores en el listado. No se puede agregar.',
        //     color: 'error',
        //   })
        //   return
        // }
        // si llego hasta aqui, paso a agregar
        for (let id in noPagados){
          // ya existe en el listado selected?
          let existe = this.selected.filter(element => element.factura_id == noPagados[id].factura_id)
          if (existe.length == 0) this.selected.push(noPagados[id])
        }
      }
    },
    quitarTodos(){
      this.selected = []
    },
    async marcarPagadas(){
      this.$store.state.loading = true
      let updatePeticion = await this.$store.dispatch('facturasCompra/marcarFCPagadas', {facturas: this.selected})
      this.$store.state.loading = false
      if (updatePeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'Las facturas fueron marcadas como pagadas con éxito.',
          color: 'success'
        })
        this.buscar()
      }else{
        this.$store.dispatch('show_snackbar', {
          text: updatePeticion.msj,
          color: 'error'
        })
      }
    },
    setModalUpImg(value){
      this.dialogSubirImgs.activo = value
      if (this.dialogSubirImgs.activo == false){
        this.dialogSubirImgs.icono = 'fas fa-eye'
        this.dialogSubirImgs.titulo_modal = 'Archivos para La Orden de Pago '
      }
    },
    setModalAsociar(value){
      this.dialogConfAsociar = value
    },
    changeSize (custom) {
      this.big = custom
    }
  },
  watch: {
    selectedEmpresa: function(){
      if (this.selectedEmpresa == null || this.selectedEmpresa == undefined){
        this.selectedEmpresa = {}
        this.selectedProveedor = {}
        this.proveedores = []
      }
    },
    selectedEstado: function(){
      if (this.selectedEstado == null || this.selectedEstado == undefined){
        this.selectedEstado = {}
      }
    },
    selectedProveedor: function(){
      if (this.selectedProveedor == null || this.selectedProveedor == undefined){
        this.selectedProveedor = {}
      }
    },
    selectedSucursal: function(){
      if (this.selectedSucursal == null || this.selectedSucursal == undefined){
        this.selectedSucursal = {}
      }
    },
    selectedEstadoControl: function(){
      if (this.selectedEstadoControl == null || this.selectedEstadoControl == undefined) this.selectedEstadoControl = {}
    },
    selected: function(){
      this.totalSeleccionado = 0
      // comentado el 17-07-2023 por MMURILLO, dejamos de controlar que sea de un solo proveedor
      if (this.selected.length > 0){
        // // tomo el primer código de proveedor
        // let unProv = this.selected[0].proveedor_codigo
        // // busco a ver si tengo elementos que contengan un código de proveedor distinto
        // let distintos = this.selected.filter(element => element.proveedor_codigo != unProv)
        // if (distintos.length > 0){
        //   // aviso al usuario que debe quedarse con un solo proveedor
        //   this.$store.dispatch('show_snackbar', {
        //     text: 'Debe seleccionar únicamente facturas de un solo proveedor.',
        //     color: 'warning',
        //   })
        //   // limpio la seleccion
        //   this.selected = []
        // }
        for (let id in this.selected){
          this.totalSeleccionado = this.totalSeleccionado + roundNumber(this.selected[id].total_real, 2)
        }
        this.totalSeleccionado = roundNumber(this.totalSeleccionado, 2)
      }
    },
    facturasCompra: function(){
      if (this.facturasCompra.length > 0){
        const self = this;
        this.facturasCompra.map(item => {
          if (item.estado_control_id == 4) self.disabledCount += 1
        })
      }
    }
  },
}
</script>

<style>

</style>