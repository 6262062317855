<template>
  <v-dialog
    v-model="activo"
    :max-width="$vuetify.breakpoint.smAndDown ? '700' : '60%'"
    scrollable
  >
    <v-card
      flat
    >
      <!-- Titulo -->
      <v-card-title
        class="pt-3"
        :class="$vuetify.breakpoint.smAndDown ? 'd-flex justify-center' : ''"
      >
        <v-icon small color="success" class="ma-2">fas fa-plus-circle</v-icon> Nuevo Cliente Mayorista
        <v-spacer></v-spacer>
        <v-btn icon><v-icon @click="activo = false" title="Cerrar">fas fa-times</v-icon></v-btn>
      </v-card-title>
      <!-- Contenido -->
      <v-card-text>
        <!-- Apellido y Nombre, Nro de Documento y Tipo de Documento -->
        <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-0">
          <v-col cols="12" sm="3" md="3" class="py-1">
            Apellido
            <v-text-field
              v-model="apellido"
              outlined
              dense
              type="text"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1">
            Nombre
            <v-text-field
              v-model="nombre"
              outlined
              dense
              type="text"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1">
            N° Documento
            <v-text-field
              v-model="nroDocumento"
              outlined
              dense
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1">
            Tipo Documento
            <v-autocomplete
              v-model="selectedTipoDoc"
              item-text="nombre"
              :items="tiposDocumento"
              hide-details
              outlined
              dense
              return-object
            ></v-autocomplete>
          </v-col>
        </v-row>
        <!-- Condicion Iva, Email y Teléfono -->
        <v-row :no-gutters="$vuetify.breakpoint.xs">
          <v-col cols="12" sm="3" md="3" class="py-1">
            Condición iva
            <v-autocomplete
              v-model="selectedCondIva"
              item-text="condicion_iva_nombre"
              :items="condicionesIva"
              hide-details
              outlined
              dense
              return-object
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="py-1">
            Email
            <v-text-field
              v-model="email"
              outlined
              dense
              type="text"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1">
            Teléfono
            <v-text-field
              v-model="telefono"
              outlined
              dense
              type="text"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- Direccion, Provincia y Localidad -->
        <v-row :no-gutters="$vuetify.breakpoint.xs">
          <v-col cols="12" sm="3" md="3" class="py-1">
            Dirección
            <v-text-field
              v-model="direccion"
              outlined
              dense
              type="text"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1">
            Provincia
            <v-autocomplete
              v-model="selectedProvincia"
              item-text="provincia_nombre"
              :items="provincias"
              hide-details
              outlined
              dense
              return-object
              @change="getLocalidades()"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="py-1">
            Localidad
            <v-autocomplete
              v-model="selectedLocalidad"
              item-text="localidad_nombre"
              :items="localidades"
              hide-details
              outlined
              dense
              return-object
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end mx-2">
        <v-btn color="warning" @click="activo = false" class="ma-2">Volver</v-btn>
        <BtnConfirmar
          @action="saveCliente()"
          color="success"
          icono="fas fa-save"
          :texto="`¿Esta seguro de dar del alta el cliente?`"
          nombre="Guardar"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BtnConfirmar from '../../util/BtnConfirmar.vue'
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  components: {
    BtnConfirmar,
  },
  computed: {
    activo: {
      get() {
        return this.datos.activo
      },
      set(value) {
        this.$emit('setearNewClienteM', value)
      }
    },
    ejecutivo_codigo: {
      get() {
        return this.datos.ejecutivo_codigo
      },
    }
  },
  data() {
    return {
      apellido: '',
      nombre: '',
      nroDocumento: '',
      selectedTipoDoc: {},
      selectedCondIva: {},
      email: '',
      telefono: '',
      direccion: '',
      tiposDocumento: [],
      condicionesIva: [],
      selectedProvincia: {},
      provincias: [],
      selectedLocalidad: {},
      localidades: []
    }
  },
  methods: {
    async initForm(){
      this.$store.state.loading = true
      let datosPeticion = await this.$store.dispatch('clientes/initFormNewClienteM')
      this.$store.state.loading = false
      if (datosPeticion.resultado == 1){
        this.tiposDocumento = datosPeticion.tiposDoc
        this.condicionesIva = datosPeticion.condiciones
        this.provincias = datosPeticion.provincias
      }else{
        this.$store.dispatch('show_snackbar', {
          text: datosPeticion.msj,
          color: 'error',
        })
      }
    },
    limpiarCampos(){
      this.apellido = ''
      this.nombre = ''
      this.nroDocumento = ''
      this.selectedTipoDoc = {}
      this.selectedCondIva = {}
      this.email = ''
      this.telefono = ''
      this.direccion = ''
      this.tiposDocumento = []
      this.condicionesIva = []
      this.selectedProvincia = {}
      this.provincias = []
      this.selectedLocalidad = {}
      this.localidades = []
    },
    async getLocalidades(){
      this.localidades = []
      if (Object.keys(this.selectedProvincia).length > 0){
        this.$store.state.loading = true
        let localidadesPeticion = await this.$store.dispatch('genericosAcc/get_localidades_x_provincia', {provincia_codigo: this.selectedProvincia.provincia_codigo})
        this.$store.state.loading = false
        if (localidadesPeticion.resultado == 1){
          this.localidades = localidadesPeticion.localidades
        }else{
          this.$store.dispatch('show_snackbar', {
            text: localidadesPeticion.msj,
            color: 'error',
          })
        }
      }
    },
    camposCompletos(){
      if (this.apellido == null || this.apellido == undefined || this.apellido.toString().length == 0) return {valido: false, msj: 'Debe ingresar el Apellido del Cliente.'}
      if (this.nombre == null || this.nombre == undefined || this.nombre.toString().length == 0) return {valido: false, msj: 'Debe ingresar el Nombre del Cliente.'}
      if (this.nroDocumento == null || this.nroDocumento == undefined || this.nroDocumento.toString().length == 0) return {valido: false, msj: 'Debe ingresar el DNI del Cliente.'}
      if (Object.keys(this.selectedTipoDoc).length == 0) return {valido: false, msj: 'Debe ingresar el Tipo de Documento del Cliente.'}
      if (Object.keys(this.selectedCondIva).length == 0) return {valido: false, msj: 'Debe ingresar la Condición de Iva del Cliente.'}
      if (this.email == null || this.email == undefined || this.email.toString().length == 0) return {valido: false, msj: 'Debe ingresar el Email del Cliente.'}
      if (this.telefono == null || this.telefono == undefined || this.telefono.toString().length == 0) return {valido: false, msj: 'Debe ingresar el Teléfono del Cliente.'}
      if (this.direccion == null || this.direccion == undefined || this.direccion.toString().length == 0) return {valido: false, msj: 'Debe ingresar el Domicilio del Cliente.'}
      if (Object.keys(this.selectedProvincia).length == 0) return {valido: false, msj: 'Debe ingresar la Provincia del Cliente.'}
      if (Object.keys(this.selectedLocalidad).length == 0) return {valido: false, msj: 'Debe ingresar la Localidad del Cliente.'}
      return {valido: true, msj: 'OK'}
    },
    async saveCliente(){
      let validos = this.camposCompletos()
      if (validos.valido == false){
        this.$store.dispatch('show_snackbar', {
          text: validos.msj,
          color: 'warning',
        })
        return
      }
      // body con los datos
      let body = {
        apellido: this.apellido,
        nombre: this.nombre,
        nro_documento: this.nroDocumento,
        tipo_documento: this.selectedTipoDoc.id,
        condicion_iva: this.selectedCondIva.condicion_iva_codigo,
        email: this.email,
        telefono: this.telefono,
        direccion: this.direccion,
        provincia_codigo: this.selectedProvincia.provincia_codigo,
        localidad_codigo: this.selectedLocalidad.localidad_codigo,
        ejecutivo_codigo: this.ejecutivo_codigo,
        sucursal_codigo: this.selectedProvincia.sucursal_codigo
      }
      // paso a generar el cliente
      this.$store.state.loading = true
      let nuevoPeticion = await this.$store.dispatch('clientes/setNuevoClienteMayorista', body)
      this.$store.state.loading = false
      if (nuevoPeticion.resultado == 1){
        this.$emit('nuevoCliente', nuevoPeticion.vend_cod)
        this.$store.dispatch('show_snackbar', {
          text: 'Cliente creado correctamente.',
          color: 'success',
        })
        this.activo = false
      }else{
        this.$store.dispatch('show_snackbar', {
          text: nuevoPeticion.msj,
          color: 'error',
        })
      }
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false){
        this.limpiarCampos()
      }else{
        // iniciar el form
        this.initForm()
      }
    }
  },
}
</script>

<style>

</style>